import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  FlexProps,
  FormControl,
  IconButton,
  IconButtonProps,
  Image,
} from "@chakra-ui/react";
import { IconBase } from "components/base";
import FileUpload from "components/FileUpload";
import { withPresignedUrl } from "components/HOC/presignedUrl";
import { PCTooltip } from "components/PCTooltip";
import { SvgIcon } from "components/SvgIcon";
import { TypeOfFile } from "constants/file";
import { useEffect, useState } from "react";
import SliderSlick from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import AudioPlayer from "./record-audio/AudioPlayer";

const ImagePresigned = withPresignedUrl(Image);
const AudioPresigned = withPresignedUrl(AudioPlayer);

interface Props extends FlexProps {
  listImageSelected: any[];
  customDeleteIconProps?: IconButtonProps;
  slideSettings?: Object;
  playIconProps?: Partial<IconButtonProps>;
  onDeleteImage: (index: number, srcImage: string) => void;
  onPreviewImage: (src: string) => void;
  onUpdateFileSlider: (e: any, index: number, type: number) => void;
}

const Slider = ({
  listImageSelected,
  customDeleteIconProps,
  slideSettings,
  playIconProps,
  onDeleteImage,
  onPreviewImage,
  onUpdateFileSlider,
  ...rest
}: Props) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    ...slideSettings,
  };

  const [listFile, setListFile] = useState<any[]>([]);

  useEffect(() => {
    if (listImageSelected.length > 0) {
      const temp = listImageSelected.map((item) => ({
        item,
        isHover: false,
      }));
      setListFile(temp);
    }
  }, [listImageSelected]);

  const onHover = (index1: any, isHover: boolean) => {
    const listFileClone = [...listFile];
    listFileClone?.forEach((file, index) => {
      if (index === index1) {
        file.isHover = isHover;
      }
    });
    setListFile(listFileClone);
  };
  if (!listImageSelected.length) {
    return null;
  }

  return (
    <Flex width="100%" flexDir="column" mb="1rem" {...rest}>
      {listFile.length > 0 && (
        <Box mt="0.3rem">
          <SliderSlick {...settings}>
            {listFile.map((file, index) => (
              <Box key={index} width="100%">
                <PCTooltip label={file.item.name}>
                  <Box
                    className="product-images-slider-thumbs-wrapper"
                    bg="#ededee"
                  >
                    {file.item.type === TypeOfFile.ATTACH && (
                      <FormControl
                        isRequired
                        w="auto"
                        onChange={(e) => {
                          onUpdateFileSlider(e, index, file.item.type);
                        }}
                        zIndex="10"
                      >
                        <FileUpload accept="image/*, .pdf, .mp4">
                          <IconButton
                            variant="text"
                            aria-label="delete"
                            position="absolute"
                            top="0"
                            right="0"
                            minHeight="auto"
                            color="white"
                            icon={
                              <IconBase icon="/img/icon-pencil-white.svg" />
                            }
                          />
                        </FileUpload>
                      </FormControl>
                    )}
                    {file.item.type === TypeOfFile.AUDIO && (
                      <AudioPresigned
                        src={file.item.src}
                        height={40}
                        isPreview
                        playIconProps={playIconProps}
                      />
                    )}
                    {[TypeOfFile.ATTACH, TypeOfFile.IMAGE].includes(
                      file.item.type
                    ) && (
                      <Box
                        onMouseLeave={() => onHover(index, false)}
                        onMouseEnter={() => onHover(index, true)}
                        position="relative"
                        onClick={(e) => {
                          file.item.type === TypeOfFile.IMAGE &&
                            onPreviewImage(file.item.src || "");
                        }}
                      >
                        <Box
                          position="absolute"
                          top="0"
                          right="0"
                          left="0"
                          bottom="0"
                          bgColor={file.isHover && "rgb(0 0 0 / 70%)"}
                        />
                        {file.item.src.includes("icon-content-note.svg") ? (
                          <SvgIcon src={file.item.src} h="40px" />
                        ) : (
                          <ImagePresigned
                            borderRadius="5px"
                            w="100%"
                            h="40px"
                            src={file.item.src}
                            alt="product images"
                            objectFit="cover"
                          />
                        )}
                      </Box>
                    )}
                    <IconButton
                      variant="dangerText"
                      onClick={() => {
                        onDeleteImage(index, file.item.src);
                      }}
                      aria-label="delete"
                      position="absolute"
                      bottom="2px"
                      right="1px"
                      padding="0.4rem"
                      minHeight="auto"
                      icon={<DeleteIcon />}
                      {...customDeleteIconProps}
                    />
                  </Box>
                </PCTooltip>
              </Box>
            ))}
          </SliderSlick>
        </Box>
      )}
    </Flex>
  );
};

export default Slider;
