import {
  Box,
  Button,
  Flex,
  Text,
  Textarea,
  useBoolean,
} from "@chakra-ui/react";
import { IconBase } from "components/base";
import FileUpload from "components/FileUpload";
import FormUploadFile from "components/FormUploadFile";
import SelectUserSingle from "components/input/SelectUserSingle";
import { SvgIcon } from "components/SvgIcon";
import DayPicker from "components/ui/DayPicker";
import Slider from "components/ui/Slider";
import { SelectUserSingleSx } from "constants/dropdown";
import { TypeOfFile } from "constants/file";
import { TaskLogTypeKey } from "constants/task";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { UserDTO } from "interfaces/dtos/UserDTO";
import { FileModel } from "interfaces/models";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { MapTitleForTask } from "utils/data-logs";
import { getUrlDisplayOfFileUpload } from "utils/image";
import { selectStyles } from "../";
import CameraModal from "../../CameraModal";
import DrawToolModal, { HandleSelectDrawImageProps } from "../../DrawToolModal";
import { PreviewFileComponent } from "../Comment/PreviewFileComponent";
import { SelectedImages } from "../hooks";
import useDownloadOriginImage from "../useDownloadOriginImage";
import useDropdownDirection from "./useDropdownDirection";

interface Props {
  canEditTaskModal: any;
  taskSelected: TaskDTO | undefined;
  taskModalInfo: TaskDTO | undefined;
  selectedConfirmedImages: FileModel[];
  listUserById: { [key: string]: UserDTO };
  isFetchingUserAssigned?: boolean;
  isDisableUploadImage?: boolean;
  isCurrentTask: boolean;
  attachesSelected: FileModel[];
  indexFileUpload: number[];
  onDeleteAttachFile: (index: number) => Promise<void>;
  onChangeInput: (field: string) => (event: any) => void;
  addChatMessage: (
    content: string,
    type: TaskLogTypeKey,
    files?: any,
    taskId?: string | undefined,
    statusChange?: string | undefined
  ) => Promise<void>;
  onDeleteImage: (
    index: number,
    addCommentFunc: () => void,
    field?: keyof SelectedImages | undefined
  ) => Promise<void>;
  handleSaveDrawImage: (
    field: keyof SelectedImages
  ) => (
    newListImage: FileModel[],
    requestId?: string | undefined
  ) => Promise<void>;
  handleSelectedDay: (field: keyof TaskDTO) => (day: string | Date) => void;
  onChangeUserSingle: (params: { field: keyof TaskDTO; user: any }) => void;
  updateDataOnBlurInput: (field: keyof TaskDTO) => void;
  onChangeFile: (field?: keyof SelectedImages) => any;
  handleSaveCaptureCamera: (
    field: keyof SelectedImages
  ) => (params: HandleSelectDrawImageProps) => Promise<void>;
}

export interface ContentAfterFixedRefType {
  onScrollModal: (e: any) => void;
}

const ContentAfterFixed = forwardRef((props: Props, ref) => {
  const {
    canEditTaskModal,
    taskModalInfo,
    selectedConfirmedImages,
    listUserById,
    isFetchingUserAssigned,
    isDisableUploadImage,
    isCurrentTask,
    taskSelected,
    attachesSelected,
    indexFileUpload,
    onDeleteAttachFile,
    handleSelectedDay,
    onChangeInput,
    onDeleteImage,
    addChatMessage,
    handleSaveDrawImage,
    onChangeUserSingle,
    updateDataOnBlurInput,
    onChangeFile,
    handleSaveCaptureCamera,
  } = props;
  const [originConfirmImageLinks, setOriginConfirmImageLinks] = useState<
    string[]
  >([]);
  const [isDeleteFile, setIsDeleteFile] = useState(false);
  const { isOnline } = useSelector((state: RootState) => state.app);

  const {
    confirmedUserDirection,
    confirmedDateTimeDirection,
    deadlineDirection,
    formRef,
    calculateScrollTop,
    checkDropdownDirection,
  } = useDropdownDirection();

  useImperativeHandle(ref, () => ({
    onScrollModal: (event: any) => {
      calculateScrollTop(event);
    },
  }));

  const [isOpenConfirmedCameraModal, setOpenConfirmedCameraModal] =
    useBoolean();
  const confirmedImgDrawFile = useRef<File>();

  const [isOpenConfirmedDraw, setOpenConfirmedDraw] = useBoolean();

  useEffect(() => {
    (async () => {
      const originConfirmImageLinks = await getUrlDisplayOfFileUpload(
        taskSelected?.confirmedImages || []
      );
      setOriginConfirmImageLinks(originConfirmImageLinks);
    })();
  }, [taskSelected?.confirmedImages]);

  const isExistConfirmedImages = useMemo(
    () => selectedConfirmedImages?.length,
    [selectedConfirmedImages]
  );

  const onClickBtnSelectFile = (event: React.MouseEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    target.value = "";
  };

  const onDeleteFile = (index: number) => async (e: any) => {
    e.stopPropagation();
    setIsDeleteFile(true);
    await onDeleteAttachFile(index);
    setIsDeleteFile(false);
  };

  // self custom
  const { isDownloadOriginImage, handleDownloadOriginImage } =
    useDownloadOriginImage();

  const renderUploadFormConfirmImage = useCallback(
    (activeThumb: number) => {
      if (!canEditTaskModal?.canEditConfirmedImagesField) {
        return <></>;
      }

      return (
        <FormUploadFile
          onClick={onClickBtnSelectFile}
          onChange={onChangeFile("confirmedImages")}
          type={TypeOfFile.IMAGE}
          style={{
            marginLeft: isExistConfirmedImages ? "auto" : "inherit",
          }}
        >
          <Flex
            alignItems="center"
            flexWrap="wrap"
            justifyContent="flex-end"
            gap="1rem"
          >
            <FileUpload width="auto" multiple accept="image/*">
              <Button
                fontSize="1.4rem"
                isDisabled={isDisableUploadImage && isCurrentTask}
              >
                選択
              </Button>
            </FileUpload>
            <Button variant="filled" onClick={setOpenConfirmedCameraModal.on}>
              撮影
            </Button>
            {originConfirmImageLinks?.[activeThumb] && (
              <Button
                isLoading={isDownloadOriginImage}
                leftIcon={
                  <IconBase
                    w="2rem"
                    h="2rem"
                    icon="/img/icon-download.svg"
                    color="font.blue"
                  />
                }
                onClick={() =>
                  handleDownloadOriginImage(
                    originConfirmImageLinks?.[activeThumb]
                  )
                }
              >
                元の写真をダウンロード
              </Button>
            )}
            {isOpenConfirmedCameraModal && (
              <CameraModal
                isOpen={isOpenConfirmedCameraModal}
                onClose={setOpenConfirmedCameraModal.off}
                onCapture={(file) => {
                  confirmedImgDrawFile.current = file;
                  setOpenConfirmedDraw.on();
                }}
              />
            )}

            {isOpenConfirmedDraw && (
              <DrawToolModal
                isOpen={isOpenConfirmedDraw}
                onSelect={handleSaveCaptureCamera("confirmedImages")}
                onClose={setOpenConfirmedDraw.off}
                fileRef={confirmedImgDrawFile}
                reCapture={() => {
                  setOpenConfirmedDraw.off();
                  setOpenConfirmedCameraModal.on();
                }}
              />
            )}
          </Flex>
        </FormUploadFile>
      );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      originConfirmImageLinks,
      isOpenConfirmedDraw,
      isOpenConfirmedCameraModal,
      isExistConfirmedImages,
      confirmedImgDrawFile,
      isDisableUploadImage,
      isCurrentTask,
      canEditTaskModal?.canEditConfirmedImagesField,
      onClickBtnSelectFile,
      onChangeFile,
      addChatMessage,
      handleSaveCaptureCamera,
    ]
  );

  return (
    <>
      <Text
        px="16px"
        color="#171717"
        fontWeight="700"
        mb="1rem"
        fontSize="1.8rem"
        alignSelf="start"
      >
        是正内容
      </Text>

      <Flex
        w="100%"
        color="#171717"
        bg="#E5E5E5"
        fontSize="1.4rem"
        alignItems={isExistConfirmedImages ? "center" : "unset"}
        mb="0.4rem"
        my="16px"
        p="1rem 16px"
        flexDir={isExistConfirmedImages ? "column" : "unset"}
      >
        <Text
          flexBasis={isExistConfirmedImages ? "unset" : "6rem"}
          mr="1rem"
          mb="0.5rem"
          alignSelf="start"
          whiteSpace="nowrap"
        >
          {MapTitleForTask.confirmedImages?.title}
        </Text>
        <Slider
          className="slider-image"
          originImageLinks={originConfirmImageLinks}
          keyImageName={"confirmedImages" as keyof TaskDTO}
          currentTask={taskModalInfo}
          onDeleteImage={(idx: number) =>
            onDeleteImage(
              idx,
              () => addChatMessage("", TaskLogTypeKey.DELETE_IMAGE),
              "confirmedImages"
            )
          }
          listImageSelected={selectedConfirmedImages || []}
          onSaveDrawImage={handleSaveDrawImage("confirmedImages")}
          readonly={!canEditTaskModal?.canEditConfirmedImagesField}
          uploadForm={renderUploadFormConfirmImage}
          isShowOriginalLink
        />
      </Flex>

      <Flex
        w="100%"
        color="#171717"
        fontSize="1.4rem"
        mb="1rem"
        my="16px"
        px="16px"
        flexDir="column"
      >
        <Text w="6rem" mr="1rem" mb="0.5rem">
          {MapTitleForTask.confirmedMemo?.title}
        </Text>
        <Textarea
          fontSize="1.4rem"
          minH="9rem"
          maxH="12rem"
          value={taskModalInfo?.confirmedMemo || ""}
          onChange={onChangeInput("confirmedMemo")}
          onBlur={() => {
            updateDataOnBlurInput("confirmedMemo");
          }}
          borderRadius={4}
          w="100%"
          border="1px solid var(--primary-border-color) !important"
          placeholder={`是正の説明内容です`}
          readOnly={!canEditTaskModal?.canEditConfirmedMemoField}
          cursor={
            !canEditTaskModal?.canEditConfirmedMemoField
              ? "not-allowed"
              : "unset"
          }
          bg={
            !canEditTaskModal?.canEditConfirmedMemoField ? "#f1f1f1" : "#FAFAFA"
          }
        />
      </Flex>

      <Flex
        w="100%"
        color="#171717"
        fontSize="1.4rem"
        alignItems="center"
        mb="0.4rem"
        my="16px"
        pl="16px"
        pr="16px"
        ref={(el) => {
          Object.assign(formRef.current, { deadlineEl: el });
        }}
      >
        <Text flexBasis="8.5rem">
          {MapTitleForTask.endDateScheduled?.title}
        </Text>
        <Box flex="1">
          <DayPicker
            name="endDateScheduled"
            value={taskModalInfo?.endDateScheduled}
            onSelectedDay={handleSelectedDay("endDateScheduled")}
            shouldCloseOnSelect
            popperPlacement={`${deadlineDirection}-start`}
            onCalendarOpen={checkDropdownDirection}
            popperModifiers={[
              {
                name: "flip",
                enabled: false,
              },
            ]}
            readonly={!canEditTaskModal?.canEditEndDateScheduledField}
          />
        </Box>
      </Flex>

      <Flex
        w="100%"
        color="#171717"
        fontSize="1.4rem"
        alignItems="center"
        mb="0.4rem"
        my="16px"
        pl="16px"
        pr="16px"
        ref={(el) => {
          Object.assign(formRef.current, { confirmedDateTimeEl: el });
        }}
      >
        <Text flexBasis="8.5rem">
          {MapTitleForTask.confirmedDateTime?.title}
        </Text>
        <Box flex="1">
          <DayPicker
            name="confirmedDateTime"
            value={taskModalInfo?.confirmedDateTime}
            onSelectedDay={handleSelectedDay("confirmedDateTime")}
            shouldCloseOnSelect
            popperPlacement={`${confirmedDateTimeDirection}-start`}
            onCalendarOpen={checkDropdownDirection}
            popperModifiers={[
              {
                name: "flip",
                enabled: false,
              },
            ]}
            readonly={!canEditTaskModal?.canEditConfirmedDateTimeField}
          />
        </Box>
      </Flex>

      <Flex
        w="100%"
        h="4.4rem"
        color="#171717"
        fontSize="1.4rem"
        alignItems="center"
        mb="0.4rem"
        my="16px"
        pl="16px"
        pr="16px"
        ref={(el) => {
          Object.assign(formRef.current, { confirmedUserEl: el });
        }}
      >
        <Text flexBasis="8.5rem">{MapTitleForTask.userConfirmed?.title}</Text>
        <Flex w="calc(100% - 8.5rem)" alignItems="center" flex="1">
          <SelectUserSingle
            users={listUserById}
            value={taskModalInfo?.userConfirmed}
            isLoading={isFetchingUserAssigned}
            components={{
              DropdownIndicator: () => (
                <SvgIcon
                  src="/img/icon-react-select-custom-dropdown.svg"
                  mx=".8rem"
                />
              ),
            }}
            menuPlacement={confirmedUserDirection}
            styles={selectStyles}
            onChange={(user) =>
              onChangeUserSingle({
                field: "userConfirmed",
                user,
              })
            }
            sx={{
              ...SelectUserSingleSx,
              "div[class*='option']": {
                borderBottom: "1px solid var(--primary-border-color)",
                p: "0.5rem 1.5rem",
              },
              "div[class*='option']:last-child": {
                borderBottom: "none",
              },
              ".containerSelectInput > div": {
                background: !canEditTaskModal?.canEditConfirmedUserField
                  ? "#f1f1f1"
                  : "#FAFAFA",
                borderColor: "var(--primary-border-color)",
              },
            }}
            isDisabled={!canEditTaskModal?.canEditConfirmedUserField}
          />
        </Flex>
      </Flex>

      <Flex
        w="100%"
        color="#171717"
        fontSize="1.4rem"
        alignItems={attachesSelected?.length ? "flex-start" : "center"}
        my="16px"
        p="1rem 16px"
        flexDir={attachesSelected?.length ? "column" : "row"}
        bg="#e5e5e5"
        borderBottom="1px solid #A3A3A3"
      >
        <Text w="8rem">{MapTitleForTask.attaches?.title}</Text>
        <Flex flex="1" width="100%" flexDirection="column">
          <Flex alignItems="flex-start" flexWrap="wrap">
            {attachesSelected?.map((file: FileModel, index: number) => {
              return (
                <PreviewFileComponent
                  key={index}
                  file={file}
                  indexFileUpload={indexFileUpload}
                  onDelete={onDeleteFile(index)}
                  isDelete={isDeleteFile || indexFileUpload.includes(index)}
                  readonly={!canEditTaskModal?.canEditAttachsField || !isOnline}
                />
              );
            })}
          </Flex>

          {canEditTaskModal?.canEditAttachsField && (
            <FormUploadFile
              onClick={onClickBtnSelectFile}
              onChange={onChangeFile()}
              type={TypeOfFile.ATTACH}
              style={{ flex: 1 }}
            >
              <Flex
                justifyContent={attachesSelected.length ? "end" : "start"}
                width="100%"
              >
                <FileUpload width="auto" accept="image/*, .pdf, .mp4" multiple>
                  <Button
                    position="relative"
                    mt={attachesSelected?.length ? "5px" : 0}
                    isDisabled={!!indexFileUpload.length && isCurrentTask}
                  >
                    選択
                  </Button>
                </FileUpload>
              </Flex>
            </FormUploadFile>
          )}
        </Flex>
      </Flex>
    </>
  );
});

export default ContentAfterFixed;
