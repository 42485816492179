import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { documentItemApi } from "apiClient/v2";
import { TypeFieldDynamic } from "constants/enum";
import { MessageType } from "constants/websocket";
import { useAuthorization } from "hooks/usePermission";
import useUserOfProject from "hooks/useUserOfProject";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { DocumentItemDTO } from "interfaces/dtos/documentItemDTO";
import { DataLog } from "interfaces/models/dataLog";
import { GetContentLog, insertDataLogToIndexedDb } from "models/dataLog";
import { getColorTextByStatus } from "models/document";
import { TypeHandleInitData } from "pages/forge-viewer/hooks/useSupportSyncDataOffline";
import { memo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { updateDocumentItem } from "redux/documentSlice";
import { sortArrayByField } from "utils/array";
import { transformBodyForCombineData } from "utils/offline";
import { DocumentTemplate } from "../../../interfaces/models/documentTemplate";
import DynamicFields from "../DocumentCategoryModal/DynamicFields";
import FieldNoComponent from "./FieldNoComponent";
import { useItemLogs } from "./hooks";
import useRevertDataByLogs from "./hooks/useRevertDataByLogs";
import ItemLog from "./ItemLog";
import ItemStatusMenu from "./ItemStatusMenu";
import ItemTasks from "./ItemTasks";
import ItemTitle, { ItemTitleType } from "./ItemTitle";
import { useForgeViewerContext } from "pages/forge-viewer/ForgeViewerContext";

type Props = {
  documentItemSelected?: DocumentItemDTO;
  documentCategorySelected?: DocumentCategoryDTO;
  documentTemplate: DocumentTemplate;
  isLoadedViewer: boolean;
  itemStatusColor: string;
  isOnline: boolean;
  itemDisplayOrder: number | string;
  dataLogTemp: DataLog | undefined;
};

const FormItemDataSheet = (props: Props) => {
  const {
    documentItemSelected,
    documentTemplate,
    isLoadedViewer,
    documentCategorySelected,
    itemStatusColor,
    isOnline,
    itemDisplayOrder,
    dataLogTemp,
  } = props;
  const dispatch = useDispatch();
  const { currentUser, listUserById, listAllUserById } = useUserOfProject();
  const { mapDynamicFieldTitle, itemLogs, isLoadMoreLogs, setItemLogs } =
    useItemLogs({
      itemId: documentItemSelected?.id || "",
      documentTemplate,
      dataLogTemp,
    });

  const { socket } = useForgeViewerContext();

  const { canEditDocumentItem } = useAuthorization();

  const insertItemLog = useCallback(
    async (params: GetContentLog) => {
      if (!currentUser?.id || !documentItemSelected?.id) {
        return;
      }

      const log = await insertDataLogToIndexedDb({
        contentLogParams: params,
        createdBy: currentUser.id,
        identifyValue: documentItemSelected.id,
        identifyCol: "itemId",
        type: "item",
      });

      if (documentCategorySelected?.id) {
        socket.addDocItemLog(documentItemSelected.id, log);
      }

      setItemLogs((prev) =>
        sortArrayByField<DataLog>([log, ...prev], "createdAt", false)
      );
    },
    [
      currentUser?.id,
      documentItemSelected?.id,
      setItemLogs,
      documentCategorySelected?.id,
      socket,
    ]
  );

  const { onRevertDataByLog } = useRevertDataByLogs({
    insertLog: insertItemLog,
    documentItemSelected,
  });

  const handleSaveDynamicField = useCallback(
    async (item: DocumentItemDTO) => {
      if (!documentItemSelected?.id) {
        return;
      }
      const now = new Date();
      documentItemApi
        .updateItem(
          transformBodyForCombineData<DocumentItemDTO>({
            body: {
              id: documentItemSelected?.id,
              data: item.data,
              mapTitleKey: item.mapTitleKey,
              mapDisplayValueKey: item.mapDisplayValueKey,
              mapDisplayLabelKey: item.mapDisplayLabelKey,
              mapDisplayValueTypeKey: item.mapDisplayValueTypeKey,
              updatedAt: now,
            } as DocumentItemDTO,
            bodyBefore: documentItemSelected,
            typeInitData: TypeHandleInitData.DOCUMENT_ITEM,
          })
        )
        .then(({ data }) => {
          socket.updateDocItem(documentItemSelected, {
            data: item.data,
            updatedAt: data.updatedAt,
          });
        });

      dispatch(
        updateDocumentItem({
          ...documentItemSelected,
          data: { ...(documentItemSelected?.data || {}), ...item.data },
          updatedAt: now,
        })
      );
    },
    [documentItemSelected, dispatch, socket]
  );

  return (
    <Flex flexDirection="column" padding="0px 1.6rem" minHeight="100%">
      {/* title */}
      <Flex mb="3.2rem" gap="1.2rem" alignItems="center">
        <FieldNoComponent
          color={itemStatusColor}
          no={itemDisplayOrder}
          colorText={getColorTextByStatus(documentItemSelected?.status)}
        />

        <Flex flexDirection="column" flex={1}>
          <Text lineHeight="2.2rem" color="font.default" fontSize="1.4rem">
            機器データシート
          </Text>
          <ItemTitle
            type={ItemTitleType.ITEM}
            item={documentItemSelected as DocumentItemDTO}
            insertItemLog={insertItemLog}
            inputProps={{ isDisabled: !canEditDocumentItem }}
          />
        </Flex>
      </Flex>

      {/* dynamic field*/}
      <DynamicFields
        mb="3.2rem"
        type={TypeFieldDynamic.TYPE_ITEM}
        template={documentTemplate}
        documentItemSelected={documentItemSelected}
        isDisabled={!canEditDocumentItem}
        insertDocumentItemLog={insertItemLog}
        onSaveDataForDocumentItem={handleSaveDynamicField}
      />

      {/* insert task */}
      <Box mb="3.2rem" borderTop="1px solid #A3A3A3" pt="2rem">
        <ItemTasks
          isOnline={isOnline}
          documentItem={documentItemSelected as DocumentItemDTO}
          isDisabled={!canEditDocumentItem}
          insertDocumentItemLog={insertItemLog}
        />
      </Box>

      {/* item logs */}
      <Box
        backgroundColor="#E8E8E8"
        ml="-1.6rem"
        mt="auto"
        width="calc(100% + 3.2rem)"
        padding="2rem 1.6rem"
      >
        <Text
          display="block"
          fontSize="1.6rem"
          color="var(--primary-text-color)"
          fontWeight="bold"
          mb="1.2rem"
        >
          変更履歴
        </Text>
        {itemLogs?.map((documentItemLog) => (
          <ItemLog
            key={documentItemLog.id}
            log={documentItemLog}
            user={listAllUserById?.[documentItemLog?.createdBy || ""]}
            currentUser={currentUser}
            listUserById={listUserById}
            mapDynamicFieldTitle={mapDynamicFieldTitle}
            onRevertDataByLog={onRevertDataByLog}
          />
        ))}

        {isLoadMoreLogs && (
          <Flex height="4rem" justifyContent="center" alignItems="center">
            <Spinner size="md" color="blue.500" />
          </Flex>
        )}
      </Box>

      {/* status */}
      <Flex
        position="sticky"
        width="calc(100% + 3.2rem)"
        marginLeft="-1.6rem"
        bottom="0"
        left="0"
        height="5.8rem"
        zIndex="12"
        backgroundColor="#E8E8E8"
        borderTop="1px solid rgba(0,0,0,0.15)"
        justifyContent="space-between"
        padding="0px 1.6rem"
        alignItems="center"
        gap="0.8rem"
      >
        <Text
          flexShrink={0}
          color="var(--primary-text-color)"
          fontSize="1.4rem"
        >
          ステータス
        </Text>
        <Box flex={1} maxW="35rem">
          <ItemStatusMenu
            isLoadedViewer={isLoadedViewer}
            currentUser={currentUser}
            documentItemSelected={documentItemSelected}
            documentCategorySelected={documentCategorySelected}
            isDisabled={!canEditDocumentItem}
            insertDocumentItemLog={insertItemLog}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default memo(FormItemDataSheet);
