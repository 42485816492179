import {
  Box,
  Button,
  Divider,
  Flex,
  Input,
  Spinner,
  Text,
  useDisclosure,
  useOutsideClick,
} from "@chakra-ui/react";
import { IconBase } from "components/base";
import SelectUserMulti from "components/input/SelectUserMulti";
import useOtherForm from "components/modal/DocumentCategoryModal/hooks/useOtherForm";
import PreviewDocumentCategory, {
  IPreviewDocumentCategoryHandle,
} from "components/modal/PreviewDocumentCategory";
import CollapseModalIcon, {
  useCollapseModal,
} from "components/ui/CollapseModalIcon";
import OfflineLabel from "components/ui/OfflineLabel";
import DocumentCategoryProgress from "components/widgets/DocumentCategoryProgress";
import { DOCUMENT_TYPE_INFO } from "constants/document";
import { DocumentCategoryKey, TypeFieldDynamic } from "constants/enum";
import { defaultIconEditDocument } from "constants/file";
import {
  RIGHT_SIDEBAR_MODAL_CLASSNAME,
  RIGHT_SIDEBAR_MODAL_CONTAINER_ID,
} from "constants/styleProps";
import PreviewDocumentCategoryContext from "contexts/PreviewDocumentCategoryContext";
import { useDownloadPdf } from "hooks/useDownloadPdf";
import { useAuthorization, useRoles } from "hooks/usePermission";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { iDocumentDataProps } from "interfaces/models/document";
import { DocumentCategory } from "interfaces/models/documentCategory";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { FilterDocumentCategoryByUserSettingProps } from "models/document";
import {
  isEquipmentDataSheetTemplate,
  isPhotoLedgerTemplate,
  isSelfInspectionTemplate,
} from "models/documentCategory";
import { LeftPanelHandleType } from "pages/forge-viewer/LeftPanel";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import store, { RootState } from "redux/store";
import { ENTITY_NAME } from "utils/data-logs";
import ConfirmModal from "../ConfirmDeleteModal";
import ItemLog from "../DocumentItemModal/ItemLog";
import DynamicFields, { iDynamicFilesHandle } from "./DynamicFields";
import FormCategoryForDocumentData from "./FormCategoryForDocumentData";
import useDocumentCategory from "./hooks";

interface Props {
  filterDocumentCategories: DocumentCategoryDTO[];
  filterDocumentCategoryOptions: FilterDocumentCategoryByUserSettingProps;
  isDisableCaptureKeynote?: boolean;
  leftPanelRef?: React.RefObject<LeftPanelHandleType>;
  setLoadedViewerOff?: () => void;
  onClickDocItem: (data: iDocumentDataProps) => void;
}

export type DocumentCategoryModelHandleType = {
  onClosePreview: () => void;
  handleUpdateCurrentDocument: () => void;
  handleCollapse: (val?: boolean) => void;
};

const DocumentCategoryModal = forwardRef<
  DocumentCategoryModelHandleType,
  Props
>(
  (
    {
      filterDocumentCategoryOptions,
      isDisableCaptureKeynote = false,
      setLoadedViewerOff,
    },
    ref
  ) => {
    const { isPartner } = useRoles();
    const { isLoadedViewer, families } = useSelector(
      (state: RootState) => state.forgeViewer
    );

    const { isLoadedBlackboard, isLoadingDocument } = useSelector(
      (state: RootState) => state.document
    );

    const { sizePanel } = useSelector((state: RootState) => state.app);
    const { currentUser, isFetchingUsers } = useSelector(
      (state: RootState) => state.user
    );

    const widthPanel = useMemo(
      () => `${sizePanel.width}${sizePanel.unit}`,
      [sizePanel]
    );

    const { isCollapsed, setIsCollapsed } = useCollapseModal();

    const { isOpen: isOpenConfirmModal, onClose: closeConfirmModal } =
      useDisclosure();

    const {
      mapDynamicFieldTitle,
      isLoadMoreLogs,
      documentCategoryLogs,
      partnerCompanies,
      isEditingFamily,
      documentType,
      isCaptureKeynoteByOperation,
      isChangeSheet,
      documentKeynoteData,
      keynoteImageData,
      isCreatingKeynote,
      documentCategorySelected,
      documentItemsHasImage,
      documentCategoryModalInfo,
      listUserById,
      listAllUserById,
      companiesById,
      loadingPreview,
      isOpenPreview,
      currentDocument,
      neptuneAreas,
      template,
      components,
      isDocumentCategoryCurrent,
      onBlurOtherForm: onBlurForm,
      onClosePreview,
      handleUpdateCurrentDocument,
      handleDisplayDocument,
      handleDeleteDocumentCategory,
      onBlurCombineOtherForm,
      onSaveDynamicField,
      updateDataRedux,
      handleChangeSelectedSheet,
      setDocumentKeynoteData,
      setKeynoteImageData,
      insertDocumentCategoryLog,
      handleUpdateFieldsChangeData,
      isFetchingUserAssigned,
      handleRevertCategoryByLogs,
      onChangeCurrentDocument,
    } = useDocumentCategory({
      isDisableCaptureKeynote,
      filterDocumentCategoryOptions,
      setLoadedViewerOff,
    });

    const roles = useAuthorization();

    useEffect(() => {
      if (documentCategoryModalInfo?.id !== documentCategorySelected?.id) {
        setIsCollapsed(false);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentCategoryModalInfo?.id, documentCategorySelected?.id]);

    const { data, onChange, onBlur } = useOtherForm({
      documentCategoryModalInfo,
      onBlurForm,
      onBlurCombineForm: onBlurCombineOtherForm,
      handleUpdateFieldsChangeData,
    });

    const [isDisplayDocumentItems, setIsDisplayDocumentItems] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [loadingPrintPdf, setLoadingPrintPdf] = useState(false);

    const previewRef = useRef<IPreviewDocumentCategoryHandle>(null);
    const parentRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const footerRef = useRef<HTMLDivElement>(null);
    const dynamicFieldsRef = useRef<iDynamicFilesHandle>(null);

    const { handleDownloadPDF, handlePrintPdf, clearCachePdf } = useDownloadPdf(
      {
        setLoading: setLoadingDownload,
        setLoadingPrintPdf,
        title: documentCategorySelected?.title,
      }
    );

    useImperativeHandle(ref, () => ({
      onClosePreview: () => {
        onClosePreview();
        if (previewRef?.current?.resetValuePreview) {
          previewRef?.current?.resetValuePreview();
        }
      },
      handleCollapse: (val = true) => {
        if (val) {
          setIsCollapsed(true);
        } else {
          setIsCollapsed(false);
        }
      },
      handleUpdateCurrentDocument,
    }));

    const isPreviewDisabled = useMemo(
      () => !isDisplayDocumentItems || !isLoadedViewer || isEditingFamily,
      [isDisplayDocumentItems, isEditingFamily, isLoadedViewer]
    );

    const handleUpdateData = useCallback(() => {
      const newDocumentCategory = structuredClone(data);

      // While applying a document filter, press reset filter to reassign the original document items value
      const state = store.getState().document;
      const originalDocumentCategory = state.documentCategories.find(
        (doc) => doc.id === documentCategorySelected?.id
      );
      newDocumentCategory.documentItems =
        originalDocumentCategory?.documentItems;
      let cateSelected =
        structuredClone(documentCategorySelected) ||
        ({} as DocumentCategoryDTO);

      let dynamicFieldData =
        dynamicFieldsRef.current?.getDynamicFieldData() || {};

      if (isEmpty(dynamicFieldData)) {
        dynamicFieldData = {};
      }
      if (!cateSelected?.data || isEmpty(cateSelected?.data)) {
        cateSelected = {
          ...cateSelected,
          data: {},
        };
      }
      newDocumentCategory.data = dynamicFieldData;

      const isEqualCateId = newDocumentCategory?.id === cateSelected?.id;

      let isDiffData = false;
      if (cateSelected?.id && isEqualCateId) {
        Object.keys(newDocumentCategory).forEach((key) => {
          if (key === DocumentCategoryKey.CHILD_TEMPLATES) {
            return;
          }

          const cate1st = (newDocumentCategory as any)?.[key];
          const cate2nd = (cateSelected as any)?.[key];
          if (!isEqual(cate1st, cate2nd)) {
            isDiffData = true;
          }
        });
      }

      if (isEqualCateId && newDocumentCategory?.title !== cateSelected?.title) {
        return;
      }

      if (isDiffData) {
        updateDataRedux(newDocumentCategory as DocumentCategoryDTO);
      }
    }, [data, documentCategorySelected, updateDataRedux]);

    useEffect(() => {
      if (!documentCategorySelected?.id) {
        return;
      }

      setIsDisplayDocumentItems(
        documentCategorySelected?.id === documentCategoryModalInfo?.id
      );
    }, [documentCategorySelected?.id, documentCategoryModalInfo?.id]);

    useOutsideClick({
      ref: parentRef,
      handler: (e) => {
        const target: HTMLElement | undefined = e.target as any;
        const subTagMenuElement =
          document.getElementsByClassName("sub-menu-tag-input")[0];
        if (
          target &&
          !!subTagMenuElement &&
          subTagMenuElement.contains(target)
        ) {
          return;
        }

        if (
          documentCategoryModalInfo?.id === documentCategorySelected?.id &&
          documentCategoryModalInfo.title !== documentCategorySelected.title
        ) {
          return;
        }

        if (
          !isOpenPreview &&
          documentCategoryModalInfo?.id &&
          documentCategoryModalInfo?.id === documentCategorySelected?.id
        ) {
          handleUpdateData();
        }
      },
    });

    const handleCollapse = () => {
      !isCollapsed && handleUpdateData();
      setIsCollapsed((prev) => !prev);
    };

    const documentItems = useMemo(() => {
      const docItems = documentCategorySelected?.documentItems?.map(
        (documentItem, index) => ({
          ...documentItem,
          displayOrder: index,
        })
      ) as any;
      if (isSelfInspectionTemplate(documentCategorySelected?.documentType)) {
        return docItems || [];
      }

      return (docItems || []).filter((item: any) =>
        documentCategorySelected?.selectedExternalIds?.includes(item?.id || "")
      );
    }, [
      documentCategorySelected?.selectedExternalIds,
      documentCategorySelected?.documentItems,
      documentCategorySelected?.documentType,
    ]);

    const documentTypeLabel = useMemo(() => {
      //@ts-ignore
      return DOCUMENT_TYPE_INFO[documentType || ""]?.typeName || "";
    }, [documentType]);

    return (
      <>
        <Box
          className={RIGHT_SIDEBAR_MODAL_CLASSNAME}
          w={isCollapsed ? "0" : widthPanel}
          position="absolute"
          right={0}
          height="100%"
          zIndex={11}
          transition="all 200ms ease-in-out 0s"
          filter={`drop-shadow(${
            isOpenPreview && isDocumentCategoryCurrent
              ? "transparent"
              : "rgba(0, 0, 0, 0.15)"
          } 0px 8px 12px) drop-shadow(${
            isOpenPreview && isDocumentCategoryCurrent
              ? "transparent"
              : "rgba(0, 0, 0, 0.3)"
          } 0px 4px 4px)`}
        >
          <Box
            ref={parentRef}
            backgroundColor="white"
            padding="0px"
            width="100%"
            position="relative"
            flexDirection="column"
          >
            <CollapseModalIcon
              backgroundColor="white"
              borderRadius="5px 0px 0px 5px"
              borderRightStyle="hidden"
              borderLeftStyle="solid"
              height="12rem"
              width="3.8rem"
              top="50%"
              iconProps={{
                transform: isCollapsed ? "rotate(90deg)" : "rotate(-90deg)",
              }}
              transform="translateY(-50%)"
              onClose={handleCollapse}
            />

            <Flex
              ref={containerRef}
              id={RIGHT_SIDEBAR_MODAL_CONTAINER_ID}
              flexDirection="column"
              overflow="hidden auto"
              sx={{
                "& input, & textArea": {
                  borderColor: "#A3A3A3",
                },
                "& ::placeholder": {
                  color: "#A3A3A3",
                  opacity: 1,
                },
              }}
              h="calc(var(--app-height) - var(--header-height) - var(--sub-header-height))"
            >
              {(isPhotoLedgerTemplate(documentCategorySelected?.documentType) &&
                !isLoadedBlackboard) ||
              !data?.id ||
              isLoadingDocument ? (
                <Flex mt="2rem" alignItems="center" justifyContent="center">
                  <Spinner color="blue.500" size="lg" mt="1rem" />
                </Flex>
              ) : (
                <>
                  {documentCategorySelected?.blockedBy && (
                    <OfflineLabel
                      minHeight={"3.6rem"}
                      zIndex="20"
                      p={"0.5rem 1.6rem"}
                      position="fixed"
                      blockedBy={documentCategorySelected?.blockedBy}
                    />
                  )}
                  {/* document category name */}
                  <Flex
                    marginTop={
                      documentCategorySelected?.blockedBy ? "3.6rem" : 0
                    }
                    padding="1.6rem"
                    direction="column"
                  >
                    <Flex gap="1.2rem" alignItems="center">
                      <IconBase
                        width="4.8rem"
                        height="4.8rem"
                        color="icon.blackBold"
                        icon={defaultIconEditDocument}
                      />
                      <Flex flexDirection="column" flex={1}>
                        <Text
                          fontSize="1.4rem"
                          mb="0.5rem"
                          as={"span"}
                          textAlign="left"
                          color="#171717"
                        >
                          {documentTypeLabel}
                        </Text>
                        <Input
                          value={data?.title || ""}
                          height="4rem"
                          fontSize="1.4rem"
                          placeholder="書類名"
                          w="100%"
                          borderColor={"#A3A3A3"}
                          onChange={onChange(DocumentCategoryKey.TITLE)}
                          onBlur={onBlur(DocumentCategoryKey.TITLE)}
                          isDisabled={!roles.canEditDocumentCategoryTitle}
                        />
                        {!data?.title?.trim() && data?.id && (
                          <Text fontSize="1.2rem" mt="0.3rem" color="#EF4444">
                            書類名が必要です。
                          </Text>
                        )}
                      </Flex>
                    </Flex>
                    <Box
                      marginBlockStart="2rem"
                      marginInlineStart="calc(48px + 1.6rem)"
                    >
                      <DocumentCategoryProgress
                        propListCounts={{ mt: "0.8rem" }}
                        documentCategorySelected={documentCategorySelected}
                      />
                    </Box>
                    {/* user assigned */}
                    {!isPartner && (
                      <Flex
                        mt="3rem"
                        w="100%"
                        alignItems="center"
                        color="#171717"
                      >
                        <Text textAlign="left" flexBasis="8rem">
                          書類担当者
                        </Text>
                        <Flex flex={1}>
                          <SelectUserMulti
                            isLoading={isFetchingUsers}
                            users={listUserById}
                            value={data?.userAssigned || []}
                            onChange={onChange(
                              DocumentCategoryKey.USER_ASSIGNED
                            )}
                            onCloseModal={onBlur(
                              DocumentCategoryKey.USER_ASSIGNED
                            )}
                            onTagRemove={(tags) => {
                              onBlurForm(
                                DocumentCategoryKey.USER_ASSIGNED,
                                tags as any
                              );
                            }}
                            placeHolder="選択してください"
                            isShowIcon={false}
                            styleContent={{
                              overflow: "auto",
                              maxHeight: "21rem",
                              overflowY: "auto",
                            }}
                            styleProps={{
                              w: "100%",
                              minH: "4rem",
                              p: 0,
                              borderColor: "#A3A3A3",
                            }}
                            styleWrapTag={{
                              padding: "0",
                            }}
                            styleTag={{
                              height: "3.4rem",
                              margin: "0.4rem var(--wrap-x-spacing)",
                            }}
                            fullWidth
                          />
                        </Flex>
                      </Flex>
                    )}

                    <Divider
                      opacity={1}
                      mt="3.2rem"
                      mb="1.6rem"
                      borderColor="#A3A3A3"
                    />

                    {/* document data */}
                    <FormCategoryForDocumentData
                      template={template}
                      listUserById={listUserById}
                      isFetchingUserAssigned={isFetchingUserAssigned}
                      data={data as DocumentCategory}
                      partnerCompanies={partnerCompanies}
                      families={families}
                      onChange={onChange}
                      onBlur={onBlur}
                      isDisabled={!roles.canEditDocumentCategoryInfo}
                    />

                    {/* Dynamic field */}
                    <Flex
                      mt="3rem"
                      className="list-dynamic-field"
                      flexDirection="column"
                    >
                      {!isEquipmentDataSheetTemplate(documentType) && (
                        <DynamicFields
                          ref={dynamicFieldsRef}
                          type={TypeFieldDynamic.TYPE_CATEGORY}
                          template={template}
                          inputHeight="4rem"
                          placeholderColor="#A3A3A3"
                          documentCategorySelected={documentCategoryModalInfo}
                          borderBottom="none"
                          onSaveDataForDocumentCategory={onSaveDynamicField}
                          insertDocumentItemLog={insertDocumentCategoryLog}
                          isDisabled={!roles.canEditDocumentItem}
                        />
                      )}
                    </Flex>
                  </Flex>

                  {/* logs */}
                  <Box
                    backgroundColor="#E8E8E8"
                    mt="auto"
                    padding="2rem 1.6rem"
                  >
                    <Text
                      display="block"
                      fontSize="1.6rem"
                      color="var(--primary-text-color)"
                      fontWeight="bold"
                      mb="1.2rem"
                    >
                      変更履歴
                    </Text>

                    {documentCategoryLogs?.map((item) => (
                      <ItemLog
                        key={item.id}
                        log={item}
                        entityName={ENTITY_NAME.DOCUMENT}
                        user={listAllUserById?.[item?.createdBy || ""]}
                        currentUser={currentUser}
                        listUserById={listUserById}
                        companiesById={companiesById}
                        mapDynamicFieldTitle={mapDynamicFieldTitle}
                        onRevertDataByLog={handleRevertCategoryByLogs}
                      />
                    ))}

                    {isLoadMoreLogs && (
                      <Flex
                        height="4rem"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Spinner size="md" color="blue.500" />
                      </Flex>
                    )}
                  </Box>

                  <Flex
                    ref={footerRef}
                    position="sticky"
                    bottom="0"
                    zIndex={30}
                    p="1rem 1.6rem"
                    bgColor="#E5E5E5"
                  >
                    <Button
                      ml="auto"
                      fontWeight="500"
                      variant="filled"
                      p="1.2rem 1.6rem"
                      height="4rem"
                      width="100%"
                      isLoading={loadingPreview && isDocumentCategoryCurrent}
                      disabled={isPreviewDisabled}
                      onClick={() => {
                        clearCachePdf();
                        handleUpdateData();
                        handleDisplayDocument(
                          false,
                          data as DocumentCategoryDTO
                        );
                      }}
                    >
                      書類を表示
                    </Button>
                  </Flex>
                </>
              )}
            </Flex>
          </Box>
        </Box>

        {isOpenPreview && isDocumentCategoryCurrent && (
          <PreviewDocumentCategoryContext.Provider
            value={{
              keynoteImageData,
              documentKeynoteData: documentKeynoteData!,
              setDocumentKeynoteData,
              setKeynoteImageData,
              isChangeSheet,
            }}
          >
            <PreviewDocumentCategory
              ref={previewRef}
              isChangeSheet={isChangeSheet}
              isLoadingPreview={loadingPreview}
              isShowFullWidth={!!isCaptureKeynoteByOperation}
              isCreatingKeynote={isCreatingKeynote}
              neptuneAreas={neptuneAreas}
              template={template}
              components={components || []}
              currentDocument={currentDocument}
              documentCategorySelected={documentCategorySelected}
              documentItems={documentItems}
              documentItemsHasImage={documentItemsHasImage}
              isLoadingDownload={loadingDownload}
              isOpen={isOpenPreview && isDocumentCategoryCurrent}
              listAllUserById={listAllUserById}
              companiesById={companiesById}
              loadingPrintPdf={loadingPrintPdf}
              handlePrintPdf={handlePrintPdf}
              clearCachePdf={clearCachePdf}
              onDownload={(isFromContentEditor) =>
                handleDownloadPDF(false, isFromContentEditor)
              }
              onClose={onClosePreview}
              handleChangeSelectedSheet={handleChangeSelectedSheet}
              onChangeCurrentDocument={onChangeCurrentDocument}
            />
          </PreviewDocumentCategoryContext.Provider>
        )}

        {isOpenConfirmModal && (
          <ConfirmModal
            title="削除の確認"
            content="削除してよろしいですか。"
            buttonConfirm="削除する"
            isDelete
            isOpen={isOpenConfirmModal}
            onClose={closeConfirmModal}
            onProcessing={() => {
              handleDeleteDocumentCategory(data.id);
            }}
          />
        )}
      </>
    );
  }
);

export default DocumentCategoryModal;
