import {
  Box,
  BoxProps,
  Button,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  UseModalProps,
} from "@chakra-ui/react";
import { IconBase } from "components/base";
import { SvgIcon } from "components/SvgIcon";

interface Props extends UseModalProps {}

export interface ContentProps {
  buttonText: string;
  title: React.ReactNode;
  descriptions: React.ReactNode;
  boxProps?: BoxProps;
  boxContentContainer?: BoxProps;
  buttonProps?: BoxProps;
  descriptionsProps?: BoxProps;
}

const CONTENTS: ContentProps[] = [
  {
    buttonText: "準備",
    title: "事前に、プロジェクトデータのダウンロードが必要です。",
    descriptions: (
      <>
        <Text>
          あらかじめ、プロジェクトのデータをiPad内にダウンロードしておくことで
          オフラインの場所でも、利用することが可能になります。
        </Text>
      </>
    ),
  },
  {
    buttonText: "準備・確認",
    title: "できるだけ「最新」のデータをダウンロードして活用ください。",
    descriptions: (
      <>
        <Text>
          ダウンロードして時間が経ってしまった「古いデータ」を元に作業すると、
        </Text>
        <Text color="font.danger">
          古いデータで新しいデータを上書きし、消してしまう可能性があります。
        </Text>
      </>
    ),
  },
  {
    buttonText: "開始前",
    title:
      "自分が編集する書類に「オフライン作業中」のラベルを表示し他の人が触らないよう、注意喚起してください。",
    descriptions: (
      <>
        <Text>同じ書類を「複数人で同時に」編集しないでください。</Text>
        <Text display="inline">意図せぬデータの上書きが起こり、</Text>
        <Text display="inline" color="font.danger">
          データが消える可能性があります。
        </Text>
      </>
    ),
  },
  {
    buttonText: "終了後",
    title: (
      <>
        <Text>オフラインモード使用後は、</Text>
        <Text>出来るだけ速やかに「オンラインモード」に戻してください。</Text>
      </>
    ),
    descriptions:
      "情報を上書きしてしまう・されてしまう事故を防止することができます。",
  },
  {
    buttonText: "仕組み",
    title: "優先されるのは「更新日時が新しいデータ」です。",
    descriptions: (
      <>
        <Text>オフラインモードで作業したあと、オンラインモードに戻し</Text>
        <Text mb="1rem">
          オフラインで編集したデータをアップロードしてください。
        </Text>
        <Text>
          検査項目ごとに「いつデータが変更されたか」という更新日時を記録しています。
        </Text>
        <Text>
          更新日時がより新しいデータで、古いデータを上書きする仕組みです。
        </Text>
      </>
    ),
  },
];

export const Content = ({
  buttonText,
  title,
  descriptions,
  boxProps,
  boxContentContainer,
  buttonProps,
  descriptionsProps,
}: ContentProps) => {
  return (
    <Flex
      padding="1.4rem 1.2rem"
      borderTop="1px solid #D4D4D4"
      gap="1.8rem"
      {...boxProps}
    >
      <Flex
        flexShrink={0}
        color="font.gray"
        width="9rem"
        height="3rem"
        border="1px solid #737373"
        fontSize="1.2rem"
        fontWeight={500}
        alignItems="center"
        justifyContent="center"
        {...buttonProps}
      >
        <Text color="colors.gray">{buttonText}</Text>
      </Flex>

      <Box flex={1} maxWidth="55rem" {...boxContentContainer}>
        <Box
          color="font.gray"
          fontSize="1.8rem"
          lineHeight="2.7rem"
          fontWeight={800}
          mb="1.4rem"
        >
          {title}
        </Box>

        <Box color="font.gray" lineHeight="2.24rem" {...descriptionsProps}>
          {descriptions}
        </Box>
      </Box>

      {/* <Box
        cursor="pointer"
        my="auto"
        flexShrink={0}
        ml="auto"
        alignItems="center"
        height="100%"
      >
        <Flex alignItems="center">
          <Text fontSize="1.4rem" color="font.blue">
            詳細
          </Text>
          <IconBase
            width="2.4rem"
            height="2.4rem"
            icon="/img/arrow-right3.svg"
            color="font.blue"
            transform="rotate(90deg)"
          />
        </Flex>
      </Box> */}
    </Flex>
  );
};

function OfflineModeTutorialModal({ isOpen, onClose, ...rest }: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true} {...rest}>
      <ModalOverlay />
      <ModalContent
        background="background.secondary"
        position="relative"
        borderRadius="8px"
        p="2rem 3.7rem 2.1rem 3.2rem"
        maxWidth="80rem"
      >
        <ModalCloseButton fontSize="12px" _focusVisible={{}} />

        <Box mt="2rem">
          <Flex alignItems="center" justifyContent="center" mb="2.2rem">
            <IconBase
              icon="/img/warning-icon.svg"
              width="5rem"
              height="5rem"
              color="font.danger"
            />
            <Text color="font.danger" fontSize="3.4rem" fontWeight={500}>
              オフラインモードの使い方・注意点
            </Text>
          </Flex>

          <Box height="fit-content" maxHeight="75vh" overflowY="auto" pr="1rem">
            {CONTENTS.map((content, index) => (
              <Content key={index} {...content} />
            ))}
          </Box>
        </Box>
      </ModalContent>
    </Modal>
  );
}

export default OfflineModeTutorialModal;
