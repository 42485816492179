import {
  Box,
  BoxProps,
  Button,
  Flex,
  HStack,
  Progress,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { bimFileApi } from "apiClient/v2";
import { message } from "components/base";
import {
  ClearDatabaseCachedIcon,
  NetworkStatusIcon,
  SyncDataForOfflineIcon,
} from "components/icon";
import ForgeHeaderItem from "components/Layout/ForgeHeaderItem";
import ProfileMenu from "components/Layout/ProfileMenu";
import { SvgIcon } from "components/SvgIcon";
import SystemModeSwitch from "components/ui/SystemModeSwitch";
import { buttonHeaderItemStyle } from "constants/header";
import useCheckProjectCached, {
  iCachedProject,
} from "hooks/useCheckProjectCached";
import useGetCurrentPath from "hooks/useGetCurrentPath";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { setIsShowOfflineMsg } from "redux/appSlice";
import { setIsCreateProject } from "redux/bimSlice";
import { resetWidgetsMode } from "redux/forgeViewerSlice";
import { setDataProjectDetail } from "redux/projectSlice";
import store, { RootState } from "redux/store";
import { routePath } from "routes/path";
import { getBimFileInfo } from "utils/bim";
import { getCacheProjectById, getDatabaseSize } from "utils/indexedDb";
import { logError } from "utils/logs";
import { getHumanReadableSize } from "utils/storage";
import HomeHeaderSetting from "./HomeHeaderSetting";

interface Props extends BoxProps {
  bg?: string;
  projectId?: string;
}

const Header = ({ bg, ...rest }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isMobileWithWidth] = useMediaQuery("(max-width: 667px)");
  const [isMobileWithHeight] = useMediaQuery("(max-height: 476px)");
  const [isWidthLessThan800] = useMediaQuery("(max-width: 800px)");
  const [isWidthLargeThan800] = useMediaQuery("(min-width: 800px)");
  const [isWidthLessThan1024] = useMediaQuery("(max-width: 1024px)");
  const [offlineEditedDataSize, setOfflineEditedDataSize] = useState<
    number | undefined
  >(undefined);
  const {
    isOnline,
    isSyncOfflineData,
    isServiceWorkerReady,
    actualNetworkStatus,
    timeBeginOffline,
    syncDataOption,
  } = useSelector((state: RootState) => state.app);
  const {
    cachingBimFileId,
    dataProjectDetail,
    lastCheckCacheProject,
    projectDetail,
  } = useSelector((state: RootState) => state.project);
  const { levels, levelSelected, widgetsMode, isLoadedViewerModelData } =
    useSelector((state: RootState) => state.forgeViewer);

  const { currentUser } = useSelector((state: RootState) => state.user);
  const { pathPattern, params } = useGetCurrentPath();

  const timeoutRef = useRef<any>();
  const [cachedInfo, setCachedInfo] = useState<iCachedProject>();
  useEffect(() => {
    if (!dataProjectDetail?.id) {
      return;
    }
    (async () => {
      const cache = await getCacheProjectById(dataProjectDetail?.id);
      setCachedInfo(cache);
    })();
  }, [lastCheckCacheProject, dataProjectDetail?.id]);

  const { progress, isLoading, stopCacheProject, handleBeforeSyncProjectData } =
    useCheckProjectCached({
      project: dataProjectDetail,
      cachingBimFileId,
      isServiceWorkerReady,
      cachedInfo,
      isOnline,
    });

  const handleUpdateCacheDataSize = useCallback(() => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      getDatabaseSize().then((value) => {
        setOfflineEditedDataSize(value);
      });
    }, 200);
  }, []);

  useEffect(() => {
    const unSubscribe = store.subscribe(() => {
      handleUpdateCacheDataSize();
    });
    handleUpdateCacheDataSize();

    return () => {
      unSubscribe();
    };
  }, [handleUpdateCacheDataSize, isOnline]);

  const syncDataTime = useMemo(() => {
    return (
      syncDataOption?.mapSyncDataTimeByProject?.[dataProjectDetail?.id || ""] ||
      null
    );
  }, [syncDataOption, dataProjectDetail?.id]);

  const levelSelectedLabel = useMemo(
    () =>
      levelSelected.guid
        ? levels.find((c) => c.guid === levelSelected.guid)?.label
        : "",
    [levels, levelSelected.guid]
  );

  const isMobile = useMemo(
    () => isMobileWithWidth || isMobileWithHeight || isWidthLessThan800,
    [isMobileWithWidth, isMobileWithHeight, isWidthLessThan800]
  );

  const isShowProfileMenu = useMemo(() => {
    return !!currentUser;
  }, [currentUser]);

  const isShowHomeIcon = useMemo(() => {
    return [routePath.Profile]?.includes(pathPattern);
  }, [pathPattern]);

  const isWidthFrom800To1024 = useMemo(() => {
    return isWidthLargeThan800 && isWidthLessThan1024;
  }, [isWidthLargeThan800, isWidthLessThan1024]);

  const isLargeScreenForgeViewer = useMemo(() => {
    return isWidthFrom800To1024 && routePath.ForgeViewer === pathPattern;
  }, [isWidthFrom800To1024, pathPattern]);

  const isShowTitle = useMemo(() => {
    return (
      !isShowHomeIcon &&
      ![routePath.UserDashboard, routePath.ProjectDashboard]?.includes(
        pathPattern
      )
    );
  }, [pathPattern, isShowHomeIcon]);

  const isPageForgeViewer = useMemo(() => {
    return [routePath.ForgeViewer]?.includes(pathPattern);
  }, [pathPattern]);

  const isShowForgeItemNew = useMemo(() => {
    return [routePath.ForgeViewer]?.includes(pathPattern);
  }, [pathPattern]);

  const isShowLogo = useMemo(() => {
    return ![routePath.Profile]?.includes(pathPattern);
  }, [pathPattern]);

  const isShowSetting = useMemo(() => {
    return (
      currentUser &&
      ![routePath.ProjectDashboard, routePath.UserDashboard]?.includes(
        pathPattern
      )
    );
  }, [pathPattern, currentUser]);

  const isShowDashBoardTitle = useMemo(() => {
    return [routePath.ProjectDashboard, routePath.UserDashboard].includes(
      pathPattern as any
    );
  }, [pathPattern]);

  const isShowClearDatabaseCached = useMemo(
    () => [routePath.Home]?.includes(pathPattern),
    [pathPattern]
  );

  const isShowSyncDataForOfflineIcon = useMemo(
    () => [routePath.ForgeViewer]?.includes(pathPattern),
    [pathPattern]
  );

  const renderHeaderTilte = () => {
    switch (pathPattern) {
      case routePath.ProjectOverview: {
        return (
          <HStack spacing="0.8rem">
            <Text whiteSpace="nowrap">プロジェクト</Text>
            {/* <SvgIcon src="/img/icon-navigation-chevron_right.svg" />
            <Text whiteSpace="nowrap">{projectDetail?.name || ""}</Text> */}
          </HStack>
        );
      }

      case routePath.Profile: {
        return <Text whiteSpace="nowrap">プロファイル</Text>;
      }

      default: {
        return (
          <>
            <Text whiteSpace="nowrap">プロジェクト選択</Text>
            {dataProjectDetail?.id && (
              <>
                <SvgIcon src="/img/icon-navigation-chevron_right.svg" />
                <Text whiteSpace="nowrap">BIM360データ選択</Text>
              </>
            )}
          </>
        );
      }
    }
  };

  const handleCloseUserDashboardModal = useCallback(() => {
    dispatch(resetWidgetsMode());
  }, [dispatch]);

  const navigateToHome = useCallback(() => {
    navigate(routePath.Home);
    dispatch(setDataProjectDetail({} as DataProjectModel));
    dispatch(setIsCreateProject(false));
    widgetsMode && dispatch(resetWidgetsMode());
  }, [dispatch, navigate, widgetsMode]);

  const navigateToForgeViewPage = async (state?: Object) => {
    if (!currentUser?.id) {
      return;
    }

    try {
      const { data: dataProjects } = await bimFileApi.getProjectList();
      const currentDataProject = dataProjects.find(
        (project) => project.id === params?.projectId
      );
      const urn = currentDataProject?.defaultBimPathId?.split("/").pop();
      const { bimFileId, version } = getBimFileInfo(urn || "");

      navigate(
        generatePath(routePath.ForgeViewer, {
          projectId: params?.projectId,
          bimFileId,
          version,
        }),
        {
          state,
        }
      );
    } catch (err) {
      logError(err);
    }
  };

  const uploadOfflineDataStatus = useMemo(() => {
    if (offlineEditedDataSize === undefined) {
      return <></>;
    }

    if (!isOnline) {
      return <Text>{getHumanReadableSize(offlineEditedDataSize)}</Text>;
    }

    if (isSyncOfflineData && offlineEditedDataSize) {
      return (
        <Flex flexDir="column" pt="5px">
          <Text userSelect="none">
            {getHumanReadableSize(offlineEditedDataSize)}
          </Text>
          <Progress
            size="sm"
            w="100%"
            bgColor={"var(--chakra-colors-gray-300)"}
            colorScheme={"completed"}
            hasStripe={true}
            isAnimated={true}
            isIndeterminate
            border="5px"
          />
        </Flex>
      );
    }

    return <></>;
  }, [isOnline, isSyncOfflineData, offlineEditedDataSize]);

  const IconHome = (props?: React.HTMLAttributes<HTMLDivElement>) => (
    <Box
      width="28px"
      height="28px"
      flexShrink={0}
      background="#215283"
      display="flex"
      justifyContent="center"
      alignItems="center"
      cursor="pointer"
      {...props}
    >
      <SvgIcon
        src="/img/icon-home.svg"
        width="18px"
        height="12px"
        flexShrink={0}
      />
    </Box>
  );

  const TitleHeader = () => (
    <Text
      fontSize="2.2rem"
      color="#215283"
      fontWeight="500"
      lineHeight="2.2rem"
      letterSpacing="1px"
      cursor="pointer"
      onClick={navigateToHome}
    >
      品質管理
    </Text>
  );

  useEffect(() => {
    if (
      !isOnline &&
      isShowForgeItemNew &&
      isLoading !== undefined &&
      (progress || 0) < 100
    ) {
      message.warning([
        "インターネット接続が切れました。",
        "オフライン環境で利用したい場合には、オフラインモードをご活用ください。（オフラインモードを利用する際には、事前にプロジェクトデータのダウンロードが必要です）",
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]);

  useEffect(() => {
    // Do not show offline messages when in the forge viewer screen without caching the project
    if (isShowForgeItemNew) {
      dispatch(setIsShowOfflineMsg((progress || 0) >= 100));
    }

    return () => {
      dispatch(setIsShowOfflineMsg(true));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowForgeItemNew, (progress || 0) >= 100]);

  const isCaching = useMemo(() => {
    return !!dataProjectDetail?.id && cachingBimFileId === dataProjectDetail.id;
  }, [cachingBimFileId, dataProjectDetail?.id]);

  return (
    <>
      <Box
        id="neptune-header"
        minH="var(--header-height)"
        boxShadow="0px 1px 3px 1px #00000026"
        filter="drop-shadow(rgba(0, 0, 0, 0.23) 0px 2px 2.62px)"
        bgColor={bg || "#fff"}
        zIndex="tooltip"
        display={"flex"}
        alignItems={"center"}
      >
        <Box
          w={"100%"}
          display={"flex"}
          alignItems={"center"}
          p={isLargeScreenForgeViewer ? "0.5rem 0.8rem" : "0.5rem 1.6rem"}
        >
          <Box
            display="flex"
            width={"100%"}
            gap={"0.8rem"}
            flexDirection="row"
            alignItems="center"
            justifyContent={"space-between"}
            {...rest}
          >
            <Flex
              overflow="visible"
              as="header"
              alignItems="center"
              flex="1"
              gap={{ base: "0.5rem", xl: "1.2rem" }}
              pr={{ base: 0, xl: "0.8rem" }}
            >
              {isShowLogo && <IconHome onClick={navigateToHome} />}

              {isShowTitle && (
                <Box
                  flex={{
                    base: isPageForgeViewer ? "0 0 5rem" : "0 0 auto",
                    xl: "0 0 10rem",
                  }}
                >
                  <TitleHeader />
                </Box>
              )}

              {isShowSetting && (
                <Box
                  mr={{
                    base: 0,
                    lg:
                      isShowClearDatabaseCached || isLargeScreenForgeViewer
                        ? "0px"
                        : "2.8rem",
                  }}
                >
                  <HomeHeaderSetting />
                </Box>
              )}

              {isShowClearDatabaseCached && <ClearDatabaseCachedIcon />}

              {isShowHomeIcon && (
                <Flex
                  className="title-wrapper"
                  mr="auto !important"
                  w={{ base: "100%", lg: "auto" }}
                  alignItems="center"
                  minH="var(--header-height)"
                  m="0px !important"
                >
                  <SvgIcon
                    src="/img/icon-home-outline.svg"
                    width="24px"
                    height="24px"
                    cursor="pointer"
                    mr="1rem"
                    onClick={() => {
                      navigate(routePath.Home);
                      handleCloseUserDashboardModal();
                      dispatch(setDataProjectDetail({} as DataProjectModel));
                      dispatch(setIsCreateProject(false));
                    }}
                  />
                  <Flex
                    alignItems="center"
                    fontSize="1.8rem"
                    fontWeight="bold"
                    color="#000000"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    gap="1rem"
                  >
                    {renderHeaderTilte()}
                  </Flex>
                </Flex>
              )}

              {isShowDashBoardTitle && (
                <Flex alignItems="center" columnGap="1rem">
                  <TitleHeader />

                  {pathPattern === routePath.ProjectDashboard && (
                    <>
                      {projectDetail?.name && (
                        <SvgIcon src="/img/icon-navigation-chevron_right.svg" />
                      )}
                      <Text fontSize="16px" fontWeight="bold">
                        {projectDetail?.name || ""}
                      </Text>

                      <Button
                        variant="outline"
                        h="4rem"
                        {...buttonHeaderItemStyle}
                        onClick={navigateToForgeViewPage}
                      >
                        BIM画面
                      </Button>
                    </>
                  )}
                </Flex>
              )}

              {isShowForgeItemNew && (
                <ForgeHeaderItem
                  isLargeScreenForgeViewer={isLargeScreenForgeViewer}
                  levelSelectedLabel={levelSelectedLabel}
                />
              )}
            </Flex>

            <Flex
              gap={isLargeScreenForgeViewer ? "0.2rem" : "0.8rem"}
              alignItems="center"
              sx={{ gap: 0 }}
              width={"auto"}
            >
              {uploadOfflineDataStatus}
              {isShowSyncDataForOfflineIcon && dataProjectDetail && (
                <SyncDataForOfflineIcon
                  isCaching={isCaching}
                  progress={progress}
                  isOnline={isOnline}
                  cachingBimFileId={cachingBimFileId || ""}
                  isPageForgeViewer={isPageForgeViewer}
                  actualNetworkStatus={actualNetworkStatus}
                  syncDataTime={syncDataTime}
                  bimfileId={dataProjectDetail.id}
                  stopCacheProject={stopCacheProject}
                  syncNewProjectData={handleBeforeSyncProjectData}
                />
              )}

              {/* <PermissionToggle feature="canAccessProjectDashboard">

            {isShowProjectDashboardButton && (
              <Button
                onClick={navigateToProjectDashboard}
                h="4.4rem"
                variant="outline"
                _hover={{ bg: "#F0F9FF" }}
                {...buttonHeaderItemStyle}
                isDisabled={!isOnline}
              >
                プロジェクトのダッシュボード
              </Button>
            )}
          </PermissionToggle> */}

              {/* <PermissionToggle feature="canAccessUserDashboard">
            {isShowUserDashBoardButton && (
              <Button
                onClick={navigateToUserDashboard}
                h="4.4rem"
                variant="outline"
                _hover={{ bg: "#F0F9FF" }}
                {...buttonHeaderItemStyle}
                isDisabled={!isOnline}
              >
                ユーザーのダッシュボード
              </Button>
            )}
          </PermissionToggle> */}

              {/* {isShowTabletMenu && (
            <TabletMenu projectId={params?.projectId || ""} />

          )} */}

              <NetworkStatusIcon
                isOnline={isOnline}
                isCacheFinished={
                  progress === undefined ? undefined : progress >= 100
                }
                isLoadingViewer={isShowForgeItemNew && !isLoadedViewerModelData}
                cachingBimFileId={cachingBimFileId || ""}
                actualNetworkStatus={actualNetworkStatus}
                timeBeginOffline={timeBeginOffline}
                isPageForgeViewer={isShowForgeItemNew}
              />
              {isShowProfileMenu && (
                <ProfileMenu
                  width={isLargeScreenForgeViewer ? "2.4rem" : "3.6rem"}
                  marginRight={isLargeScreenForgeViewer ? "1.2rem" : "0px"}
                  height={isLargeScreenForgeViewer ? "2.4rem" : "3.6rem"}
                />
              )}
            </Flex>
          </Box>
        </Box>
      </Box>
      {isShowForgeItemNew && (
        <Box
          id="neptune-switch-mode-header"
          alignItems="center"
          p="0"
          bgColor={"#FAFAFA"}
          h="var(--sub-header-height)"
          borderBottom="2px solid #D4D4D4"
        >
          <SystemModeSwitch
            isForgePage={isPageForgeViewer}
            isMobile={isMobile}
            projectId={params?.projectId}
          />
        </Box>
      )}
    </>
  );
};

export default Header;
