import {
  Box,
  Button,
  Checkbox,
  Flex,
  FlexProps,
  IconButton,
  Link,
  Spacer,
  Spinner,
  Text,
  useBoolean,
} from "@chakra-ui/react";
import {
  BoldButton,
  ItalicButton,
  UnderlineButton,
  UnorderedListButton,
} from "@draft-js-plugins/buttons";
import Editor from "@draft-js-plugins/editor";
import createImagePlugin from "@draft-js-plugins/image";
import createMentionPlugin, { MentionData } from "@draft-js-plugins/mention";
import "@draft-js-plugins/mention/lib/plugin.css";
import createToolbarPlugin from "@draft-js-plugins/static-toolbar";
import "@draft-js-plugins/static-toolbar/lib/plugin.css";
import FileUpload from "components/FileUpload";
import FormUploadFile from "components/FormUploadFile";
import CameraModal from "components/modal/CameraModal";
import DrawToolModal, {
  HandleSelectDrawImageProps,
} from "components/modal/DrawToolModal";
import { InspectionItemType } from "constants/enum";
import { LIMIT_FILE_SIZE, TypeOfFile } from "constants/file";
import { EditorBlock, EditorState, Modifier } from "draft-js";
import { useDrawTool } from "hooks/useDrawTool";
import { useRoles } from "hooks/usePermission";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { UserDTO } from "interfaces/dtos/UserDTO";
import { FileModel } from "interfaces/models";
import { TaskComment, TaskLogDTO } from "interfaces/models/task";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { fileToBase64, verifyFile } from "utils/file";
import ModalMentionSuggestions from "./ModalMentionSuggestions";
import ModalPreviewImage from "./ModalPreviewImage";
import RecordAudio from "./record-audio/RecordAudio";
import RecordCommentList from "./RecordCommentList";
import Slider from "./Slider";
import { IconBase, message } from "components/base";
import AvatarUser from "components/AvatarUser";

interface Props {
  comments: TaskComment[];
  mapPartnerCompany: { [key: string]: string };
  listAssignedUserById: { [key: string]: UserDTO | null };
  listAllUserById: { [key: string]: UserDTO | null };
  containerRef: any;
  inputRef: any;
  loading: boolean;
  loadingImage: boolean;
  disabledChat: boolean;
  editorState: any;
  suggestions: MentionData[];
  styleInputBlock?: React.CSSProperties;
  windowWidth?: number;
  isOnline: boolean;
  status?: any;
  taskSelected: TaskDTO | undefined;
  isCurrentTask?: boolean;
  mapTaskType: Map<string, string>;
  setEditorState: (value: any) => void;
  onKeyUp: (e: any) => void;
  onSearchChange: (e: any) => void;
  handleKeyCommand: (e: any) => Promise<void>;
  setTempInfo?: (e: any) => void;
  onDeleteImage: (comment: TaskComment, srcImage: string) => Promise<void>;
  onUpdateImageComment: (
    e: any,
    comment: TaskComment,
    srcImage: string
  ) => Promise<void>;
  onLoadedAllFile?: () => void;
  onClickAddMessage: (file: FileModel[], statusChange: string) => Promise<void>;
  onClickBtnSelectFile: (event: React.MouseEvent<HTMLInputElement>) => void;
  onRevertDataByLog: (log: TaskLogDTO) => void;
}

const IconDoc = "/img/icon-content-note.svg";

export default function Comment({
  comments,
  listAssignedUserById,
  listAllUserById,
  containerRef,
  inputRef,
  loading,
  loadingImage,
  disabledChat,
  editorState,
  suggestions,
  status,
  styleInputBlock,
  windowWidth,
  isOnline,
  taskSelected,
  isCurrentTask = true,
  mapTaskType,
  mapPartnerCompany,
  onSearchChange,
  setEditorState,
  onKeyUp,
  handleKeyCommand,
  setTempInfo,
  onDeleteImage,
  onUpdateImageComment,
  onClickAddMessage,
  onClickBtnSelectFile,
  onRevertDataByLog,
  ...rest
}: Props & Partial<FlexProps>) {
  const [open, setOpen] = useState(false);
  const [isCheckTreated, setIsCheckTreated] = useState<boolean>(false);
  const [isCheckConfirmed, setIsCheckConfirmed] = useState<boolean>(false);
  const { currentUser } = useSelector((state: RootState) => state.user);
  const [listFileSelected, setListFileSelected] = useState<FileModel[]>([]);
  const [modalImage, setModalImage] = useState<{
    isOpen?: boolean;
    srcImage?: string;
  }>({});
  const totalSizeRef = useRef<number>(0);

  const { isOpenDraw, setOpenDraw, imgDrawFile } = useDrawTool();
  const [isOpenCameraModal, setOpenCameraModal] = useBoolean();

  const [heightBoxInputComment, setHeightBoxInputComment] = useState(0);

  const inputBoxRef = useRef<HTMLElement | null>(null);
  const { isTakasagoGroup } = useRoles();

  const { MentionSuggestions, Toolbar, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      supportWhitespace: true,
      mentionPrefix: "@",
    });
    const { MentionSuggestions } = mentionPlugin;
    const staticToolbarPlugin = createToolbarPlugin();
    const { Toolbar } = staticToolbarPlugin;
    const imagePlugin = createImagePlugin();
    const addImage = imagePlugin.addImage;
    const plugins = [mentionPlugin, staticToolbarPlugin, imagePlugin];

    return { plugins, MentionSuggestions, Toolbar, addImage };
  }, []);

  useEffect(() => {
    updateHeightInputBoxComment();
  }, [windowWidth]);

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);

  const triggerMention = () => {
    const newContentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      "@"
    );
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "insert-fragment"
    );

    setEditorState(
      EditorState.forceSelection(
        newEditorState,
        newContentState.getSelectionAfter()
      )
    );
  };

  const blockRenderer = (contentBlock: any) => {
    const type = contentBlock.getType();
    if (type === "atomic") {
      return {
        component: Component,
        editable: true,
        props: {
          foo: "bar",
        },
      };
    }
  };

  const Component = (props: any) => {
    return (
      <Link color="blue">
        <EditorBlock {...props} />
      </Link>
    );
  };

  const onClickComment = () => {
    const statusCheck: string = isCheckTreated
      ? InspectionItemType.Treated
      : isCheckConfirmed
      ? InspectionItemType.Confirmed
      : "";
    if ((isCheckConfirmed || isCheckTreated) && setTempInfo) {
      setIsCheckConfirmed(false);
      setIsCheckTreated(false);
    }

    onClickAddMessage(listFileSelected, statusCheck);
  };

  const handleFileSelected = async (fileList: FileList, type: TypeOfFile) => {
    const newListFileSelected = [...listFileSelected];

    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      const exactlyType = file.type.includes("image") ? TypeOfFile.IMAGE : type;
      let objectFile = {};

      totalSizeRef.current = totalSizeRef.current + file.size;

      if (totalSizeRef.current >= LIMIT_FILE_SIZE) {
        message.error("ファイルサイズの合計が最大値の100MBを超えます。");

        totalSizeRef.current = totalSizeRef.current - file.size;
      } else {
        const imgString =
          exactlyType === TypeOfFile.IMAGE ? await fileToBase64(file) : IconDoc;

        objectFile = {
          src: imgString,
          name: file.name,
          file,
          type,
        };

        newListFileSelected.push(objectFile);
      }
    }

    setListFileSelected(newListFileSelected);

    return;
  };

  const onChangeFile = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: TypeOfFile
  ) => {
    const target = e.target as any;
    const fileList = target.files as FileList;
    if (!fileList.length) return;
    handleFileSelected(fileList as any, type);
  };

  const onDeleteFileSlider = (index: number, src: string) => {
    const newsImages = [...listFileSelected];
    const sizeFile = newsImages[index].file?.size || 0;
    newsImages.splice(index, 1);
    setListFileSelected(newsImages);
    totalSizeRef.current = totalSizeRef.current - sizeFile;
  };
  const onPreviewImage = useCallback((src: string) => {
    setModalImage({ isOpen: true, srcImage: src });
  }, []);
  const onUpdateFileSlider = async (e: any, index: number, type: number) => {
    const newsImages = [...listFileSelected];
    const fileList = e.target.files as FileList;
    const file = fileList[0];

    const errorTitle = await verifyFile(fileList, TypeOfFile.ATTACH);
    if (errorTitle) {
      return message.error(errorTitle);
    }
    const imageSrc =
      type === TypeOfFile.IMAGE ? await fileToBase64(file) : IconDoc;
    const objectFile = {
      src: imageSrc,
      name: file.name,
      file,
      type,
    };
    newsImages.splice(index, 1, objectFile);
    setListFileSelected(newsImages);
  };

  useEffect(() => {
    setIsCheckTreated(false);
    setIsCheckConfirmed(false);
  }, [comments]);

  const onChangeInputBoxRef = (e: HTMLElement | null) => {
    inputBoxRef.current = e;
    updateHeightInputBoxComment();
  };

  const updateHeightInputBoxComment = () => {
    if (inputBoxRef.current) {
      setHeightBoxInputComment(inputBoxRef.current.offsetHeight);
    }
  };

  useEffect(() => {
    if (!disabledChat || !isCurrentTask) {
      setListFileSelected([]);
      totalSizeRef.current = 0;
    }
  }, [disabledChat, taskSelected?.id, isCurrentTask]);

  return (
    <>
      <Box
        id="container-chat-inspection-item"
        ref={containerRef}
        background="#ededee"
        mt="1.8rem"
        mb={`${
          heightBoxInputComment
            ? `calc(${heightBoxInputComment}px - 0.5rem + 2px)`
            : "18rem"
        }`}
      >
        {loading ? (
          <Flex
            height="4rem"
            bg="#E5E5E5"
            justifyContent="center"
            alignItems="center"
          >
            <Spinner size="xl" color="blue.500" />
          </Flex>
        ) : (
          <RecordCommentList
            isOnline={isOnline}
            comments={comments}
            loadingImage={loadingImage}
            status={status}
            listAssignedUserById={listAssignedUserById}
            listAllUserById={listAllUserById}
            mapTaskType={mapTaskType}
            mapPartnerCompany={mapPartnerCompany}
            taskCreatedAt={taskSelected?.createdAt as any}
            taskCreatedBy={taskSelected?.userCreated}
            currentUser={currentUser}
            onUpdateImageComment={onUpdateImageComment}
            onDeleteImage={onDeleteImage}
            onPreviewImage={onPreviewImage}
            onRevertDataByLog={onRevertDataByLog}
          />
        )}
      </Box>
      <Flex
        background="#E5E5E5"
        py="1rem"
        px="1.5rem"
        position="fixed"
        bottom="0"
        zIndex={100}
        width="calc(100% - 9px)"
        borderTop="1px solid #A3A3A3"
        style={styleInputBlock}
        {...rest}
        ref={onChangeInputBoxRef}
      >
        <Box mr="12px" minW="2.5rem">
          <AvatarUser
            w="2.5rem"
            h="2.5rem"
            borderRadius="full"
            objectFit="cover"
            src={currentUser?.avatar}
            mr="12px"
          />
        </Box>
        <Box
          width="calc(100% - 3.5rem)"
          maxW={{
            md: "245px",
            lg: "unset",
          }}
          borderRadius="4px"
        >
          <Box
            bgColor="#FFFFFF"
            borderTopLeftRadius="4px"
            alignItems="center"
            maxH={{
              md: "150px",
              xl: "110px",
            }}
          >
            <Flex
              className="editcomment"
              flex={1}
              fontSize="1.4rem"
              height={{ base: "3rem", lg: "5rem" }}
              border="none"
              maxW="45.6rem"
              borderBottomLeftRadius="none"
              borderBottomRightRadius="none"
              boxSizing="border-box"
              cursor="text"
              py="16px"
              borderRadius="2px"
              background="#fefefe"
              onClick={() => {
                inputRef?.current.focus();
              }}
              overflowY="auto"
              mb="4px"
            >
              <Editor
                editorKey={"editor"}
                editorState={editorState}
                onChange={setEditorState}
                placeholder="コメントや画像を投稿 "
                plugins={plugins}
                handleKeyCommand={handleKeyCommand}
                ref={inputRef}
                blockRendererFn={blockRenderer}
                keyBindingFn={onKeyUp}
              />
            </Flex>
            <Flex
              alignItems={{
                md: "center",
                xl: "flex-end",
              }}
              className="slider-custom"
              columnGap={{
                md: "unset",
                lg: "2px",
              }}
              rowGap={{
                md: "2px",
                xl: "unset",
              }}
              flex={1}
              pb="4px"
              flexDir={{
                md: "column",
                xl: "row",
              }}
            >
              <Flex
                justifyContent="center"
                px="4px"
                width={{
                  md: "80%",
                  xl: "40%",
                }}
                minW={{
                  md: "80%",
                  xl: "40%",
                }}
                maxW={{
                  md: "228px",
                  xl: "unset",
                }}
                ml={
                  listFileSelected?.length > 3
                    ? {
                        xl: "20px",
                        xxl: "30px",
                      }
                    : {}
                }
              >
                <Slider
                  listImageSelected={listFileSelected || []}
                  onDeleteImage={onDeleteFileSlider}
                  onPreviewImage={onPreviewImage}
                  onUpdateFileSlider={onUpdateFileSlider}
                  mb="0"
                  customDeleteIconProps={{
                    bottom: "3px",
                    right: "2px",
                    "aria-label": "delete",
                  }}
                  playIconProps={{
                    minW: {
                      md: "3rem",
                      lg: "unset",
                    },
                  }}
                />
              </Flex>
              <Spacer />
              <Box
                px="4px"
                mb="4px"
                textAlign="right"
                w="100%"
                display="flex"
                justifyContent={{ base: "flex-end" }}
                alignItems={{ base: "center" }}
              >
                {status !== InspectionItemType.Treated &&
                  status !== InspectionItemType.Confirmed && (
                    <>
                      <Checkbox
                        defaultChecked
                        as="b"
                        isChecked={isCheckTreated === true}
                        onChange={(e) => {
                          setIsCheckTreated(!isCheckTreated);
                        }}
                      >
                        <Text ml="0" as="b" color="#9766A7">
                          処置済
                        </Text>
                        にする
                      </Checkbox>
                    </>
                  )}
                {status === InspectionItemType.Treated && isTakasagoGroup && (
                  <>
                    <Checkbox
                      defaultChecked
                      as="b"
                      isChecked={isCheckConfirmed}
                      borderColor="#A3A3A3"
                      onChange={(e) => {
                        setIsCheckConfirmed(!isCheckConfirmed);
                      }}
                    >
                      <Text as="b" color="#0284C7">
                        確認済
                      </Text>
                      にする
                    </Checkbox>
                  </>
                )}
                <Button
                  ml="0.8rem"
                  variant="filled"
                  isLoading={disabledChat && isCurrentTask}
                  disabled={disabledChat && isCurrentTask}
                  onClick={onClickComment}
                >
                  投稿
                </Button>
              </Box>
            </Flex>
          </Box>
          <Flex
            h={{ base: "40px", md: "32px", lg: "40px" }}
            py={{ base: 0, lg: "0.5rem" }}
            pl={{ base: "14px", md: "7px", lg: "14px" }}
            pr={{ base: "12px", md: "6px", lg: "12px" }}
            borderRadius="4px"
            borderTop="none"
          >
            <Flex alignItems="center">
              <div className="toolbar toolbarComment">
                <Toolbar>
                  {(externalProps) => (
                    <>
                      <BoldButton {...externalProps} />
                      <ItalicButton {...externalProps} />
                      <UnderlineButton {...externalProps} />
                      <UnorderedListButton {...externalProps} />
                    </>
                  )}
                </Toolbar>
              </div>
              <MentionSuggestions
                open={open}
                onOpenChange={onOpenChange}
                suggestions={suggestions}
                onSearchChange={onSearchChange}
                onAddMention={() => {}}
                entryComponent={ModalMentionSuggestions}
              />
              <Box alignSelf="center">
                <IconButton
                  color="icon.default"
                  variant="text"
                  onClick={triggerMention}
                  icon={<IconBase icon="/img/icon-tag.svg" />}
                  aria-label="tags"
                />
              </Box>
            </Flex>
            <Spacer />
            <Flex
              height="100%"
              borderTopRightRadius="4px"
              alignItems="center"
              flexDir="row"
            >
              <RecordAudio
                onResult={(res) => {
                  const audioName = `record_audio_${Date.now()}.mp3`;
                  const objectFile = {
                    src: res.blobURL,
                    name: audioName,
                    type: TypeOfFile.AUDIO,
                    file: new File([res.blob], audioName),
                  };
                  setListFileSelected((s) => [...s, objectFile]);
                }}
              />
              <FormUploadFile
                onClick={onClickBtnSelectFile}
                onChange={onChangeFile}
                type={TypeOfFile.ATTACH}
                className="button"
              >
                <FileUpload accept="image/*, .pdf, .mp4" multiple={true}>
                  <IconButton
                    mr="4px"
                    color="icon.default"
                    variant="text"
                    icon={<IconBase icon="/img/icon-zip-black.svg" />}
                    aria-label="images"
                  />
                </FileUpload>
              </FormUploadFile>
              <IconButton
                mr="4px"
                color="icon.default"
                variant="text"
                icon={<IconBase icon="/img/icon-camera-gray.svg" />}
                aria-label="camera"
                onClick={setOpenCameraModal.on}
              />
              {isOpenCameraModal && (
                <CameraModal
                  isOpen={isOpenCameraModal}
                  onClose={setOpenCameraModal.off}
                  onCapture={(file) => {
                    imgDrawFile.current = file;
                    setOpenDraw.on();
                  }}
                />
              )}
              {isOpenDraw && (
                <DrawToolModal
                  isOpen={isOpenDraw}
                  onSelect={({ file }: HandleSelectDrawImageProps) => {
                    const dT = new DataTransfer();
                    dT.items.add(file);
                    handleFileSelected(dT.files, TypeOfFile.IMAGE);
                  }}
                  onClose={setOpenDraw.off}
                  fileRef={imgDrawFile}
                  reCapture={() => {
                    setOpenDraw.off();
                    setOpenCameraModal.on();
                  }}
                />
              )}
            </Flex>
          </Flex>
        </Box>

        <ModalPreviewImage
          isOpen={modalImage.isOpen ?? false}
          srcImage={modalImage.srcImage}
          onClose={() => setModalImage({})}
        />
      </Flex>
    </>
  );
}
