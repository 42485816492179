import {
  Badge,
  Box,
  BoxProps,
  Button,
  Center,
  Flex,
  Portal,
  Spinner,
  Text,
} from "@chakra-ui/react";
import IconDocument from "components/icon/IconDocument";
import IconFolder from "components/icon/IconFolder";
import { DocumentCategoryModelHandleType } from "components/modal/DocumentCategoryModal";
import { DocumentItemModalHandleType } from "components/modal/DocumentItemModal";
import FamilyEditModal from "components/modal/FamilyEditModal";
import ModalAddDocumentCategory from "components/modal/ModalAddDocumentCategory";
import ModalAddDocumentGroup from "components/modal/ModalAddDocumentGroup";
import ModalConfirmDelete from "components/modal/ModalConfirmDelete";
import OfflineModeTutorialModal from "components/modal/OfflineModeTutorialModal";
import { TaskModalHandleType } from "components/modal/TaskModal";
import SearchBar from "components/SearchBar";
import { SvgIcon } from "components/SvgIcon";
import TooltipForgeView from "components/TooltipForgeView";
import CollapseModalIcon from "components/ui/CollapseModalIcon";
import TaskSheet from "components/widgets/TaskSheet";
import { DEFAULT_DOCUMENT_FILTER_DATA } from "constants/document";
import { SystemModeType } from "constants/enum";
import {
  DISABLE_LEFT_SIDEBAR_ID,
  LEFT_PANEL_CONTENT_ID,
} from "constants/styleProps";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { NeptuneArea } from "interfaces/models/area";
import { iDocumentDataProps } from "interfaces/models/document";
import { DocumentCategory } from "interfaces/models/documentCategory";
import { DocumentGroup } from "interfaces/models/documentGroup";
import { DocumentTask } from "interfaces/models/documentTask";
import { DocumentTemplate } from "interfaces/models/documentTemplate";
import { PartnerCompany } from "interfaces/models/partnerCompany";
import { UserSetting } from "interfaces/models/user";
import { FilterDocumentCategoryByUserSettingProps } from "models/document";
import { isBlockDocumentCategory } from "models/documentCategory";
import React, { forwardRef, useCallback, useMemo } from "react";
import { setIsCreateDocumentItem } from "redux/forgeViewerSlice";
import { setUserSetting } from "redux/userSlice";
import { sortArrayByField } from "utils/array";
import { DEFAULT_FORMAT_DATE_DISPLAY, formatDate } from "utils/date";
import { ClickInfo } from "utils/forge/extensions/click-extension";
import { getLabelExtension } from "utils/forge/extensions/custom-label";
import DocumentCategoryDropDown from "./document/DocumentCategoryDropDown";
import DocumentGroupDropDown from "./document/DocumentGroupDropDown";
import AddDocumentMenu from "./document/menu/AddDocumentMenu";
import FilterBoxDocument from "./FilterBoxDocument";
import FilterBoxTask from "./FilterBoxTask";
import { useLeftPanel } from "./hooks";
import TaskGroupDropDown from "./TaskGroupDropDown";

type GroupTask = {
  title: string;
  tasks: TaskDTO[];
};

export interface Props {
  filterDocumentCategoryOptions: FilterDocumentCategoryByUserSettingProps;
  clickInfo?: ClickInfo;
  forgeViewContainerRef: React.RefObject<HTMLDivElement>;
  isLoadedDocumentTasks: boolean;
  isLoadedSheetTransformRatio: boolean;
  documentTasksByLevel?: DocumentTask[];
  bimFileId?: string;
  taskData: TaskDTO[];
  documentTemplates: { [key: string]: DocumentTemplate };
  searchInputValue?: string;
  isCreateTask?: boolean;
  isLoadedLevels: boolean;
  settings: UserSetting;
  isLoadedViewer: boolean;
  mapTaskType: Map<string, string>;
  partnerCompanies: PartnerCompany[];
  documentItemModalRef: React.RefObject<DocumentItemModalHandleType>;
  documentCategoryModalRef: React.RefObject<DocumentCategoryModelHandleType>;
  taskModalRef: React.RefObject<TaskModalHandleType>;
  areas: NeptuneArea[];
  filterDocumentGroupAndCategories: {
    categories: DocumentCategoryDTO[];
    allCategories: DocumentCategoryDTO[];
    groups: DocumentGroup[];
  };
  isOnline: boolean;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  mapDocumentItemBlockIdsByTemplateId: { [templateId: string]: Set<string> };
  isFetchingPartnerCompanies: boolean;
  onSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickDocumentGroup?: (documentGroup: DocumentGroup) => void;
  onOpenDocLevel?: () => void;
  handleClickDocumentCategory: (documentCategory: DocumentCategoryDTO) => void;
  handleClickDocumentItem: (data: iDocumentDataProps) => void;
  handleChangeSheet: (id: string, isSaveData?: boolean) => Promise<void>;
  setClickedLabelInfo?: (data: any | undefined) => void;
  handleUpdateClickForgeInfo: (info?: ClickInfo) => void;
}

export const MAX_WIDTH_IPAD = 1300;
export const TOGGLE_BUTTON_ID = "toggle-button";
export const LEFT_PANEL_NOT_SEARCH = "left-panel-content_not_search";

export type LeftPanelHandleType = {
  handleCollapse: () => void;
};

const LeftPanel = forwardRef<LeftPanelHandleType, Props & BoxProps>(
  (props, ref) => {
    const { states, methods } = useLeftPanel(props, ref);
    const {
      dataProjectDetail,
      isOpenOfflineTutorial,
      parentRef,
      searchBarRef,
      filterTaskBoxRef,
      filterDocumentBoxRef,
      forgeViewContainerRef,
      //boolean
      isLoading,
      isLoadingTask,
      isCreateDocumentItem,
      isCollapsed,
      isFilter,
      isOpenFilter,
      isTakasagoGroup,
      isCreatingNewTask,
      loadingExportSheetTask,
      isOpenTaskSheet,
      isShowEditingFamily,
      isLoadingAddDocumentTemplate,
      isOpenAddDocumentTemplateModal,
      isDeleteDocumentProcessing,
      openModalDeleteDocument,
      isOpenModalAddDocumentGroup,
      openModalDeleteDocumentGroup,
      isAddingDocumentGroup,
      isProcessing,
      isOnline,
      //@end boolean
      documentItems,
      qrCodeImage,
      neptuneAreas,
      systemMode,
      widthPanel,
      searchInputValue,
      listTaskToDisplay,
      countItemDisplay,
      shouldShowExportTaskSheet,
      isDisableCaptureKeyplan,
      documentCategories,
      isLoadingDocument,
      documentList,
      documentGroups,
      visibleDocumentGroupAndDocumentCategories,
      documentCategorySelected,
      documentItemSelected,
      documentGroupSelected,
      debounceSearch,
      mapDocumentItemBlockIdsByTemplateId,
      taskSelected,
      tasks,
      levelSelected,
      documentTasksByLevel,
      settings,
      listUserById,
      partnerCompanies,
      areas,
      documentTask,
      listTaskFromS3,
      currentUser,
      documentGroupList,
      modalAddDocumentDefaultValue,
      levels,
      documentTemplates,
      selectedDocument,
      selectedDocumentGroup,
      rest,
      isFetchingPartnerCompanies,
      isGeneratingPdf,
    } = states;

    const {
      handleOpenOfflineTutorialModal,
      handleCloseOfflineTutorialModal,
      setSelectedDocumentGroup,
      setOpenModalDeleteDocumentGroup,
      handleAddDocumentGroup,
      handleCloseAddDocumentGroup,
      handleDeleteDocumentGroup,
      setSelectedDocument,
      setOpenModalDeleteDocument,
      handleDeleteDocument,
      onCloseAddDocumentTemplateModal,
      handleAddDocumentCategory,
      setClickedLabelInfo,
      onChangeTaskSheetTemplate,
      onUpdateDocumentTask,
      onGotoForgeViewPage,
      onCloseTaskSheet,
      onChangeKeyplanRatio,
      onChangeKeyplanSheet,
      onOpenAddDocumentTemplateModal,
      handleDisplayTaskSheet,
      dispatch,
      onOpenModalAddDocumentGroup,
      collapseLeftBar,
      handleClickDocumentItem,
      onClickAddDocumentItem,
      onSelectDeleteDocumentGroup,
      onSelectUpdateMenuItem,
      onSelectDeleteDocument,
      onSelectCategoryUpdate,
      handleClickDocumentCategory,
      handleClickDocumentGroup,
      focusCurrentDocCategory,
      goToPageDocumentTaskList,
      handleToggleFilterBox,
      onFilterDocument,
      clearFilterData,
      onHandleCloseAddDocumentItem,
      onToggleVisibleDocumentItem,
      onDeletedDocumentItem,
    } = methods;

    const documentElements = useMemo(() => {
      if (
        !documentCategories.length &&
        !isLoadingDocument &&
        !documentList.length &&
        !documentGroups?.length
      ) {
        return (
          <Box borderTop="1px solid #E2E2E3" pt="1rem">
            <Text fontWeight="500" color={"#e01616"}>
              書類が登録されていません。
            </Text>
          </Box>
        );
      }
      const { visibleUnGroupDocumentCategories, mapDocumentCategoryByGroup } =
        visibleDocumentGroupAndDocumentCategories;
      if (
        !visibleUnGroupDocumentCategories?.length &&
        !documentGroups?.length &&
        !isLoadingDocument &&
        (documentList.length || documentGroups.length)
      ) {
        return (
          <Center
            h={{
              base: "100%",
              md: "calc(100% - 5rem)",
              lg: "calc(100% - 5rem)",
              xl: "calc(100% - 5rem)",
            }}
            pb="6rem"
          >
            <Text>結果なし</Text>
          </Center>
        );
      }

      const groupElements = documentGroups.map((doc, index) => {
        const categories: DocumentCategoryDTO[] =
          mapDocumentCategoryByGroup[doc.id];
        const selectedCategoryId = !!documentCategorySelected
          ? categories?.some((item) => item.id === documentCategorySelected?.id)
            ? documentCategorySelected?.id
            : undefined
          : undefined;

        // child element always document category selected
        const selectChildDocumentItemCategoryId = documentItemSelected
          ? documentItemSelected?.documentCategoryId === selectedCategoryId
            ? documentItemSelected?.documentCategoryId
            : undefined
          : undefined;

        return (
          <DocumentGroupDropDown
            key={index}
            currentUserId={currentUser?.id}
            documentGroup={doc}
            documentCategories={categories}
            isSelect={documentGroupSelected?.id === doc.id}
            selectChildDocumentCategoryId={selectedCategoryId}
            selectChildDocumentItemCategoryId={
              selectChildDocumentItemCategoryId
            }
            selectChildDocumentItemId={
              selectChildDocumentItemCategoryId
                ? documentItemSelected?.id
                : undefined
            }
            isOnline={isOnline}
            parentRef={parentRef}
            isCreateDocumentItem={isCreateDocumentItem && !!selectedCategoryId}
            mapDocumentItemBlockIdsByTemplateId={
              mapDocumentItemBlockIdsByTemplateId
            }
            handleClickDocumentGroup={handleClickDocumentGroup}
            handleClickDocumentCategory={handleClickDocumentCategory}
            handleClickDocumentItem={handleClickDocumentItem}
            onClickAddDocumentItem={onClickAddDocumentItem}
            onSelectDeleteDocumentGroup={onSelectDeleteDocumentGroup}
            onSelectUpdateMenuItem={onSelectUpdateMenuItem}
            onSelectCategoryDelete={onSelectDeleteDocument}
            onSelectCategoryUpdate={onSelectCategoryUpdate}
            onToggleVisibleDocumentItem={onToggleVisibleDocumentItem}
            onDeletedDocumentItem={onDeletedDocumentItem}
            onOpenOfflineModeTutorialModal={handleOpenOfflineTutorialModal}
          />
        );
      });

      const categoryOutSideElements = visibleUnGroupDocumentCategories.map(
        (doc: DocumentCategory) => {
          return (
            <DocumentCategoryDropDown
              key={doc.id}
              currentUserId={currentUser?.id}
              isOnline={isOnline}
              documentCategory={doc}
              parentRef={parentRef}
              isFromDocumentGroup={false}
              isSelect={doc.id === documentCategorySelected?.id}
              selectChildId={
                doc.id === documentItemSelected?.documentCategoryId
                  ? documentItemSelected.id
                  : undefined
              }
              boxShadow="0px 1px 2px 0px #0000004D"
              isCreateDocumentItem={
                isCreateDocumentItem && doc.id === documentCategorySelected?.id
              }
              setDocumentItemBlockIds={
                mapDocumentItemBlockIdsByTemplateId[doc.templateId]
              }
              isBlockDocumentCategory={isBlockDocumentCategory(doc.blockedBy)}
              handleClickDocumentItem={handleClickDocumentItem}
              onClickAddDocumentItem={onClickAddDocumentItem}
              handleClickDocumentCategory={handleClickDocumentCategory}
              onSelectDelete={onSelectDeleteDocument}
              onSelectUpdate={onSelectCategoryUpdate}
              onToggleVisibleDocumentItem={onToggleVisibleDocumentItem}
              onDeletedDocumentItem={onDeletedDocumentItem}
              onOpenOfflineModeTutorialModal={handleOpenOfflineTutorialModal}
            />
          );
        }
      );

      return (
        <>
          {groupElements}
          {categoryOutSideElements}
        </>
      );
    }, [
      isOnline,
      currentUser?.id,
      parentRef,
      documentCategories,
      isLoadingDocument,
      documentList?.length,
      visibleDocumentGroupAndDocumentCategories,
      documentItemSelected,
      documentCategorySelected,
      documentGroupSelected,
      isCreateDocumentItem,
      documentGroups,
      mapDocumentItemBlockIdsByTemplateId,
      onToggleVisibleDocumentItem,
      onDeletedDocumentItem,
      handleClickDocumentGroup,
      handleClickDocumentCategory,
      handleClickDocumentItem,
      onClickAddDocumentItem,
      onSelectDeleteDocumentGroup,
      onSelectUpdateMenuItem,
      onSelectDeleteDocument,
      onSelectCategoryUpdate,
      handleOpenOfflineTutorialModal,
    ]);

    const taskElements = useMemo(() => {
      if (isLoadingTask) return [];

      if (!tasks.length && !isLoadingTask) {
        return (
          <Box borderTop="1px solid #E2E2E3" pt="1rem">
            <Text fontWeight="500" color={"#e01616"}>
              指摘が登録されていません。
            </Text>
          </Box>
        );
      }

      if (listTaskToDisplay?.length == 0 && !isLoadingTask) {
        return (
          <Center
            h={{
              base: "100%",
              md: "calc(100% - 5rem)",
              lg: "calc(100% - 5rem)",
              xl: "calc(100% - 5rem)",
            }}
            pb="6rem"
          >
            <Text>結果なし</Text>
          </Center>
        );
      }

      const taskNoDates: TaskDTO[] = [];
      const mapGroups: {
        [key: string]: GroupTask;
      } = {};
      listTaskToDisplay.forEach((item) => {
        if (!item.taskDate) {
          taskNoDates.push(item);

          return;
        }
        const title = formatDate(item.taskDate, DEFAULT_FORMAT_DATE_DISPLAY);
        if (!mapGroups[title]) {
          mapGroups[title] = {
            title,
            tasks: [],
          };
        }
        mapGroups[title].tasks.push(item);
      });
      const allGroups: GroupTask[] = sortArrayByField(
        Object.values(mapGroups),
        "title",
        false
      );
      if (taskNoDates.length) {
        allGroups.unshift({
          title: "日付なし",
          tasks: taskNoDates,
        });
      }

      return (
        <>
          {allGroups.map((group) => {
            const tasks = group.tasks;
            if (!tasks.length) {
              return undefined;
            }
            const containSelected =
              !!taskSelected &&
              tasks.some((item) => item.id === taskSelected.id);

            return (
              <TaskGroupDropDown
                key={group.title}
                tasks={tasks}
                id={group.title}
                selectTaskId={containSelected ? taskSelected.id : undefined}
                groupName={group.title}
                mapTaskType={props.mapTaskType}
                listUserById={listUserById}
                parentRef={parentRef}
              />
            );
          })}
        </>
      );
    }, [
      isLoadingTask,
      props.mapTaskType,
      listTaskToDisplay,
      listUserById,
      taskSelected,
      tasks.length,
      parentRef,
    ]);

    const onCloseModalDeleteDocumentGroup = useCallback(() => {
      if (isCreateDocumentItem) {
        dispatch(setIsCreateDocumentItem(false));
      }
      setSelectedDocumentGroup(undefined);
      setOpenModalDeleteDocumentGroup(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCreateDocumentItem]);

    const onCloseModalDeleteDocument = useCallback(() => {
      if (isCreateDocumentItem) {
        dispatch(setIsCreateDocumentItem(false));
      }
      setSelectedDocument(undefined);
      setOpenModalDeleteDocument(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCreateDocumentItem]);

    return (
      <>
        <Box
          position="absolute"
          height="100%"
          zIndex={11}
          transition="width 200ms ease-in-out 0s"
          boxShadow="4px 0 4px -1px rgba(0, 0, 0, 0.15)"
        >
          <CollapseModalIcon
            left="100%"
            borderRadius="0px 5px 5px 0px"
            borderRightStyle="solid"
            borderLeftStyle="hidden"
            height="12rem"
            width="3.8rem"
            top="50%"
            iconProps={{
              transform: isCollapsed ? "rotate(-90deg)" : "rotate(90deg)",
            }}
            boxShadow="4px 4px 4px 0px rgba(0, 0, 0, 0.15)"
            transform="translateY(-50%)"
            onClose={collapseLeftBar}
          />

          <Box
            id={LEFT_PANEL_CONTENT_ID}
            className="forge-left-panel"
            w={isCollapsed ? "0" : widthPanel}
            h="calc(var(--app-height) - var(--header-height) - var(--sub-header-height))"
            transition="width 200ms ease-in-out 0s"
            position="relative"
          >
            <Box
              id={DISABLE_LEFT_SIDEBAR_ID}
              position="absolute"
              justifyContent="center"
              alignItems="center"
              width="100%"
              height="100%"
              bgColor="rgba(255,255,255,0.6)"
              sx={{
                zIndex: isGeneratingPdf ? "10 !important" : "0",
                display: isGeneratingPdf ? "flex !important" : "none",
              }}
            >
              <Spinner color="blue.500" size="lg" />
            </Box>

            <Flex h="100%" width="100%">
              <Box
                width="100%"
                left="0"
                top="0.5rem"
                zIndex="auto"
                overflowX="hidden"
                overflowY={{ base: "auto", md: "hidden" }}
                bgColor="#fff"
                transition="width 200ms ease-in-out 0s"
                {...rest}
              >
                {isLoading && (
                  <Flex
                    alignItems="center"
                    justifyContent={"center"}
                    width="100%"
                    backgroundColor="#FAFAFB"
                    h="calc(var(--app-height) - var(--header-height) - var(--sub-header-height))"
                    className="loading"
                    position="absolute"
                    left="0"
                    top="0.5rem"
                    opacity="0.7"
                  >
                    {!isCollapsed && <Spinner color="blue.500" size="lg" />}
                  </Flex>
                )}
                <Flex
                  flexDir="column"
                  h="calc(var(--app-height) - var(--header-height) - var(--sub-header-height))"
                >
                  <Box w="100%" bgColor="#EEEEEE" p="1.2rem">
                    <SearchBar
                      ref={searchBarRef}
                      height="4rem"
                      iconDir="left"
                      width="100%"
                      defaultValue={searchInputValue || ""}
                      onSearch={debounceSearch}
                      containerProps={{ maxW: "100%" }}
                      placeholder="検索"
                      rightIcon={
                        isOpenFilter || isFilter ? (
                          <Flex w="auto" position="relative">
                            <Box
                              cursor="pointer"
                              pos="absolute"
                              bg="transparent"
                              height="100%"
                              width="100%"
                              top="0"
                              left="0"
                              id={TOGGLE_BUTTON_ID}
                              onClick={handleToggleFilterBox}
                            />

                            <SvgIcon
                              w="2.4rem"
                              src="/img/icon-settings.svg"
                              sx={{
                                path: {
                                  fill: "#3ca4ff",
                                },
                              }}
                            />

                            {isFilter && systemMode === SystemModeType.Task && (
                              <Text
                                w="auto"
                                fontSize="12px"
                                color="#3CA4FF"
                                marginLeft="2px"
                                alignSelf="center"
                              >
                                {`(${countItemDisplay})`}
                              </Text>
                            )}
                          </Flex>
                        ) : (
                          <SvgIcon
                            cursor="pointer"
                            w="2.4rem"
                            src="/img/icon-settings.svg"
                            onClick={handleToggleFilterBox}
                          />
                        )
                      }
                      rightIconProps={{
                        w: "auto",
                      }}
                      pr={`calc(4.5rem + ${
                        isFilter
                          ? 5 + (String(countItemDisplay || 0).length - 1) * 10
                          : 0
                      }px)`}
                      border="1px solid #A3A3A3 !important"
                    />
                  </Box>

                  {systemMode === SystemModeType.Task && (
                    <Box
                      id={`left-p ${LEFT_PANEL_NOT_SEARCH}`}
                      ref={parentRef as any}
                      overflowY="auto"
                      flexGrow="4"
                    >
                      {taskElements}
                    </Box>
                  )}

                  {systemMode === SystemModeType.Document && (
                    <Flex
                      id={LEFT_PANEL_NOT_SEARCH}
                      ref={parentRef as any}
                      overflowY="auto"
                      p=".8rem"
                      flexDirection="column"
                      gap=".4rem"
                      height="100%"
                      paddingBottom={"3.5rem"}
                    >
                      {documentElements}
                    </Flex>
                  )}

                  {systemMode === SystemModeType.Task && (
                    <Flex
                      id={LEFT_PANEL_NOT_SEARCH}
                      width="100%"
                      h="6rem"
                      justifyContent="space-between"
                      mt="0 !important"
                      px={{ base: "0.6rem", lg: "1.0rem", xl: "1rem" }}
                      py="0.8rem"
                      bgColor="#E5E5E5"
                      boxShadow="0px -1px 0px rgba(0, 0, 0, 0.25)"
                      borderTop="1px solid var(--primary-border-color)"
                    >
                      <Button
                        onClick={goToPageDocumentTaskList}
                        isDisabled={dataProjectDetail?.isDiffVersion}
                        color="#009BE0"
                        borderRadius={6}
                      >
                        是正指示書
                      </Button>

                      {shouldShowExportTaskSheet && (
                        <Button
                          ml={{ base: "0.6rem", lg: "1rem" }}
                          variant="filled"
                          isLoading={loadingExportSheetTask}
                          isDisabled={
                            isDisableCaptureKeyplan ||
                            isCreatingNewTask ||
                            !levelSelected?.guid
                          }
                          onClick={handleDisplayTaskSheet}
                        >
                          検査結果から是正指示書作成
                        </Button>
                      )}
                    </Flex>
                  )}
                </Flex>
              </Box>

              {systemMode === SystemModeType.Document && isTakasagoGroup && (
                <AddDocumentMenu
                  isLoadingDocument={isLoadingDocument}
                  onOpenAddParentDocumentTemplateModal={
                    onOpenModalAddDocumentGroup
                  }
                  onOpenAddDocumentTemplateModal={
                    onOpenAddDocumentTemplateModal
                  }
                  position="absolute"
                  bottom=".8rem"
                  right=".8rem"
                  zIndex={10}
                />
              )}
            </Flex>

            {isOpenFilter && systemMode === SystemModeType.Task && (
              <Box
                position="absolute"
                h="auto"
                width={`calc(${widthPanel} - 10px)`}
                maxWidth="32rem"
                top="0"
                left={isCollapsed ? "0" : widthPanel}
                bottom="0"
                right="0"
                zIndex={100}
                boxShadow="4px 0 4px -1px rgba(0, 0, 0, 0.15)"
                bgColor="#00000055"
              >
                <Flex h="100%" w="100%" ref={filterTaskBoxRef}>
                  <FilterBoxTask
                    documentTasksByLevel={documentTasksByLevel}
                    setFilterData={(value) => {
                      dispatch(setUserSetting({ settings: value }));
                    }}
                    filterData={settings}
                    clearData={clearFilterData}
                    userList={Object.values(listUserById)}
                    partnerCompanies={partnerCompanies}
                    isFetchingPartnerCompanies={isFetchingPartnerCompanies}
                  />
                  <Box
                    className="filter-overlay"
                    flexGrow={4}
                    onClick={handleToggleFilterBox}
                  ></Box>
                </Flex>
              </Box>
            )}
            {isOpenFilter &&
              systemMode === SystemModeType.Document &&
              !isCollapsed && (
                <Box
                  position="absolute"
                  h="auto"
                  width={`calc(${widthPanel} - 10px)`}
                  maxWidth="32rem"
                  top="0"
                  left={isCollapsed ? "0" : widthPanel}
                  bottom="0"
                  right="0"
                  boxShadow="4px 0 4px -1px rgba(0, 0, 0, 0.15)"
                  zIndex={5}
                  bgColor="#00000055"
                >
                  <Flex h="100%" w="100%" ref={filterDocumentBoxRef}>
                    <FilterBoxDocument
                      setFilterData={onFilterDocument}
                      filterData={settings}
                      clearData={clearFilterData}
                      userList={Object.values(listUserById)}
                      areas={areas}
                      filterDataDefault={{
                        ...settings,
                        ...DEFAULT_DOCUMENT_FILTER_DATA,
                      }}
                    />
                    <Box
                      className="filter-overlay"
                      flexGrow={4}
                      onClick={handleToggleFilterBox}
                    ></Box>
                  </Flex>
                </Box>
              )}
          </Box>
        </Box>

        {isOpenTaskSheet && (
          <TaskSheet
            bimFileId={props.bimFileId || ""}
            documentTasks={documentTasksByLevel}
            documentTask={documentTask!}
            loading={loadingExportSheetTask}
            initTasks={documentTask?.id ? listTaskFromS3 : listTaskToDisplay}
            qrCodeImage={qrCodeImage}
            onGotoForgeViewPage={onGotoForgeViewPage}
            onClose={onCloseTaskSheet}
            onChangeRatio={onChangeKeyplanRatio}
            onChangeSheet={(value) => onChangeKeyplanSheet(value)}
            onUpdateDocumentTask={onUpdateDocumentTask}
            settings={settings}
            onChangeTaskSheetTemplate={onChangeTaskSheetTemplate}
          />
        )}

        {isOpenModalAddDocumentGroup && (
          <ModalAddDocumentGroup
            isOpen={isOpenModalAddDocumentGroup}
            isLoading={isAddingDocumentGroup}
            onClose={handleCloseAddDocumentGroup}
            onApply={handleAddDocumentGroup}
            defaultValue={selectedDocumentGroup}
          />
        )}

        {openModalDeleteDocumentGroup && (
          <ModalConfirmDelete
            isLoading={isProcessing}
            onClose={onCloseModalDeleteDocumentGroup}
            titleHeader="以下のフォルダを削除します。よろしいですか？"
            iconHeader={<IconFolder width="4rem" height="4rem" />}
            content={
              <>
                <Flex direction="row" alignItems="center" mb="3rem">
                  <Box flexGrow={"auto"}>
                    <IconFolder
                      color="#171717"
                      width="2.4rem"
                      height="2.4rem"
                    />
                  </Box>

                  <Text
                    ml="0.5rem"
                    color="#737373"
                    fontWeight="600"
                    fontSize="1.6rem"
                  >
                    {selectedDocumentGroup?.name}
                  </Text>
                </Flex>
                <Flex
                  fontSize="1.6rem"
                  fontWeight="700"
                  lineHeight="2.88rem"
                  direction={"column"}
                  color="#EF4444"
                >
                  {" "}
                  <Text>
                    注意！
                    フォルダに含まれる書類は削除されません。フォルダに含まれる書類がフォルダ外に移動されます。
                  </Text>
                  <Text>
                    書類を削除したい場合、書類の削除機能を使用してください。
                  </Text>
                </Flex>
              </>
            }
            onApply={handleDeleteDocumentGroup}
          />
        )}

        {openModalDeleteDocument && (
          <ModalConfirmDelete
            isLoading={isDeleteDocumentProcessing}
            onClose={onCloseModalDeleteDocument}
            titleHeader="以下の書類を削除します。よろしいですか？"
            iconHeader={
              <IconDocument fill="#171717" width="4rem" height="4rem" />
            }
            content={
              <>
                <Flex
                  direction="row"
                  alignItems="center"
                  mb="3rem"
                  gap={"0.5rem"}
                >
                  <Badge
                    color="#fff"
                    bgColor={"#737373"}
                    fontSize="1.4rem"
                    fontWeight={500}
                    p="1px 1rem 1px 1rem"
                    h="2rem"
                    my=".2rem"
                    mr="0.5rem"
                    flexShrink={"auto"}
                  >
                    {selectedDocument?.level}
                  </Badge>

                  <Box flexGrow={"auto"}>
                    <IconDocument
                      fill="#737373"
                      width="2.4rem"
                      height="2.4rem"
                    />
                  </Box>
                  <Text color="#737373" fontWeight="600" fontSize="1.6rem">
                    {selectedDocument?.title}
                  </Text>
                </Flex>
                <Flex
                  fontSize="1.6rem"
                  fontWeight="700"
                  lineHeight="2.88rem"
                  direction={"column"}
                  color="#EF4444"
                >
                  {" "}
                  <Text>
                    注意！
                    書類に含まれるピンのデータは削除されます。他の書類にも含まれるピンは残されます。
                  </Text>
                </Flex>
              </>
            }
            onApply={handleDeleteDocument}
          />
        )}

        {isOpenAddDocumentTemplateModal && (
          <ModalAddDocumentCategory
            documentItems={documentItems}
            isOpen={isOpenAddDocumentTemplateModal}
            isLoading={isLoadingAddDocumentTemplate}
            documentTemplates={Object.values(documentTemplates)}
            users={listUserById}
            neptuneAreas={neptuneAreas}
            levelDefault={levelSelected?.label}
            levels={levels}
            defaultValue={modalAddDocumentDefaultValue}
            documentGroups={documentGroupList}
            currentUser={currentUser}
            onClose={onCloseAddDocumentTemplateModal}
            onApply={handleAddDocumentCategory}
          />
        )}

        {isCreateDocumentItem && (
          <Portal containerRef={forgeViewContainerRef}>
            <TooltipForgeView
              propButton={{
                rightIcon: (
                  <SvgIcon
                    cursor="pointer"
                    src="/img/icon-close.svg"
                    width="2.4rem"
                    height="2.4rem"
                    onClick={onHandleCloseAddDocumentItem}
                  />
                ),
              }}
              title="ピン追加モード"
              callback={() =>
                documentCategorySelected &&
                focusCurrentDocCategory(documentCategorySelected)
              }
            />
          </Portal>
        )}
        {isShowEditingFamily && (
          <Portal containerRef={forgeViewContainerRef}>
            <TooltipForgeView
              title="一括抽出・削除モード"
              extra={
                <FamilyEditModal setClickedLabelInfo={setClickedLabelInfo} />
              }
              callback={() => {
                documentCategorySelected &&
                  focusCurrentDocCategory(documentCategorySelected);
              }}
            />
          </Portal>
        )}

        {isOpenOfflineTutorial && (
          <OfflineModeTutorialModal
            isOpen={isOpenOfflineTutorial}
            onClose={handleCloseOfflineTutorialModal}
          />
        )}
      </>
    );
  }
);

export default LeftPanel;
