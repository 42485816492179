import { Box, BoxProps, Flex, Spinner, VStack } from "@chakra-ui/react";
import CollapseModalIcon, {
  useCollapseModal,
} from "components/ui/CollapseModalIcon";
import { RIGHT_SIDEBAR_MODAL_CLASSNAME } from "constants/styleProps";
import { usePermission } from "hooks/usePermission";
import { useResize } from "hooks/useResize";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { PartnerCompany } from "interfaces/models/partnerCompany";
import { LeftPanelHandleType } from "pages/forge-viewer/LeftPanel";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { StylesConfig } from "react-select/dist/declarations/src";
import { RootState } from "redux/store";
import { ClickInfo } from "utils/forge/extensions/click-extension";
import Comment from "./Comment";
import useTaskModal from "./hooks";
import useChat from "./useChat";
import { useForgeViewerContext } from "pages/forge-viewer/ForgeViewerContext";
import useRevertTask from "./useRevertTask";
import ContentAfterFixed, {
  ContentAfterFixedRefType,
} from "./ContentAfterFixed";
import ContentBeforeFixed, {
  ContentBeforeFixedRefType,
} from "./ContentBeforeFixed";
import ContentCommon from "./ContentCommon";

export interface Props extends BoxProps {
  task: TaskDTO | undefined;
  filterTasks: TaskDTO[];
  isOpen: boolean;
  leftPanelRef?: React.RefObject<LeftPanelHandleType>;
  headerHeight?: string;
  contentHeight?: string;
  imageCaptured: React.MutableRefObject<File | undefined>;
  clickInfo?: ClickInfo;
  partnerCompanies?: PartnerCompany[];
  clickedLabelInfo: any;
  forgeViewContainerRef: React.RefObject<HTMLDivElement>;
  isFetchingPartnerCompanies?: boolean;
  mapTaskType: Map<string, string>;
  setClickInfo: React.Dispatch<React.SetStateAction<ClickInfo | undefined>>;
  onClose: () => void;
}

export const selectStyles: StylesConfig = {
  menuList: (base) => ({
    ...base,
    maxHeight: "200px",
  }),
};

export type TaskModalHandleType = {
  handleCollapse: () => void;
};

const TaskModal = forwardRef<TaskModalHandleType, Props>(
  (
    {
      leftPanelRef,
      task,
      filterTasks,
      forgeViewContainerRef,
      isOpen,
      headerHeight = "var(--header-height)",
      contentHeight,
      imageCaptured,
      clickInfo,
      partnerCompanies = [],
      clickedLabelInfo,
      isFetchingPartnerCompanies,
      mapTaskType,
      onClose,
      setClickInfo,
      ...rest
    }: Props,
    ref
  ) => {
    const [taskModalInfo, setTaskModalInfo] = useState<TaskDTO | undefined>();
    const { isOnline } = useSelector((state: RootState) => state.app);
    const { taskTypes, isLoadingTask } = useSelector(
      (state: RootState) => state.task
    );
    const contentAfterFixedRef = useRef<ContentAfterFixedRefType>();
    const contentBeforeFixedRef = useRef<ContentBeforeFixedRefType>();
    const { currentUser } = useSelector((state: RootState) => state.user);
    const { canEditTaskModal } = usePermission(currentUser?.role);
    const { sendWebSocketMessage, sendMessageToCommonChannel } =
      useForgeViewerContext();
    const { sizePanel } = useSelector((state: RootState) => state.app);
    const widthPanel = useMemo(
      () => `${sizePanel.width}${sizePanel.unit}`,
      [sizePanel]
    );

    const { width } = useResize();
    const containerRef = useRef<HTMLDivElement>(null);

    const { isCollapsed, setIsCollapsed } = useCollapseModal();

    useImperativeHandle(ref, () => ({
      handleCollapse: () => {
        !isCollapsed && handleCollapse();
      },
    }));

    useEffect(() => {
      if (isCollapsed) {
        handleCollapse();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clickedLabelInfo]);

    useEffect(() => {
      if (!containerRef.current) return;

      containerRef.current.scrollTop = 0;
    }, [task?.id]);

    const {
      listUserById,
      listAllUserById,
      comments,
      inputRef,
      containerChatRef,
      disabled: disableChat,
      loadingChat,
      loadingImage,
      editorState,
      suggestions,
      chatSavedRef,
      isFetchingUserAssigned,
      addChatMessage,
      onSearchChange,
      setEditorState,
      onKeyUp,
      onAddMessage,
      onScroll,
      handleKeyCommand,
      onDeleteImageChat,
      onUpdateImageComment,
      addTaskLog,
    } = useChat({
      setTaskModalInfo,
      taskTypes,
    });

    const {
      listImageSelected,
      attachesSelected,
      isProcessingTaskModal,
      indexFileUpload,
      isCreatingNewTask,
      handleSaveDrawImage,
      onDeleteTask,
      onChangeInput,
      updateDataOnBlurInput,
      onChangeTags,
      handleSelectedDay,
      onClickBtnSelectFile,
      onChangeFile,
      onDeleteImage,
      saveModalData,
      handleSaveCaptureCamera,
      onDeleteAttachFile,
      onChangeContentType,
      onChangeUserSingle,
      changeStatusTask,
      setIsDisableUploadImage,
      handleChangePartnerCompanies,
      onChangeIdentificationMode,
      handleUpdateFieldsChangeData,
    } = useTaskModal({
      filterTasks,
      task,
      imageCaptured,
      clickInfo,
      taskModalInfo,
      onClose,
      addTaskLog,
      setClickInfo,
      setTaskModalInfo,
    });

    const { handleRevertTaskByLog } = useRevertTask({
      taskModalInfo,
      saveTaskModalData: saveModalData,
      handleUpdateFieldsChangeData,
    });

    const isCurrentTask = useMemo(() => {
      return (
        taskModalInfo?.id === task?.id ||
        taskModalInfo?.id === chatSavedRef.current?.id
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskModalInfo, task, chatSavedRef.current]);

    const partnerCompanyOptions = useMemo(() => {
      return partnerCompanies?.map((item) => ({
        name: item?.name || "",
        value: item?.id,
      }));
    }, [partnerCompanies]);

    const mapPartnerCompany: { [id: string]: string } = useMemo(() => {
      return partnerCompanyOptions?.reduce((acc, item) => {
        return Object.assign(acc, {
          [item?.value]: item?.name,
        });
      }, {});
    }, [partnerCompanyOptions]);

    const handleCollapse = () => {
      setIsCollapsed((prev) => !prev);
    };

    const onScrollModal = (e: any) => {
      onScroll?.(e);
      contentAfterFixedRef.current?.onScrollModal(e);
      contentBeforeFixedRef.current?.onScrollModal(e);
    };

    return (
      <Box
        className={RIGHT_SIDEBAR_MODAL_CLASSNAME}
        width={isCollapsed ? "0" : widthPanel}
        position="absolute"
        right={0}
        height="100%"
        zIndex={11}
        transition="all 200ms ease-in-out 0s"
        filter="drop-shadow(rgba(0, 0, 0, 0.15) 0px 8px 12px) drop-shadow(rgba(0, 0, 0, 0.3) 0px 4px 4px)"
      >
        <Box
          p="0"
          width="100%"
          bgColor={"#FAFAFA"}
          height="100%"
          h="calc(var(--app-height) - var(--header-height) - var(--sub-header-height))"
          zIndex={7}
          position="relative"
          display="flex"
          flexDir="column"
          {...rest}
        >
          <CollapseModalIcon
            backgroundColor="#FAFAFA"
            borderRadius="5px 0px 0px 5px"
            borderRightStyle="hidden"
            borderLeftStyle="solid"
            height="12rem"
            width="3.8rem"
            top="50%"
            transform="translateY(-50%)"
            iconProps={{
              transform: isCollapsed ? "rotate(90deg)" : "rotate(-90deg)",
            }}
            onClose={handleCollapse}
          />

          {isCreatingNewTask || isLoadingTask || !taskModalInfo?.id ? (
            <Flex
              position="absolute"
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
              border={"2px solid white"}
            >
              <Spinner color="blue.500" size={"xl"} mt="1rem" />
            </Flex>
          ) : (
            <Box
              ref={containerRef}
              id="pin-detail-content-modal"
              height="100%"
              overflowX="hidden"
              onScroll={onScrollModal}
            >
              {isProcessingTaskModal && (
                <Box
                  position="absolute"
                  inset="0px"
                  backgroundColor="rgba(0,0,0,0.2)"
                  zIndex={999}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Spinner color="white" size="xl" mt="1rem" />
                </Box>
              )}
              <ContentCommon
                taskModalInfo={taskModalInfo}
                canEditTaskModal={canEditTaskModal}
                clickInfo={clickInfo}
                forgeViewContainerRef={forgeViewContainerRef}
                onDeleteTask={onDeleteTask}
                onClose={onClose}
                addTaskLog={addTaskLog}
                setClickInfo={setClickInfo}
                changeStatusTask={changeStatusTask}
                onChangeIdentificationMode={onChangeIdentificationMode}
                onChangeTags={onChangeTags}
                updateDataOnBlurInput={updateDataOnBlurInput}
                sendWebSocketMessage={sendWebSocketMessage}
                sendMessageToCommonChannel={sendMessageToCommonChannel}
                onChangeContentType={onChangeContentType}
              />

              <VStack
                spacing="1rem"
                fontSize="1.4rem"
                color="#171717"
                h="calc(100% - 7rem)"
              >
                <ContentBeforeFixed
                  ref={contentBeforeFixedRef}
                  taskSelected={task}
                  canEditTaskModal={canEditTaskModal}
                  partnerCompanyOptions={partnerCompanyOptions}
                  taskModalInfo={taskModalInfo}
                  selectedImages={listImageSelected.images}
                  isFetchingPartnerCompanies={isFetchingPartnerCompanies}
                  mapPartnerCompany={mapPartnerCompany}
                  listUserById={listUserById}
                  isFetchingUserAssigned={isFetchingUserAssigned}
                  isDisableUploadImage={isProcessingTaskModal}
                  isCurrentTask={isCurrentTask}
                  handleSelectedDay={handleSelectedDay}
                  handleChangePartnerCompanies={handleChangePartnerCompanies}
                  onChangeInput={onChangeInput}
                  onDeleteImage={onDeleteImage}
                  addChatMessage={addChatMessage}
                  handleSaveDrawImage={handleSaveDrawImage}
                  setIsDisableUploadImage={setIsDisableUploadImage}
                  onChangeUserSingle={onChangeUserSingle}
                  updateDataOnBlurInput={updateDataOnBlurInput}
                  onChangeFile={onChangeFile}
                  handleSaveCaptureCamera={handleSaveCaptureCamera}
                />
                <ContentAfterFixed
                  ref={contentAfterFixedRef}
                  canEditTaskModal={canEditTaskModal}
                  taskModalInfo={taskModalInfo}
                  selectedConfirmedImages={listImageSelected.confirmedImages}
                  listUserById={listUserById}
                  isFetchingUserAssigned={isFetchingUserAssigned}
                  isDisableUploadImage={isProcessingTaskModal}
                  isCurrentTask={isCurrentTask}
                  taskSelected={task}
                  attachesSelected={attachesSelected}
                  indexFileUpload={indexFileUpload}
                  onDeleteAttachFile={onDeleteAttachFile}
                  handleSelectedDay={handleSelectedDay}
                  onChangeInput={onChangeInput}
                  onDeleteImage={onDeleteImage}
                  addChatMessage={addChatMessage}
                  handleSaveDrawImage={handleSaveDrawImage}
                  onChangeUserSingle={onChangeUserSingle}
                  updateDataOnBlurInput={updateDataOnBlurInput}
                  onChangeFile={onChangeFile}
                  handleSaveCaptureCamera={handleSaveCaptureCamera}
                />

                <Box
                  w="100%"
                  flexDir="column"
                  fontSize="14px"
                  mt="auto!important"
                  flex="1"
                  background="#E5E5E5"
                >
                  <Comment
                    isOnline={isOnline}
                    editorState={editorState}
                    suggestions={suggestions}
                    comments={comments}
                    listAssignedUserById={listUserById}
                    listAllUserById={listAllUserById}
                    loading={loadingChat}
                    loadingImage={loadingImage}
                    disabledChat={disableChat}
                    containerRef={containerChatRef}
                    inputRef={inputRef}
                    status={taskModalInfo.status}
                    position={{ base: "fixed", md: "absolute" }}
                    windowWidth={width}
                    taskSelected={task}
                    isCurrentTask={isCurrentTask}
                    mapTaskType={mapTaskType}
                    mapPartnerCompany={mapPartnerCompany}
                    onSearchChange={onSearchChange}
                    setEditorState={setEditorState}
                    onKeyUp={onKeyUp}
                    handleKeyCommand={handleKeyCommand}
                    setTempInfo={saveModalData}
                    onDeleteImage={onDeleteImageChat}
                    onUpdateImageComment={onUpdateImageComment}
                    onClickAddMessage={onAddMessage}
                    onClickBtnSelectFile={onClickBtnSelectFile}
                    onRevertDataByLog={handleRevertTaskByLog}
                  />
                </Box>
              </VStack>
            </Box>
          )}
        </Box>
      </Box>
    );
  }
);

export default TaskModal;
