import { taskApi } from "apiClient/v2";
import {
  InspectionItemType,
  ModalType,
  SystemModeType,
  TASK_PRINT_MODE,
} from "constants/enum";
import { TypeOfFile } from "constants/file";
import { DEFAULT_TASK_MODAL_INFO, OPERATION } from "constants/task";
import useFamilyInstance from "hooks/useFamilyInstance";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { FileModel } from "interfaces/models";
import { TaskLog, TaskLogDTO } from "interfaces/models/task";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import {
  getMapDisplayValueFromTaskLogs,
  getMapTaskTypeKeyFromTaskLogs,
} from "models/commentLog";
import { getDefaultLogWhenCreateTask } from "models/dataLog";
import {
  getUpdatesWhenChangeDay,
  getUpdatesWhenChangeStatus,
} from "models/task";
import { useForgeViewerContext } from "pages/forge-viewer/ForgeViewerContext";
import useSupportSyncDataOffline, {
  TypeHandleInitData,
} from "pages/forge-viewer/hooks/useSupportSyncDataOffline";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  resetState,
  setCreateTask,
  setIsCreatingNewTask,
  setIsMoveTaskLabel,
  setModalType,
} from "redux/forgeViewerSlice";
import { RootState } from "redux/store";
import {
  insertTaskIdCreated,
  removeTask,
  setIsProcessingTaskModal,
  setStatusCreateTask,
  setTask,
  setTaskSelected,
} from "redux/taskSlice";

import { getNetworkStatus, uuid } from "utils/common";
import { getLogContents } from "utils/data-logs/task-log";
import { convertToUtcByOffsetLocal } from "utils/date";
import {
  getCurrentViewer,
  highlighTaskPin,
  selectDbIdOfTask,
  selectDbIds,
} from "utils/forge";
import { getDbIdByExternalId } from "utils/forge/data";
import { ClickInfo } from "utils/forge/extensions/click-extension";
import {
  CustomLabelExtension,
  DisplayItem,
} from "utils/forge/extensions/custom-label";
import { generateDataTaskLabelTemp } from "utils/task";
import { getLocalStorageUser } from "utils/user";
import useUploadFile from "./useUploadFile";

interface Props {
  task?: TaskDTO | null;
  filterTasks: TaskDTO[];
  taskModalInfo: TaskDTO | undefined;
  imageCaptured: React.MutableRefObject<File | undefined>;
  clickInfo?: ClickInfo;
  setTaskModalInfo: React.Dispatch<React.SetStateAction<TaskDTO | undefined>>;
  setClickInfo: React.Dispatch<React.SetStateAction<ClickInfo | undefined>>;
  addTaskLog: (logs: TaskLog[], requestId: string) => void;
  onClose: () => void;
}

export type SelectedImages = {
  images: FileModel[];
  confirmedImages: FileModel[];
};

export type SaveTaskModalData = {
  newTaskModalData: TaskDTO;
  fileData?: {
    [TypeOfFile.IMAGE]?: FileModel[];
    [TypeOfFile.ATTACH]?: FileModel[];
  };
  field?: keyof SelectedImages;
  isCompress?: boolean;
  taskLogs?: TaskLog[];
  requestId?: string;
  isRevertDataByLog?: boolean;
  isInsertMediaLog?: boolean;
  addCommentFunc?: () => void;
};
/**
 *
 *   --- Flow update  ---
 *  1. Set to task modal info
 *  2. Get Logs
 *  3. Call function save Modal
 */

const useTaskModal = ({
  task,
  filterTasks,
  taskModalInfo,
  imageCaptured,
  clickInfo,
  addTaskLog,
  setTaskModalInfo,
  onClose,
  setClickInfo,
}: Props) => {
  const { socket } = useForgeViewerContext();
  const { bimFileId } = useParams();
  const { levelSelected, isCreatingNewTask, isFilter } = useSelector(
    (state: RootState) => state.forgeViewer
  );
  const dispatch = useDispatch();
  const { familyInstances } = useFamilyInstance();
  const { currentUser } = useSelector((state: RootState) => state.user);
  const { taskTypes, isLoadingTask, taskIdsCreated, isProcessingTaskModal } =
    useSelector((state: RootState) => state.task);

  // reset loading when change task
  useEffect(() => {
    dispatch(setIsProcessingTaskModal(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task?.id]);

  const selfRef = useRef<any>({});
  const taskSelectedRef = useRef<TaskDTO>();
  const taskIdsCreatedRef = useRef<Array<string>>([]);
  const [operation] = useState(
    new URLSearchParams(window.location.search).get("operation")
  );

  const {
    fieldUpdateDataRef,
    initDataRef: initTaskDataRef,
    clearFieldUpdateData,
    handleUpdateFieldsChangeData,
    handleInitData: handleInitTaskData,
  } = useSupportSyncDataOffline<TaskDTO>();

  const {
    attachesSelected,
    listImageSelected,
    indexFileUpload,
    getDataImageHasModifiedDataFile,
    setListImageSelected,
    setIsDisableUploadImage,
    onDeleteAttachFile,
    setAttachesSelected,
    onClickBtnSelectFile,
    onDeleteImage,
    handleSaveDrawImage,
    onChangeFile,
    handleSaveCaptureCamera,
    setIndexFileUpload,
    handleUploadImageToS3,
    handleUploadAttach,
  } = useUploadFile({
    taskModalInfo,
    currentUser,
    setTaskModalInfo,
    handleUpdateFieldsChangeData,
    saveModalData: selfRef.current.saveModalData,
  });

  useEffect(() => {
    taskIdsCreatedRef.current = taskIdsCreated;
  }, [taskIdsCreated]);

  // close task modal when taskSelected not in filter tasks
  useEffect(() => {
    if (!isFilter || !task?.id) {
      return;
    }
    if (
      !filterTasks.some((item) => item.id === task.id) &&
      !taskIdsCreatedRef.current?.includes(task.id)
    ) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task, isFilter, filterTasks]);

  useEffect(() => {
    taskSelectedRef.current = task || undefined;
  }, [task]);

  useEffect(() => {
    if (isEmpty(task) || !task?.position || task?.id) {
      return;
    }
    // create task when open modal
    (async () => {
      dispatch(setIsCreatingNewTask(true));
      let fileData;
      let field;
      if (imageCaptured.current) {
        const image = {
          file: imageCaptured.current,
          name: imageCaptured.current.name,
          type: TypeOfFile.IMAGE,
        };
        fileData = {
          [TypeOfFile.IMAGE]: [image],
        };
        field = "images";
      }
      await saveModalData({
        newTaskModalData: task,
        fileData,
        field: field as keyof SelectedImages,
      });
      imageCaptured.current = undefined;
      if (operation === OPERATION.CreateTask) {
        dispatch(setCreateTask(false));
        dispatch(setIsMoveTaskLabel(true));
      }
      dispatch(setIsCreatingNewTask(false));
    })();
    // eslint-disable-next-line
  }, [task?.id]);

  // when fetching data -> set current modal info -> empty
  useEffect(() => {
    const isEqualId = task?.id && task?.id === taskModalInfo?.id;
    if (isLoadingTask) {
      clearFieldUpdateData();
      setTaskModalInfo(undefined as any);

      return;
    }
    if (isEqualId) {
      handleInitTaskData({
        data: task,
        type: TypeHandleInitData.TASK,
      });
    } else {
      clearFieldUpdateData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task?.id, taskModalInfo?.id, isLoadingTask]);

  useEffect(() => {
    setListImageSelected({
      images: task?.images
        ?.filter((image) => !!image)
        ?.map((image) => getDataImageHasModifiedDataFile(image)) as FileModel[],
      confirmedImages: task?.confirmedImages?.map((image) =>
        getDataImageHasModifiedDataFile(image)
      ) as FileModel[],
    });
    setAttachesSelected(task?.attaches || []);
    setTaskModalInfo(task || (DEFAULT_TASK_MODAL_INFO as any));
    // eslint-disable-next-line
  }, [task]);

  const onChangeTags = (tags: string[]) => {
    if (!taskModalInfo?.id) {
      return;
    }
    setTaskModalInfo({ ...taskModalInfo, tags });
    handleUpdateFieldsChangeData(["tags"]);
  };

  const handleSelectedDay = (field: keyof TaskDTO) => (day: Date | string) => {
    if (!taskModalInfo?.id) {
      return;
    }
    const updates = getUpdatesWhenChangeDay(taskModalInfo, field, day);
    const newTaskModalInfo: TaskDTO = {
      ...taskModalInfo,
      ...updates,
    };
    selectDbIdOfTask(newTaskModalInfo);
    handleChange(newTaskModalInfo, updates);
  };

  const onChangeInput = (field: string) => (event: any) => {
    if (!taskModalInfo?.id) {
      return;
    }
    handleUpdateFieldsChangeData([field as keyof TaskDTO]);
    setTaskModalInfo({ ...taskModalInfo, [field]: event.target.value });
  };

  const updateDataOnBlurInput = (field: keyof TaskDTO) => {
    if (!taskModalInfo?.id) {
      return;
    }
    const isArray = Array.isArray(taskModalInfo[field]);
    const isUpdateData =
      JSON.stringify(task?.[field] || (isArray ? [] : "")) !==
      JSON.stringify(taskModalInfo[field] || (isArray ? [] : ""));
    if (isUpdateData) {
      handleChange(taskModalInfo, { [field]: taskModalInfo[field] });
    }
  };

  const onChangeContentType = (value: string) => {
    if (!taskModalInfo?.id) {
      return;
    }
    const newTaskModal: TaskDTO = {
      ...taskModalInfo,
      taskTypeId: value,
    };
    setTimeout(() => {
      const updates = { taskTypeId: value };
      handleChange(newTaskModal, updates);
    });
  };

  const onChangeIdentificationMode = (value: TASK_PRINT_MODE) => {
    if (!taskModalInfo?.id) {
      return;
    }
    const newTaskModalInfo: TaskDTO = {
      ...taskModalInfo,
      printMode: value,
    };
    const updates = { printMode: value };
    handleChange(newTaskModalInfo, updates);
  };

  const onChangeUserSingle = ({
    field,
    user,
  }: {
    field: keyof TaskDTO;
    user: any;
  }) => {
    if (!taskModalInfo?.id) {
      return;
    }
    const newTaskModal = {
      ...taskModalInfo,
      [field]: user?.value || "",
    };
    // task log for field is changed
    const updates = { [field]: user?.value || "" };
    if (
      field === "userConfirmed" &&
      !!user &&
      taskModalInfo.status !== InspectionItemType.Confirmed
    ) {
      updates["status"] = InspectionItemType.Confirmed;
      // task log for self inspection
      newTaskModal.status = InspectionItemType.Confirmed;
      selectDbIdOfTask(newTaskModal);
    }
    handleChange(newTaskModal, updates);
  };

  const objectTypes = useMemo(() => {
    if (!taskModalInfo) {
      return [];
    }
    if (taskModalInfo.objectTypes) return taskModalInfo.objectTypes;
    if (taskModalInfo.externalId)
      return familyInstances[taskModalInfo.externalId]?.objectTypes.map(
        (item) => item.id
      );
  }, [familyInstances, taskModalInfo]);

  const showTaskLabelTemp = (displayItem: DisplayItem) => {
    const viewer = getCurrentViewer();
    const labelExt = viewer?.getExtension(
      "CustomLabelExtension"
    ) as CustomLabelExtension;
    if (labelExt && displayItem.tempId) {
      labelExt.showLabels({
        mode: SystemModeType.Task,
        data: [displayItem],
        options: {
          isShowTempLabel: true,
        },
      });
    }
  };

  const revertSaveModalData = useCallback(
    (displayItem: DisplayItem, isUpdateTask: boolean) => {
      const viewer = getCurrentViewer();
      const labelExt = viewer?.getExtension(
        "CustomLabelExtension"
      ) as CustomLabelExtension;

      if (labelExt && displayItem.tempId) {
        labelExt.removeTempLabels([displayItem]);
      }

      if (isUpdateTask && task) {
        dispatch(setTask(task));
        dispatch(setIsProcessingTaskModal(false));
      } else {
        dispatch(setStatusCreateTask(false));
        dispatch(setTaskSelected());
        dispatch(setModalType(ModalType.NONE));
        selectDbIds([], {});
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, task]
  );

  const highlightPinObject = useCallback(
    (taskPin: TaskDTO) => {
      const title = taskTypes.find(
        (taskType) => taskType.id === taskPin.taskTypeId
      )?.title;
      highlighTaskPin(taskPin!, title || "");
    },
    [taskTypes]
  );

  /**
   * create update task by bellow follow step
   *  1. call api upload image
   *  2. call api update data
   *  3. call api save log
   *  4. set to state
   *  5. update to forge viewer
   *  6. send socket
   *
   * @param param0
   * @returns
   */
  const saveModalData = async ({
    newTaskModalData,
    addCommentFunc,
    fileData,
    field,
    isCompress = true,
    taskLogs: _taskLogs = [],
    requestId: _requestId,
    isInsertMediaLog = true,
    isRevertDataByLog,
  }: SaveTaskModalData) => {
    let taskId: string | number | undefined = newTaskModalData?.id;
    const indexId = newTaskModalData?.indexId || 0;
    let defaultTags: string[] | null = null;
    const isUpdateTask = !!taskId;
    const requestId = _requestId || uuid();
    const taskLogs = _taskLogs;
    if (!newTaskModalData?.position && !isUpdateTask) {
      return;
    }
    const externalId = newTaskModalData?.externalId || "";
    if (!taskId) {
      // set default tags
      const objectTypes = familyInstances[externalId]?.objectTypes || [];
      defaultTags = objectTypes.map((objectType) => objectType.name);
    }
    const imageFiles =
      fileData?.[TypeOfFile.IMAGE] ||
      listImageSelected?.[field as keyof SelectedImages] ||
      [];
    const listImage = await handleUploadImageToS3(
      imageFiles,
      isCompress,
      requestId
    );
    const attachFiles = fileData?.[TypeOfFile.ATTACH] || attachesSelected || [];
    const attaches = await handleUploadAttach(
      attachFiles,
      requestId,
      task?.attaches || []
    );
    if (isInsertMediaLog) {
      const updates: Partial<TaskDTO> = { attaches };
      if (field) {
        updates[field] = listImage;
      }
      const logs = getLogContents(taskId, updates, task);
      taskLogs.push(...logs);
    }
    // total image:  include task image and comment image
    const totalImageNum = task?.numberImage || 0;
    const imageNum = task?.images?.length || 0;
    let numberImage = Math.min(0, totalImageNum - imageNum);
    numberImage += listImage.length;
    const userSeen = [...(newTaskModalData?.userSeen || [])];
    if (!userSeen.includes(currentUser?.id!)) {
      userSeen.push(currentUser?.id!);
    }
    const now = new Date();
    const isOnline = getNetworkStatus();
    const currentDateUtc = new Date();
    convertToUtcByOffsetLocal(currentDateUtc);
    const bodyCreateTask: TaskDTO = {
      ...newTaskModalData,
      id: taskId,
      indexId,
      externalId,
      bimFileId: bimFileId || "",
      tags: newTaskModalData.tags || defaultTags || undefined,
      status: newTaskModalData?.status ?? InspectionItemType.Defect,
      objectTypes,
      hasComment: !!addCommentFunc || newTaskModalData.hasComment,
      level:
        newTaskModalData?.level ||
        (levelSelected?.guid ? levelSelected.label : ""),
      userCreated: newTaskModalData?.userCreated || currentUser?.id || "",
      attaches,
      numberImage,
      userSeen,
      uranusUrl: newTaskModalData.uranusUrl || clickInfo?.forgeData?.uranus_url,
      uranusStepId:
        newTaskModalData.uranusStepId || clickInfo?.forgeData?.uranusStepId,
      images: field === "images" ? listImage : task?.images,
      confirmedImages:
        field === "confirmedImages" ? listImage : task?.confirmedImages,
      printMode: newTaskModalData.printMode || TASK_PRINT_MODE.PRINTABLE,
      updatedAt: now,
      createdAt: newTaskModalData.createdAt || now,
      taskDate: newTaskModalData?.taskDate || currentDateUtc,
      mapTaskTypeKey: getMapTaskTypeKeyFromTaskLogs(taskLogs as TaskLogDTO[]),
      mapDisplayValueKey: getMapDisplayValueFromTaskLogs(taskLogs),
      requestId,
      createdBy: getLocalStorageUser()?.id!,
    };

    const bodyUpdate: TaskDTO = {
      id: newTaskModalData.id,
      updatedAt: now,
      documentItemId: bodyCreateTask.documentItemId,
      mapTaskTypeKey: bodyCreateTask.mapTaskTypeKey,
      mapDisplayValueKey: bodyCreateTask.mapDisplayValueKey,
      requestId: bodyCreateTask.requestId,
      isRevertDataByLog,
    } as any;
    if (!isOnline) {
      bodyUpdate.initData = initTaskDataRef.current?.initData || ({} as any);
    }
    if (isUpdateTask) {
      fieldUpdateDataRef.current.forEach((key) => {
        bodyUpdate[key] = bodyCreateTask[key] as never;
      });
    }
    const dataTaskLabelTemp = generateDataTaskLabelTemp(newTaskModalData);
    // show task's label temp
    if (!isUpdateTask) {
      showTaskLabelTemp(dataTaskLabelTemp);
    }
    let { data: newTask } = isUpdateTask
      ? await taskApi.updateTask(bodyUpdate)
      : await taskApi.createTask(bodyCreateTask);

    if (!isUpdateTask) {
      dispatch(setStatusCreateTask(false));
    }
    // handle revert task when save data have been failure
    if (!newTask?.id) {
      revertSaveModalData(dataTaskLabelTemp, isUpdateTask);

      return;
    }
    if (!taskId && newTask?.id) {
      const logs = getDefaultLogWhenCreateTask(bodyCreateTask, newTask.id);
      taskLogs.push(...logs);
    }

    taskId = newTask?.id;
    if (!isUpdateTask) {
      dispatch(insertTaskIdCreated(taskId));
    }
    delete newTask?.isRevertDataByLog;
    newTask = { ...bodyCreateTask, ...newTask };
    if (fileData?.[TypeOfFile.ATTACH]) {
      setIndexFileUpload([]);
    }
    newTask.dbId = getDbIdByExternalId(newTask.externalId);
    const taskItemHighlightObj = {
      ...newTaskModalData,
      showImage: Number(newTask?.images?.length) > 0,
    };
    // data relative to label temp will deleted in func updateLabel
    highlightPinObject(taskItemHighlightObj);
    const currentSelectedId = taskSelectedRef.current?.id;
    addTaskLog((taskLogs || []) as TaskLogDTO[], requestId);

    if (
      taskId &&
      !isEqual(attaches, newTask.attaches) &&
      newTask.id === currentSelectedId
    ) {
      setAttachesSelected(attaches);
    }
    // when create new task -> need set task selected
    if (!isUpdateTask) {
      dispatch(setTaskSelected(newTask));
    }
    dispatch(setIsProcessingTaskModal(false));
    dispatch(setTask(newTask));
    // check has  new file upload files
    setTaskModalInfo(newTask);
    handleInitTaskData({
      data: newTask,
      type: TypeHandleInitData.TASK,
    });
    clearFieldUpdateData();
    setClickInfo(undefined);
    if (isUpdateTask) {
      socket.updateTask(bodyUpdate);
    } else {
      socket.addTask(newTask);
    }

    return task;
  };

  selfRef.current.saveModalData = saveModalData;
  const onDeleteTask = async () => {
    if (task?.id) {
      await taskApi.deleteTaskList([task.id]).then(() => {
        socket.deleteTask(task);
        dispatch(removeTask(task));
        dispatch(resetState());
        onClose();
      });
    }
  };

  const changeStatusTask = async (statusChange: string) => {
    if (!taskModalInfo?.id) {
      return;
    }
    const updates = getUpdatesWhenChangeStatus(
      taskModalInfo,
      statusChange,
      currentUser?.id!
    );
    const newTaskModalData = {
      ...(task || taskModalInfo),
      ...updates,
    } as TaskDTO;
    highlightPinObject(newTaskModalData);
    handleChange(newTaskModalData, updates);
  };

  const handleChangePartnerCompanies = (id: string | string[]) => {
    if (!taskModalInfo?.id) {
      return;
    }
    const newTaskModalData = {
      ...taskModalInfo,
      partnerCompany: id as string,
    };
    const updates = {
      partnerCompany: id,
    };
    handleChange(newTaskModalData, updates);
  };

  const handleChange = (
    newTask: TaskDTO,
    updates: { [field: string]: any }
  ) => {
    if (newTask.id === task?.id) {
      setTaskModalInfo(newTask);
    }
    handleUpdateFieldsChangeData(Object.keys(updates) as any);
    const taskLogs = getLogContents(newTask.id, updates, task);
    saveModalData({
      newTaskModalData: newTask,
      taskLogs,
    });
  };

  return {
    taskModalInfo,
    listImageSelected,
    attachesSelected,
    taskTypes,
    isProcessingTaskModal,
    indexFileUpload,
    isCreatingNewTask,
    handleSaveDrawImage,
    onDeleteTask,
    onChangeTags,
    handleSelectedDay,
    updateDataOnBlurInput,
    onChangeInput,
    onClickBtnSelectFile,
    onChangeFile,
    onDeleteImage,
    onDeleteAttachFile,
    saveModalData,
    handleSaveCaptureCamera,
    onChangeContentType,
    onChangeUserSingle,
    changeStatusTask,
    setIsDisableUploadImage,
    handleChangePartnerCompanies,
    onChangeIdentificationMode,
    handleUpdateFieldsChangeData,
  };
};

export default useTaskModal;
