import { UserDTO } from "interfaces/dtos/UserDTO";
import { TaskComment } from "interfaces/models/task";
import { User } from "interfaces/models/user";
import { useCallback } from "react";
import { transformTextForTaskLog } from "utils/data-logs/task-log";

interface Props {
  currentUser: User | null;
  listUserById: { [key: string]: UserDTO | null };
  mapTaskType: Map<string, string>;
  mapPartnerCompany: { [key: string]: string };
}

const useRecordComment = (props: Props) => {
  const { currentUser, listUserById, mapTaskType, mapPartnerCompany } = props;

  const getLogText = useCallback(
    (recordComment: TaskComment) =>
      transformTextForTaskLog({
        log: recordComment,
        user: currentUser,
        listUserById,
        mapTaskType,
        mapPartnerCompany,
      }),
    [listUserById, mapTaskType, mapPartnerCompany, currentUser]
  );

  return { getLogText };
};

export default useRecordComment;
