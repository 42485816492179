import {
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spinner,
  Text,
  UseModalProps,
} from "@chakra-ui/react";

import { PCTooltip } from "components/PCTooltip";
import { SvgIcon } from "components/SvgIcon";
import ColorPicker, { DEFAULT_COLOR } from "components/ui/ColorPicker";
import { BASE_S3_URL } from "constants/app";
import { KeyBoardCode } from "constants/enum";
import { fabric } from "fabric";
import { useDetectDeviceOS } from "hooks/useDetectDevice";
import { useCallback, useEffect, useRef, useState } from "react";
import { deleteObjects, removeCanvasEvents } from "utils/fabric";
import { Circle } from "utils/fabric/circle";
import { Line } from "utils/fabric/line";
import { Rectangle } from "utils/fabric/rectangle";
import { Text as FText } from "utils/fabric/text";
import { Triangle } from "utils/fabric/triangle";
import { zoomPan } from "utils/fabric/zoom";
import { base64ToFile, getPreSignUrl } from "utils/file";
import { logDev } from "utils/logs";
import ImageCropper, { ImageCropperRefType } from "components/ImageCropper";

import "utils/fabric/redo-undo";
import { IconBase, message } from "components/base";

const DEFAULT_SCALE_WIDTH = 0.25;

export interface HandleSelectDrawImageProps {
  file: File & { lastModifiedDate?: Date };
  isEdited: boolean;
  originFile: File;
  activeThumb?: number;
  hasCapturedCamera?: boolean;
}

interface Props extends UseModalProps {
  fileRef: React.MutableRefObject<File | undefined>;
  position?: number[] | undefined;
  imageUrl?: string;
  onSelect: (params: HandleSelectDrawImageProps) => void;
  reCapture?: () => void;
  isViewerLoading?: boolean;
  disabledReCapture?: boolean;
  onCloseAfterSave?: () => void;
}

enum DrawType {
  Mouse = "mouse",
  Crop = "crop",
  Square = "square",
  Triangle = "triangle",
  Circle = "circle",
  Line = "line",
  FreeLine = "free-line",
  Eraser = "eraser",
  Text = "text",
  FillColor = "fill-color",
  SelectColor = "select-color",
  Redo = "redo",
  Undo = "undo",
}

const buttons = [
  { icon: "/img/draw-tool/undo.svg", type: DrawType.Undo },
  { icon: "/img/draw-tool/redo.svg", type: DrawType.Redo },
  { icon: "/img/draw-tool/mouse-cursor.svg", type: DrawType.Mouse },
  { icon: "/img/draw-tool/crop.svg", type: DrawType.Crop },
  { icon: "/img/draw-tool/circle.svg", type: DrawType.Circle },
  { icon: "/img/draw-tool/pen-line.svg", type: DrawType.Line },
  { icon: "/img/draw-tool/free-line.svg", type: DrawType.FreeLine },
  { icon: "/img/draw-tool/eraser.svg", type: DrawType.Eraser },
  { icon: "/img/draw-tool/text.svg", type: DrawType.Text },
];
function DrawToolModal({
  isOpen,
  imageUrl,
  fileRef,
  isViewerLoading = false,
  onCloseAfterSave,
  onSelect,
  onClose,
  reCapture,
  disabledReCapture,
}: Props) {
  const { isMacOS } = useDetectDeviceOS();

  const [aspectRatio, setAspectRatio] = useState(0);
  const [color, setColor] = useState(DEFAULT_COLOR);
  const [currType, setCurrType] = useState<DrawType | undefined>(
    DrawType.FreeLine
  );
  const [isLoading, setIsLoading] = useState(true);
  const [lineThickness, setLineThickness] = useState(6);
  const [showTooltip, setShowTooltip] = useState(false);
  const cropImageRef = useRef<ImageCropperRefType | null>(null);

  const boxContainer = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const canvasOriginInstance = useRef<fabric.Canvas | null>(null);
  const canvasInstance = useRef<fabric.Canvas | null>(null);
  const currTypeRef = useRef<DrawType | undefined>(currType);
  const colorRef = useRef(color);
  const toolBarRef = useRef<HTMLDivElement>(null);
  const file = fileRef?.current;
  const disabledDeleteShortcutRef = useRef(false);
  const canvasSize = useRef<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });

  const handleWheel = useCallback(
    (event: WheelEvent) => {
      if (currType === DrawType.Crop) {
        return;
      }
      const canvas = canvasInstance.current;
      if (canvas && currType !== DrawType.Mouse) {
        const delta = event.deltaY;
        let zoom = canvas.getZoom();

        zoom *= 0.999 ** delta;
        if (zoom > 20) zoom = 20;
        if (zoom < 0.01) zoom = 0.01;

        canvas.setZoom(zoom);

        const { width, height } = canvasSize.current;

        if (width && height) {
          const sizeContainer = {
            width: boxContainer.current?.offsetWidth || 0,
            height: boxContainer.current?.offsetHeight || 0,
          };

          const heightHeader = headerRef.current?.offsetHeight || 0;
          const heightToolbar = toolBarRef.current?.offsetHeight || 0;

          const maxSizeCanvas = {
            width:
              sizeContainer.width - sizeContainer.width * DEFAULT_SCALE_WIDTH ||
              0,
            height: sizeContainer.height - heightHeader - heightToolbar || 0,
          };

          const sizeCanvas = {
            width:
              width * zoom > maxSizeCanvas.width
                ? maxSizeCanvas.width
                : width * zoom,
            height:
              height * zoom > maxSizeCanvas.height
                ? maxSizeCanvas.height
                : height * zoom,
          };

          canvas.setWidth(sizeCanvas.width);
          canvas.setHeight(sizeCanvas.height);
        }

        event.preventDefault();
        event.stopPropagation();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currType,
      canvasSize.current,
      boxContainer.current,
      headerRef.current,
      toolBarRef.current,
    ]
  );

  const calculateCanvasSize = (canvas: fabric.Canvas) => {
    canvas.setZoom(1);
    const { width, height } = canvas;

    if (width && height) {
      canvasSize.current = {
        width,
        height,
      };
    }

    // canvas.renderAll();
  };

  const handleUndoOrRedoByKeyboard = (e: KeyboardEvent) => {
    const canvas = canvasInstance.current;

    if (
      [
        KeyBoardCode.Delete as string,
        KeyBoardCode.Backspace as string,
      ].includes(e.code) &&
      !disabledDeleteShortcutRef.current
    ) {
      const activeObject = canvas?.getActiveObject();
      if (activeObject) {
        canvas?.remove(activeObject);
      }
    }

    // On MacOs
    if (isMacOS) {
      if (e.metaKey) {
        if (e.shiftKey && e.code === KeyBoardCode.KeyZ) {
          canvas?.redo(calculateCanvasSize);

          return;
        }

        if (e.code === KeyBoardCode.KeyZ) {
          canvas?.undo(calculateCanvasSize);

          return;
        }
      }

      return;
    }

    // On Window
    if (e.ctrlKey) {
      if (e.code === KeyBoardCode.KeyZ) {
        canvas?.undo(calculateCanvasSize);

        return;
      }

      if (e.code === KeyBoardCode.KeyY) {
        canvas?.redo(calculateCanvasSize);

        return;
      }
    }
  };

  useEffect(() => {
    const boxContainerEle = boxContainer.current;

    if (boxContainerEle) {
      boxContainer?.current?.addEventListener(
        "keydown",
        handleUndoOrRedoByKeyboard
      );

      return () => {
        boxContainerEle?.removeEventListener(
          "keydown",
          handleUndoOrRedoByKeyboard
        );
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boxContainer?.current, disabledDeleteShortcutRef.current]);

  const removeWheelEventListener = useCallback(() => {
    document?.removeEventListener("wheel", handleWheel);
  }, [handleWheel]);

  useEffect(() => {
    document?.addEventListener("wheel", handleWheel, {
      passive: false,
    });

    if (!isOpen) {
      removeWheelEventListener();
    }

    return () => {
      removeWheelEventListener();
    };
  }, [handleWheel, isOpen, removeWheelEventListener]);

  useEffect(() => {
    colorRef.current = color;
    const canvas = canvasInstance.current;
    if (!canvas) return;
    canvas.freeDrawingBrush.color = color;
  }, [color]);

  const onSave = () => {
    const hasCapturedCamera = Boolean(fileRef.current);
    if (isViewerLoading) {
      return;
    }
    const canvas = canvasInstance.current;
    const canvasOrigin = canvasOriginInstance.current;

    if (!canvas) {
      return;
    }
    canvas.setZoom(1);
    canvas.absolutePan({ x: 0, y: 0 });
    canvas.setWidth(canvasSize.current.width);
    canvas.setHeight(canvasSize.current.height);
    canvas.renderAll();

    const eFile = base64ToFile(
      canvas?.toDataURL({ format: "jpeg" }) ?? "",
      `imgDraw-${Date.now()}.jpg`
    );
    const isEdited = !!canvas?.stateManager.stateStack?.length;
    const originFile = base64ToFile(
      canvasOrigin?.toDataURL({ format: "jpeg" }) ?? "",
      `imgDraw-${Date.now()}.jpg`
    );
    fileRef.current = eFile;
    onSelect({ file: eFile, originFile, isEdited, hasCapturedCamera });

    if (onCloseAfterSave) {
      onCloseAfterSave();
    } else {
      onClose();
    }
  };

  useEffect(() => {
    if (((!file || !file.type) && !imageUrl) || !isOpen) return;
    logDev("____new canvas");
    setIsLoading(true);
    setCurrType(DrawType.FreeLine);
    currTypeRef.current = DrawType.FreeLine;

    (async () => {
      // add background image
      if (imageUrl) {
        return readFileFromUrl(imageUrl);
      }
      if (!file) {
        return;
      }

      const reader = new FileReader();
      reader.onload = async function (f) {
        const data = f.target?.result as string;
        readFileFromUrl(data);
      };
      reader.readAsDataURL(file);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, isOpen, imageUrl]);

  const readFileFromUrl = async (url: string) => {
    if (
      url.startsWith(BASE_S3_URL) &&
      !url.includes("X-Amz-SignedHeaders=host")
    ) {
      url = await getPreSignUrl(url, "");
    }

    const src = await fetch(url).then(async (res) => {
      const blob = await res.blob();
      const objectURL = URL.createObjectURL(blob);

      return objectURL;
    });

    fabric.Image.fromURL(
      src,
      function (img) {
        if (!img.width || !img.height) {
          onClose();

          return;
        }
        canvasInstance.current = new fabric.Canvas("c");
        canvasOriginInstance.current = new fabric.Canvas("c-origin");
        const canvas = canvasInstance.current;
        const canvasOrigin = canvasOriginInstance.current;
        const initCanvas = (canvas: fabric.Canvas, img: fabric.Image) => {
          if (!img.width || !img.height) {
            return canvas;
          }

          canvas.freeDrawingBrush.color = color;
          canvas.selection = false;
          canvas.freeDrawingBrush.width = lineThickness;
          canvas.isDrawingMode = true;

          const sizeContainer = {
            width: boxContainer.current?.offsetWidth || 0,
            height: boxContainer.current?.offsetHeight || 0,
          };

          const heightHeader = headerRef.current?.offsetHeight || 0;
          const heightToolbar = toolBarRef.current?.offsetHeight || 0;

          const maxSizeCanvas = {
            width:
              sizeContainer.width - sizeContainer.width * DEFAULT_SCALE_WIDTH ||
              0,
            height: sizeContainer.height - heightHeader - heightToolbar || 0,
          };

          let scale = 1,
            canvasWidth = img.width,
            canvasHeight = img.height;

          if (img.height > maxSizeCanvas.height) {
            scale = maxSizeCanvas.height / img.height;
            canvasWidth = canvasWidth * scale;
            canvasHeight = canvasHeight * scale;
          }

          if (canvasWidth > maxSizeCanvas.width) {
            scale = maxSizeCanvas.width / canvasWidth;
            canvasWidth = canvasWidth * scale;
            canvasHeight = canvasHeight * scale;
          }

          canvasSize.current = { width: canvasWidth, height: canvasHeight };

          canvas.setDimensions({
            width: canvasWidth,
            height: canvasHeight,
          });
          setAspectRatio(canvasWidth / canvasHeight);
          canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
            scaleX: canvasWidth / img.width,
            scaleY: canvasHeight / img.height,
            crossOrigin: "anonymous",
          });
          canvas.centerObject(img);
          canvas.renderAll();
          setIsLoading(false);
          canvas.historyInit();
        };

        initCanvas(canvas, img);
        initCanvas(canvasOrigin, img);
      },
      {
        crossOrigin: "anonymous",
      }
    );
  };

  const onSelectTool = useCallback(
    (type: DrawType) => () => {
      logDev({ type });
      const canvas = canvasInstance.current!;
      // reset
      canvas.discardActiveObject();
      canvas.selection = false;
      canvas.forEachObject(function (o) {
        o.selectable = false;
      });
      canvas.isDrawingMode = false;
      canvas.hoverCursor = "crosshair";
      canvas.lineThickness = lineThickness;
      removeCanvasEvents(canvas);
      if (
        type === currTypeRef.current &&
        ![DrawType.Redo, DrawType.Undo]?.includes(type)
      ) {
        setCurrType(undefined);
        currTypeRef.current = undefined;

        return;
      }
      setCurrType(type);
      currTypeRef.current = type;

      switch (type) {
        case DrawType.Redo: {
          canvas.redo(calculateCanvasSize);
          break;
        }
        case DrawType.Undo: {
          canvas.undo(calculateCanvasSize);
          break;
        }
        case DrawType.Mouse: {
          disabledDeleteShortcutRef.current = false;
          canvas.selection = true;
          canvas.hoverCursor = "move";
          canvas.forEachObject(function (o) {
            o.selectable = true;
          });
          zoomPan(canvas);
          break;
        }
        case DrawType.Crop: {
          // Nothing
          break;
        }
        case DrawType.Square: {
          new Rectangle(canvas);
          break;
        }
        case DrawType.Triangle: {
          new Triangle(canvas);
          break;
        }
        case DrawType.Circle: {
          new Circle(canvas);
          break;
        }
        case DrawType.Line: {
          new Line(canvas);
          break;
        }
        case DrawType.FreeLine: {
          canvas.freeDrawingBrush.width = lineThickness;
          canvas.isDrawingMode = true;
          break;
        }
        case DrawType.Eraser: {
          canvas.selection = true;
          canvas.hoverCursor = "url('/img/draw-tool/erase.png'), auto";
          canvas.forEachObject(function (o) {
            o.selectable = true;
          });
          canvas.on("mouse:down", function (options) {
            if (options.target) {
              if (currTypeRef.current === DrawType.Eraser) {
                deleteObjects(canvas);
              }
            }
          });
          break;
        }
        case DrawType.Text: {
          disabledDeleteShortcutRef.current = true;
          new FText(canvas);
          break;
        }
        case DrawType.FillColor: {
          canvas.selection = true;
          canvas.forEachObject(function (o) {
            o.selectable = true;
          });
          canvas.hoverCursor = "url('/img/draw-tool/fill-color.png'), auto";
          canvas.on("mouse:down", function (options) {
            if (options.target) {
              if (currTypeRef.current === DrawType.FillColor) {
                const activeObject = canvas.getActiveObject();
                logDev("fill color", colorRef.current);
                activeObject?.set("fill", colorRef.current);
                canvas.renderAll();
                canvas.discardActiveObject();
              }
            }
          });
          break;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [color, imageUrl, file, lineThickness]
  );

  const handleCancelCrop = () => {
    const canvas = canvasInstance.current;

    if (canvas) {
      setCurrType(DrawType.FreeLine);
      currTypeRef.current = DrawType.FreeLine;
      canvas.freeDrawingBrush.width = lineThickness;
      canvas.isDrawingMode = true;
    }
  };

  const handleCrop = () => {
    const canvas = canvasInstance.current;
    try {
      const img = cropImageRef.current?.getCroppedData();
      if (img && canvas) {
        const backupCanvasWidth = canvasSize.current.width;
        const backupCanvasHeight = canvasSize.current.height;
        const sizeContainer = {
          width: boxContainer.current?.offsetWidth || 0,
          height: boxContainer.current?.offsetHeight || 0,
        };

        const heightHeader = headerRef.current?.offsetHeight || 0;
        const heightToolbar = toolBarRef.current?.offsetHeight || 0;

        const maxSizeCanvas = {
          width:
            sizeContainer.width - sizeContainer.width * DEFAULT_SCALE_WIDTH ||
            0,
          height: sizeContainer.height - heightHeader - heightToolbar || 0,
        };

        const imgObject = new fabric.Image(img);

        let scale = 1,
          canvasWidth = img.width,
          canvasHeight = img.height;

        if (img.height > maxSizeCanvas.height) {
          scale = maxSizeCanvas.height / img.height;
          canvasWidth = canvasWidth * scale;
          canvasHeight = canvasHeight * scale;
        }

        if (canvasWidth > maxSizeCanvas.width) {
          scale = maxSizeCanvas.width / canvasWidth;
          canvasWidth = canvasWidth * scale;
          canvasHeight = canvasHeight * scale;
        }

        canvasSize.current = { width: canvasWidth, height: canvasHeight };

        canvas.setDimensions({
          width: canvasWidth,
          height: canvasHeight,
        });

        canvas.setBackgroundImage(imgObject, canvas.renderAll.bind(canvas));
        canvas.centerObject(imgObject);
        canvas.calcOffset();
        canvas.setZoom(1);
        canvas.lockHistory(true);
        canvas.remove(...canvas.getObjects());
        canvas.lockHistory(false);
        canvas.saveState({
          width: backupCanvasWidth,
          height: backupCanvasHeight,
        });

        // set currType to default
        setCurrType(DrawType.FreeLine);
        currTypeRef.current = DrawType.FreeLine;
        canvas.freeDrawingBrush.width = lineThickness;
        canvas.isDrawingMode = true;
      }
    } catch (error) {
      message.error(error instanceof Error ? error.message : "Unknown error!");
    }
  };

  useEffect(() => {
    if (!isOpen) {
      // reset
      setColor(DEFAULT_COLOR);
    }
  }, [isOpen]);

  const onChangeThickness = (value: number) => {
    setLineThickness(value);
    const canvas = canvasInstance.current;
    if (canvas) {
      canvas.lineThickness = value;
      canvas.freeDrawingBrush.width = value;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {!isLoading && <ModalOverlay />}

      <ModalContent
        my="auto"
        maxW="auto"
        maxH="auto"
        p="0"
        overflow="hidden"
        width="95%"
        height="95%"
        position="relative"
        display={"flex"}
        alignItems="center"
        sx={
          !imageUrl
            ? {
                rounded: "none",
                width: "100%",
                height: "100%",
              }
            : {}
        }
        ref={boxContainer}
      >
        {isLoading && (
          <Flex
            alignItems="center"
            justifyContent={"center"}
            w="100%"
            h="100%"
            bgColor="#F2F2F2"
            height="calc(var(--app-height) - var(--header-height))"
            className="loading"
            position="absolute"
            left="0"
            top="0"
            opacity="0.7"
          >
            <Spinner color="blue.500" size="lg" />
          </Flex>
        )}

        <Flex
          position="absolute"
          alignItems={"center"}
          justifyContent="flex-end"
          zIndex={999}
          w="100%"
          top="0px"
          left="0"
          p="4rem"
          gap="2rem"
          color="#707070"
          ref={headerRef}
        >
          <Flex
            direction="column"
            rounded="0.8rem"
            w="6rem"
            h="6rem"
            alignItems="center"
            justifyContent="center"
            bgColor="#fff"
            filter="brightness(96%)"
            onClick={onClose}
            _hover={{ cursor: "pointer" }}
          >
            <SvgIcon src="/img/icon-navigation-close.svg" pathFill="#707070" />
            戻る
          </Flex>

          <Flex
            direction="column"
            rounded="0.8rem"
            w="6rem"
            h="6rem"
            alignItems="center"
            justifyContent="center"
            bgColor="#fff"
            filter="brightness(96%)"
            onClick={reCapture}
            opacity={disabledReCapture ? 0.5 : 1}
            _hover={{ cursor: disabledReCapture ? "not-allowed" : "pointer" }}
          >
            <SvgIcon
              width="24px"
              height="24px"
              src="/img/refresh.svg"
              pathFill="#707070"
            />
            再撮影
          </Flex>

          <Button
            ml="auto"
            variant="filled"
            onClick={onSave}
            isDisabled={isViewerLoading || isLoading}
            leftIcon={
              <IconBase
                width="1.6rem"
                height="1.6rem"
                icon="/img/icon-action-check_circle.svg"
              />
            }
          >
            完了
          </Button>
        </Flex>
        <Flex
          margin="auto"
          position="relative"
          direction="column"
          alignItems="center"
          zIndex={999}
        >
          <Flex
            className="image-edit-container"
            position="relative"
            justifyContent="center"
            boxShadow={
              currType === DrawType.Crop
                ? "none"
                : "rgba(0, 0, 0, 0.24) 0px 0px 9px"
            }
            sx={{
              ".canvas-container": {
                ...(currType === DrawType.Crop
                  ? {
                      visibility: "hidden",
                    }
                  : {}),
              },

              "& > .canvas-container:nth-of-type(2)": {
                display: "none",
              },
            }}
          >
            <canvas id="c"></canvas>
            <canvas style={{ display: "none" }} id="c-origin"></canvas>
            {currType === DrawType.Crop && canvasInstance.current && (
              <ImageCropper
                ref={cropImageRef}
                aspectRatio={aspectRatio}
                image={canvasInstance.current.toDataURL()}
              />
            )}
          </Flex>
        </Flex>
        <Flex
          position="absolute"
          pt="1rem"
          ref={toolBarRef}
          justifyContent="center"
          background="none"
          width="100%"
          bottom="0"
        >
          {currType === DrawType.Crop && canvasInstance.current ? (
            <HStack
              minW="4.8rem"
              boxShadow="0px 3px 6px 3px #00000029"
              borderRadius="8px 8px 0 0"
              p={{ base: "1.8rem", md: "1.8rem 6rem" }}
              spacing="2rem"
              mr="0.8rem"
              direction="row"
              background="white"
              position="relative"
            >
              <Button onClick={handleCancelCrop} variant="dangerOutline">
                破棄
              </Button>
              <Button variant="filled" onClick={handleCrop}>
                クロップ
              </Button>
            </HStack>
          ) : (
            <HStack
              minW="4.8rem"
              boxShadow="0px 3px 6px 3px #00000029"
              borderRadius="8px 8px 0 0"
              p={{ base: "1.8rem", md: "1.8rem 6rem" }}
              spacing="2rem"
              mr="0.8rem"
              direction="row"
              background="white"
              position="relative"
              sx={{
                input: {
                  w: "0",
                  maxW: "0",
                  visibility: "hidden",
                },
                ".redo, .undo": {
                  backgroundColor: "lightgray",
                  borderRadius: "50%",
                  w: "3rem",
                  h: "3rem",
                  p: "0.3rem",
                },
              }}
            >
              {buttons.map((d) => (
                <SvgIcon
                  key={d.type}
                  className={`button ${d.type}`}
                  src={d.icon}
                  onClick={onSelectTool(d.type)}
                  sx={{
                    "#icon-path": {
                      fill: currType === d.type ? color : "",
                    },
                    "#icon-stroke": {
                      stroke: currType === d.type ? color : "",
                    },
                  }}
                />
              ))}

              <ColorPicker onSelect={setColor}>
                <SvgIcon
                  className="button"
                  src="/img/draw-tool/select-color.svg"
                  sx={{
                    "#path-color": {
                      fill: `${color} !important`,
                      stroke: "rgba(0, 0, 0, 0.1)",
                    },
                  }}
                />
              </ColorPicker>

              <Popover>
                <PopoverTrigger>
                  <Flex
                    flexDir="column"
                    h="3rem"
                    minW="3.1rem"
                    alignItems="center"
                    cursor="pointer"
                  >
                    <Flex color="black" fontWeight="bold" alignItems="flex-end">
                      <Text lineHeight="20px">{lineThickness}</Text>
                      <Text fontSize="12px">px</Text>
                    </Flex>
                    <Box
                      borderRadius="4px"
                      h={`${lineThickness}px`}
                      bg="black"
                      w="100%"
                    />
                  </Flex>
                </PopoverTrigger>
                <PopoverContent
                  width="300px"
                  sx={{
                    "&:focus": {
                      outline: "none",
                      boxShadow: "none",
                    },
                  }}
                >
                  <PopoverArrow />
                  <PopoverBody px="2rem">
                    <Slider
                      value={lineThickness}
                      min={1}
                      max={10}
                      step={1}
                      width="100%"
                      onChange={onChangeThickness}
                      onMouseEnter={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                    >
                      <SliderTrack bg="#D8D8D8">
                        <Box position="relative" right={10} />
                        <SliderFilledTrack bg="#3A3A3A" />
                      </SliderTrack>
                      <PCTooltip
                        hasArrow
                        bg="black"
                        color="white"
                        placement="top"
                        isOpen={showTooltip}
                        label={`${lineThickness}px`}
                      >
                        <SliderThumb boxSize={6} bg="#3A3A3A" />
                      </PCTooltip>
                    </Slider>

                    <Text mt="0.5rem" fontWeight="bold">
                      ラインの太さ
                    </Text>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </HStack>
          )}
        </Flex>
      </ModalContent>
    </Modal>
  );
}

export default DrawToolModal;
