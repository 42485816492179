import { Box, Flex, FlexProps, Text, useDisclosure } from "@chakra-ui/react";
import { SvgIcon } from "components/SvgIcon";
import CellTask from "components/ui/CellTask";
import { defaultAvatarPath } from "constants/file";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { UserDTO } from "interfaces/dtos/UserDTO";
import { Task } from "interfaces/models/task";
import isEqual from "lodash/isEqual";
import React, { memo, useCallback, useEffect, useMemo, useRef } from "react";

interface Props extends FlexProps {
  tasks: Task[];
  id: string;
  selectTaskId?: string;
  groupName: string;
  listUserById: { [key: string]: UserDTO };
  parentRef: React.MutableRefObject<HTMLElement | undefined>;
  mapTaskType: Map<string, string>;
}

const CellTaskList = memo(
  (props: {
    tasks: TaskDTO[];
    selectTaskId: string;
    listUserById: { [key: string]: UserDTO };
    parentRef: React.MutableRefObject<HTMLElement | undefined>;
    mapTaskType: Map<string, string>;
  }) => {
    const { tasks, selectTaskId, listUserById, parentRef, mapTaskType } = props;

    return (
      <>
        {tasks.map((task) => {
          return (
            <CellTask
              key={task.id}
              data={task}
              isSelected={selectTaskId === task.id}
              userAvatar={
                listUserById?.[task.userAssigned || ""]?.avatarBase64 ||
                defaultAvatarPath
              }
              name={mapTaskType?.get(task.taskTypeId || "") || "-"}
              parentRef={parentRef}
            />
          );
        })}
      </>
    );
  }
);

const TaskGroupDropDown = (props: Props) => {
  const {
    tasks,
    parentRef,
    mapTaskType,
    selectTaskId,
    id,
    groupName,
    listUserById,
  } = props;
  const { isOpen, onToggle } = useDisclosure();
  const documentGroupTitleContainerRef = useRef<HTMLDivElement>(null);
  const onClickToggle = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      onToggle();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onToggle]
  );
  const isSelected = !!selectTaskId;

  const handleClickGroup = useCallback(() => {
    onToggle();
  }, [onToggle]);

  useEffect(() => {
    if (!isOpen) {
      if (!!selectTaskId) {
        onToggle();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectTaskId]);

  return (
    <Box
      bgColor={isSelected ? "#F0F9FF" : "transparent"}
      width="100%"
      position="relative"
      boxShadow="0px 1px 2px 0px #0000004D"
      id={`doc-group-${id}`}
    >
      <Flex
        height="4.4rem"
        cursor="pointer"
        alignItems="center"
        width="100%"
        onClick={handleClickGroup}
      >
        <Flex w="4rem" h="4rem" alignItems="center" justifyContent="center">
          <SvgIcon
            src="/img/icon-expand-item-left-bar.svg"
            w="2rem"
            transition="0.1s"
            transform={isOpen ? "rotate(90deg)" : "rotate(0deg)"}
            className="button"
            onClick={onClickToggle as any}
          />
        </Flex>

        <Flex alignItems="center" gap="0.4rem" w="70%" flexGrow={2}>
          <Box ref={documentGroupTitleContainerRef} height="2.4rem" flex={1}>
            <Text
              width="fit-content"
              noOfLines={1}
              marginTop="-1px"
              fontSize="1.6rem"
              height="2.4rem"
              color={isSelected ? "#171717" : "#737373"}
              fontWeight={700}
            >
              {groupName}
            </Text>
          </Box>
        </Flex>
      </Flex>

      <Box display={isOpen ? "block" : "none"} position="relative">
        {isOpen && (
          <Box marginLeft="4rem" width="calc(100% - 4rem)">
            <CellTaskList
              tasks={tasks}
              selectTaskId={selectTaskId || ""}
              listUserById={listUserById}
              parentRef={parentRef}
              mapTaskType={mapTaskType}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default memo(TaskGroupDropDown);
