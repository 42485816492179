import useUserOfProject from "hooks/useUserOfProject";
import { Box, Button, Flex, Image, Text, Spinner } from "@chakra-ui/react";
import { IconBase } from "components/base";
import { withPresignedUrl } from "components/HOC/presignedUrl";
import { SYSTEM_NAME_JP_TEXT } from "constants/user";
import { UserDTO } from "interfaces/dtos/UserDTO";
import { DataLog } from "interfaces/models/dataLog";
import { PartnerCompany } from "interfaces/models/partnerCompany";
import { User } from "interfaces/models/user";
import { memo, useMemo } from "react";
import { DocumentLogTypeKey, ENTITY_NAME } from "utils/data-logs";
import { transformTextForDocumentLog } from "utils/data-logs/document-log";
import { formatDate } from "utils/date";
import { isDoc, isVideo } from "utils/file";
import { PreviewFileComponent } from "../TaskModal/Comment/PreviewFileComponent";

const ImagePresigned = withPresignedUrl(Image);

interface Props {
  log: DataLog;
  currentUser: User | null;
  user?: User;
  listUserById: { [key: string]: UserDTO | null };
  companiesById?: { [key: string]: PartnerCompany };
  mapDynamicFieldTitle: Record<
    string,
    { label: string; groupName?: string; dynamicFieldType: string }
  >;
  entityName?: ENTITY_NAME;
  documentType?: string;
  blackboardTemplateId?: string;
  onRevertDataByLog: (log: DataLog) => void;
}

const KEY_DISABLED_REVERT_LOGS = [
  DocumentLogTypeKey.CREATE_TASK,
  DocumentLogTypeKey.CREATE_SUB_ITEM,
  DocumentLogTypeKey.DELETE_SUB_ITEM,
];

const ItemLog = (props: Props) => {
  const {
    companiesById,
    log,
    currentUser,
    user,
    listUserById,
    mapDynamicFieldTitle,
    documentType,
    blackboardTemplateId,
    entityName,
    onRevertDataByLog,
  } = props;
  const { isFetchingUsers } = useUserOfProject();

  const logContent = useMemo(() => {
    const companyIds = Object.keys(companiesById || {});
    const mapPartnerCompany = companyIds.reduce((acc: any, id) => {
      acc[id] = companiesById?.[id]?.name;

      return acc;
    }, {});

    return transformTextForDocumentLog({
      entityName,
      log,
      user: currentUser,
      listUserById,
      mapDynamicFieldTitle,
      mapPartnerCompany,
      documentType,
      blackboardTemplateId,
    });
  }, [
    log,
    currentUser,
    listUserById,
    mapDynamicFieldTitle,
    companiesById,
    documentType,
    entityName,
    blackboardTemplateId,
  ]);

  if (!logContent?.logText) {
    return <></>;
  }

  return (
    <>
      <Flex
        data-id={log.id}
        alignItems="center"
        color="font.gray"
        py="0.5rem"
        fontSize="1rem"
        gap="1rem"
      >
        <Box fontSize="1rem" wordBreak="break-word">
          <Text as="b">
            {isFetchingUsers ? (
              <Spinner />
            ) : log?.value?.isCombine ? (
              SYSTEM_NAME_JP_TEXT
            ) : (
              user?.name
            )}
          </Text>
          {logContent?.logText}
          {log.createdAt ? formatDate(new Date(log.createdAt)) : ""}
        </Box>

        {log?.field && !KEY_DISABLED_REVERT_LOGS.includes(log.field as any) && (
          <Button
            p="0"
            variant="text"
            color="black"
            ml="auto"
            minHeight="2.4rem"
            isDisabled={!!logContent?.isDisabledRevert}
          >
            <IconBase
              icon="/img/icon-revert-data.svg"
              width="2rem"
              height="2rem"
              flexShrink={0}
              onClick={() => onRevertDataByLog?.(log)}
            />
          </Button>
        )}
      </Flex>

      {log?.value?.thumbnail && (
        <ImagePresigned
          maxH="62px"
          minW="60px"
          objectFit="cover"
          borderRadius="5px"
          src={log.value?.thumbnail}
          cursor="pointer"
          isPreviewImageOnNewTab={true}
        />
      )}

      {log?.field === "images" &&
        !!log?.value?.data?.src &&
        !log?.value?.thumbnail && (
          <ImagePresigned
            maxH="62px"
            minW="60px"
            objectFit="cover"
            borderRadius="5px"
            src={log?.value?.data?.src || ""}
            cursor="pointer"
            isPreviewImageOnNewTab={true}
          />
        )}

      <Flex gap="0.5rem" flexWrap="wrap">
        {log?.field === "attaches" &&
          (log.value?.data as string[])?.map((src, key) => {
            const fileName = new URL(src).pathname.substring(1);

            if (isDoc(fileName) || isVideo(fileName)) {
              return (
                <PreviewFileComponent
                  key={key}
                  onDelete={() => {}}
                  readonly={true}
                  file={{ src }}
                />
              );
            }

            return (
              <ImagePresigned
                key={key}
                maxH="62px"
                minW="60px"
                objectFit="cover"
                borderRadius="5px"
                src={src}
                cursor="pointer"
                isPreviewImageOnNewTab={true}
              />
            );
          })}
      </Flex>
    </>
  );
};

export default memo(ItemLog);
