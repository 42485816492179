import { Button, Flex } from "@chakra-ui/react";
import { IconBase } from "components/base";

interface Props {
  isCreateDocumentItem: boolean;
  isDisabled?: boolean;
  handleClickAddPin: (e: any) => void;
}

export const AddPin = ({
  isCreateDocumentItem,
  isDisabled,
  handleClickAddPin,
}: Props) => {
  return (
    <Flex borderTop="1px solid #D4D4D4" p="0.8rem 0 0.8rem 4rem">
      <Button
        color={isCreateDocumentItem ? "#009BE0" : "#737373"}
        lineHeight="1.6rem"
        fontSize="1.6rem"
        loadingText="写真を追加"
        variant="text"
        isDisabled={isDisabled}
        onClick={handleClickAddPin}
        leftIcon={<IconBase icon="/img/icon-plus-2.svg" />}
      >
        図面上にピンを追加
      </Button>
    </Flex>
  );
};
