import { bimFileApi, documentTaskApi } from "apiClient/v2";
import { message } from "components/base";
import { OPERATION } from "constants/task";
import { useQuery } from "hooks/useQuery";
import { Level } from "interfaces/models";
import { DocumentTask } from "interfaces/models/documentTask";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { setLevelSelected } from "redux/forgeViewerSlice";
import { RootState } from "redux/store";
import { getBimFileInfo } from "utils/bim";

const ShortenRoute = () => {
  const dispatch = useDispatch();
  const { settings } = useSelector((state: RootState) => state.user);

  const { id } = useParams();
  const query = useQuery();

  const [loading, setLoading] = useState(true);
  const [longUrl, setLongURL] = useState("");

  useEffect(() => {
    (async () => {
      setLoading(true);

      let dataTask: Partial<DocumentTask> = {};

      if (id) {
        dataTask = (await documentTaskApi.getDocumentTask(id)) || {};
      }
      const { id: documentTaskId, filterData } = dataTask;

      const { bimFileId } = query;

      if (!bimFileId) {
        return message.error("プロジェクトのIDが存在しません。");
      }

      const { data: project } = await bimFileApi.getProject(bimFileId!);
      const { version, projectId } = project;
      const filterDataJson = filterData || {};
      const levelSelected: Level = filterDataJson.levelSelected;

      dispatch(setLevelSelected(levelSelected));

      filterDataJson.levelSelected = {
        label: levelSelected.label,
        guid: levelSelected.guid,
      };

      const data = {
        filterData: JSON.stringify(filterDataJson),
        settings: {
          documentTaskIds: [documentTaskId],
        },
      };

      setLongURL(
        `/${projectId}/forge-viewer/${bimFileId}/${version}?operation=${
          OPERATION.ExportTask
        }&data=${JSON.stringify(data)}`
      );
      setLoading(false);
    })();
  }, [id, settings, dispatch, query]);

  if (loading) {
    return <></>;
  }

  return <Navigate to={longUrl} />;
};

export default ShortenRoute;
