import { Button, Flex } from "@chakra-ui/react";
import { Editor } from "@tiptap/react";
import { NumberInputFormat } from "components/input";
import Select from "components/input/Select";
import { SvgIcon } from "components/SvgIcon";
import {
  MAX_ZOOM_VALUE,
  MIN_ZOOM_VALUE,
  STEP_ZOOM_VALUE,
} from "constants/document";
import { DISPLAY_MODE } from "constants/forge";
import PreviewDocumentCategoryContext from "contexts/PreviewDocumentCategoryContext";
import { useAuthorization } from "hooks/usePermission";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import { getSheets } from "models/document";
import { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import PreviewDocumentToolbar from "./PreviewDocumentToolbar";

interface Props {
  isEditMode: boolean;
  isLoading: boolean;
  isSavingDocument: boolean;
  loadingPrintPdf: boolean;
  isDisableButton: boolean;
  isLoadingDownload: boolean;
  isLoadSpin: boolean;
  pageZoom: number;
  isShowSheetMenu: boolean;
  isContentFromS3: boolean | null;
  documentCategorySelected: DocumentCategoryDTO | undefined;
  editor: Editor | null;

  onClose: () => void;
  onChangeEditMode: (value: boolean) => void;
  onCancelEditMode: () => void;
  onDownload: (isContentFromS3: boolean) => void;
  onPrintPdf: (isContentFromS3: boolean) => Promise<void>;
  onChangeSelectedSheet: ((value: any) => void) | undefined;
  onChangePageZoom: (_: string, value: number) => void;
  onSaveEditor: () => Promise<void>;
}

const PreviewDocumentHeader = (props: Props) => {
  const {
    isContentFromS3,
    isShowSheetMenu,
    pageZoom,
    isLoadSpin,
    isLoadingDownload,
    isDisableButton,
    isEditMode,
    isLoading,
    isSavingDocument,
    loadingPrintPdf,
    documentCategorySelected,
    editor,
    onCancelEditMode,
    onClose,
    onChangeEditMode,
    onDownload,
    onPrintPdf,
    onChangeSelectedSheet,
    onChangePageZoom,
    onSaveEditor,
  } = props;
  const [sheetOptions, setSheetOptions] = useState<
    { name: string; value: string }[]
  >([]);

  const { levels } = useSelector((state: RootState) => state.forgeViewer);
  const { canEditDocumentItem } = useAuthorization();
  const { keynoteImageData } = useContext(PreviewDocumentCategoryContext);

  const hasSheets = useMemo(() => {
    const sheets =
      levels.find((level) => level.label === documentCategorySelected?.level)
        ?.sheets || [];

    return sheets?.length;
  }, [documentCategorySelected?.level, levels]);

  const groupSheetOptions = useMemo(() => {
    if (hasSheets) {
      return [{ title: "2D Sheets", options: sheetOptions }];
    }

    return [
      {
        title: "3D View",
        options: [
          {
            name: "3D View",
            value: DISPLAY_MODE["3D"],
          },
        ],
      },
      { title: "2D Sheets", options: sheetOptions },
    ];
  }, [sheetOptions, hasSheets]);

  useEffect(() => {
    (async () => {
      const sheets = await getSheets({ documentCategorySelected });

      setSheetOptions(
        sheets?.map(({ name, guid }) => ({
          name,
          value: guid,
        }))
      );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentCategorySelected?.level]);

  return (
    <Flex
      position="sticky"
      left="0px"
      top="0px"
      zIndex="modal"
      p="1.2rem 1.6rem"
      bgColor="#F2F2F2"
      flexWrap="wrap"
    >
      {isEditMode ? (
        <>
          <Flex flex="0 1 100%" alignItems="center">
            <Button
              mr="2rem"
              height="4rem"
              isDisabled={isLoading || isDisableButton}
              isLoading={isSavingDocument}
              onClick={onSaveEditor}
            >
              保存して終了
            </Button>
            <Button
              isDisabled={isLoading || isDisableButton}
              isLoading={isSavingDocument}
              height="4rem"
              variant="danger"
              onClick={onCancelEditMode}
            >
              変更を破棄
            </Button>
            <Button
              isDisabled={isLoading || isDisableButton || isSavingDocument}
              height="4rem"
              isLoading={loadingPrintPdf}
              onClick={() => {
                onPrintPdf(true);
              }}
              ml="auto!important"
            >
              印刷
            </Button>
          </Flex>

          <PreviewDocumentToolbar
            isDisableButton={isDisableButton || isSavingDocument}
            editor={editor}
            mt="2rem"
            flex="0 1 100%"
          />
        </>
      ) : (
        <Flex justifyContent="space-between" width="100%">
          <Flex>
            <SvgIcon
              src="/img/icon-navigation-chevron_left_light.svg"
              style={{
                alignSelf: "center",
                marginRight: ".8rem",
                cursor: "pointer",
              }}
              onClick={onClose}
            />

            <Button
              isDisabled={isDisableButton}
              variant="filled"
              height="4rem"
              onClick={() => {
                onDownload(!!isContentFromS3);
              }}
              isLoading={isLoadingDownload}
            >
              PDFダウンロード
            </Button>
            {canEditDocumentItem && (
              <Button
                isDisabled={isDisableButton}
                ml="1rem"
                height="4rem"
                onClick={() => {
                  onChangeEditMode(true);
                }}
              >
                書類情報の編集
              </Button>
            )}
            <Button
              isDisabled={isDisableButton}
              isLoading={loadingPrintPdf}
              onClick={() => {
                onPrintPdf(!!isContentFromS3);
              }}
              ml="1rem"
              height="4rem"
            >
              印刷
            </Button>
          </Flex>

          <Flex alignItems="center" gap="1rem" zIndex={1000}>
            {!isContentFromS3 && (
              <NumberInputFormat
                isDisabled={isLoadSpin || isDisableButton}
                disableTyping={true}
                border="1px solid #A3A3A3"
                w="11rem"
                height="4rem"
                unit="%表示"
                initValue={`${pageZoom}`}
                min={MIN_ZOOM_VALUE}
                max={MAX_ZOOM_VALUE}
                step={STEP_ZOOM_VALUE}
                onChangeStyle={onChangePageZoom}
                borderRadius="6px"
                sx={{
                  "& > input": {
                    border: "none !important",
                    borderRadius: "6px !important",
                  },
                }}
              />
            )}

            {isShowSheetMenu && (
              <Select
                isDisabled={isLoadSpin || isDisableButton}
                value={keynoteImageData.guid}
                // TODO: comment in if using 3D View in preview document
                groupOptions={groupSheetOptions}
                height="4rem"
                minW="24rem"
                maxW="24rem"
                zIndex="dropdown"
                showIcon
                onChange={onChangeSelectedSheet}
              />
            )}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default PreviewDocumentHeader;
