import { Box, Flex, Spinner, Text, Tooltip } from "@chakra-ui/react";
import { documentItemApi } from "apiClient/v2";
import { SvgIcon } from "components/SvgIcon";
import { SubItemKey, TypeFieldDynamic } from "constants/enum";
import useUserOfProject from "hooks/useUserOfProject";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { FamilyInstanceDTO } from "interfaces/dtos/familyInstance";
import { UserDTO } from "interfaces/dtos/UserDTO";
import { Level } from "interfaces/models";
import { Blackboard as IBlackboard } from "interfaces/models/blackboard";
import { DataLog } from "interfaces/models/dataLog";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import { DocumentTemplate } from "interfaces/models/documentTemplate";
import { GetContentLog, insertDataLogToIndexedDb } from "models/dataLog";
import { getColorTextByStatus } from "models/document";
import { TypeHandleInitData } from "pages/forge-viewer/hooks/useSupportSyncDataOffline";
import { memo, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { updateDocumentItem } from "redux/documentSlice";
import { sortArrayByField, updateElementInArray } from "utils/array";
import { transformBodyForCombineData } from "utils/offline";
import DynamicFields from "../DocumentCategoryModal/DynamicFields";
import Blackboard from "./Blackboard";
import FieldNoComponent from "./FieldNoComponent";
import { useSubItemLogs } from "./hooks";
import useRevertDataByLogs from "./hooks/useRevertDataByLogs";
import ItemLog from "./ItemLog";
import ItemTitle, { ItemTitleType } from "./ItemTitle";
import { useForgeViewerContext } from "pages/forge-viewer/ForgeViewerContext";

interface Props {
  users: { [key: string]: UserDTO };
  subItemSelected?: DocumentSubItemDTO;
  documentItemSelected?: DocumentItemDTO;
  documentTemplate?: DocumentTemplate;
  documentCategorySelected?: DocumentCategoryDTO;
  dataBlackboardDefault?: Partial<IBlackboard>;
  itemStatusColor: string;
  familyInstance: FamilyInstanceDTO;
  levelSelected?: Level;
  listOptionFloor?: { label: string; value: string }[];
  listOptionUsers?: { label: string; value: string }[];
  listPartnerCompanies?: { label: string; value: string }[];
  dataProjectDetail?: DataProjectModel;
  isDisabled?: boolean;
  projectName?: string;
  isLoadedBlackboard: boolean;
  dataBlackBoards: IBlackboard[];
  itemDisplayOrder: number | string;
  onClose: () => void;
}

const ModalTypeSubItem = (props: Props) => {
  const {
    users,
    subItemSelected,
    documentTemplate,
    documentItemSelected,
    documentCategorySelected,
    dataBlackboardDefault,
    itemStatusColor,
    familyInstance,
    levelSelected,
    listOptionFloor,
    listOptionUsers,
    listPartnerCompanies,
    projectName,
    isLoadedBlackboard,
    dataBlackBoards,
    itemDisplayOrder,
    isDisabled,
    onClose,
  } = props;

  const dispatch = useDispatch();
  const { currentUser, listUserById, listAllUserById } = useUserOfProject();
  const {
    mapDynamicFieldTitle,
    subItemLogs,
    isLoadMoreLogs,
    setSubItemLogs,
    webSocketMessages,
  } = useSubItemLogs({
    subItemId: subItemSelected?.id || "",
    documentTemplate,
  });
  const { socket } = useForgeViewerContext();
  const insertSubItemLog = useCallback(
    async (params: GetContentLog) => {
      if (!currentUser?.id || !subItemSelected?.id) {
        return;
      }

      const log = await insertDataLogToIndexedDb({
        contentLogParams: params,
        createdBy: currentUser.id,
        identifyValue: subItemSelected.id,
        identifyCol: "subItemId",
        type: "item",
      });

      if (log?.id) {
        socket.addSubItemLog(
          documentItemSelected?.level!,
          subItemSelected?.id,
          log
        );
        setSubItemLogs((prev) =>
          sortArrayByField<DataLog>([log, ...prev], "createdAt", false)
        );
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subItemSelected?.id, currentUser?.id, documentItemSelected?.level]
  );

  const { onRevertDataByLog } = useRevertDataByLogs({
    insertLog: insertSubItemLog,
    documentItemSelected,
    subItemSelected,
  });

  const handleSaveDynamicField = useCallback(
    async (item: DocumentItemDTO) => {
      if (!documentItemSelected?.id || !subItemSelected?.id) {
        return;
      }

      const now = new Date();
      const documentItem = structuredClone(documentItemSelected);
      updateElementInArray({
        array: documentItem?.subItems || [],
        keyIndex: SubItemKey.ID,
        element: {
          ...subItemSelected,
          data: { ...(subItemSelected?.data || {}), ...item.data },
          updatedAt: now,
        } as DocumentSubItemDTO,
      });

      documentItemApi.updateSubItem(
        transformBodyForCombineData<DocumentSubItemDTO>({
          body: {
            id: subItemSelected?.id,
            itemId: subItemSelected.itemId,
            data: item.data,
            mapTitleKey: item.mapTitleKey,
            mapDisplayValueKey: item.mapDisplayValueKey,
            mapDisplayLabelKey: item.mapDisplayLabelKey,
            mapDisplayValueTypeKey: item.mapDisplayValueTypeKey,
            updatedAt: now,
          } as DocumentSubItemDTO,
          bodyBefore: subItemSelected,
          typeInitData: TypeHandleInitData.SUB_ITEM,
        })
      );

      socket.updateSubItem(documentItem, subItemSelected, {
        data: item.data,
        updatedAt: now,
      });
      dispatch(updateDocumentItem(documentItem));
    },
    [documentItemSelected, subItemSelected, dispatch, socket]
  );

  const noSubItem = useMemo(
    () =>
      `${itemDisplayOrder}-${
        (documentItemSelected?.subItems?.findIndex(
          (sub) => sub.id === subItemSelected?.id
        ) || 0) + 1
      }`,
    [itemDisplayOrder, documentItemSelected?.subItems, subItemSelected?.id]
  );

  return (
    <Flex flexDirection="column" padding="0px 1.6rem" minHeight="100%">
      {subItemSelected?.isLoadingUpdateImage && (
        <Box
          position="absolute"
          inset="0px"
          backgroundColor="rgba(0,0,0,0.2)"
          zIndex={999}
        ></Box>
      )}

      {/* navigate */}
      <Flex alignItems="center" gap="3rem" mb="3.2rem">
        <SvgIcon
          onClick={onClose}
          cursor="pointer"
          flexShrink={0}
          src="/img/arrow-move.svg"
        />
        <Flex
          flexGrow={1}
          alignItems="center"
          gap="1rem"
          maxW={"calc(100% - 40px)"}
        >
          <Box flexGrow={"auto"}>
            <FieldNoComponent
              color={itemStatusColor}
              no={itemDisplayOrder}
              colorText={getColorTextByStatus(documentItemSelected?.status)}
            />
          </Box>
          <Box flexGrow={"auto"} maxW={"calc(100% - 40px)"}>
            <Tooltip label={documentItemSelected?.title}>
              <Text
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                fontSize="1.8rem"
                color="var(--primary-text-color)"
              >
                {documentItemSelected?.title}
              </Text>
            </Tooltip>
          </Box>
        </Flex>
      </Flex>

      {/* title */}
      <Flex gap="1rem" alignItems="center" mb="1.6rem">
        <SvgIcon
          flexShrink={0}
          src="/img/icon-camera-black.svg"
          width="2.4rem"
          height="2.4rem"
        />
        <Text
          fontSize="1.4rem"
          color="var(--primary-text-color)"
          flexShrink={0}
        >
          {noSubItem}
        </Text>
        <ItemTitle
          type={ItemTitleType.SUB_ITEM}
          item={documentItemSelected}
          subItem={subItemSelected}
          inputProps={{ isDisabled }}
          insertItemLog={insertSubItemLog}
        />
      </Flex>

      {/* photo */}
      <Box paddingLeft="3.4rem" mb="3.2rem">
        <Box
          padding="0.8rem"
          backgroundColor={
            subItemSelected?.images?.src || !isDisabled ? "#E8E8E8" : "#fff"
          }
          borderRadius="6px"
        >
          <Blackboard
            users={users}
            currentUser={currentUser}
            documentTemplate={documentTemplate}
            documentItemSelected={documentItemSelected}
            subItem={subItemSelected}
            documentCategorySelected={documentCategorySelected}
            dataBlackboardDefault={dataBlackboardDefault}
            familyInstance={familyInstance}
            levelSelected={levelSelected}
            listOptionFloor={listOptionFloor}
            listOptionUsers={listOptionUsers}
            listPartnerCompanies={listPartnerCompanies}
            projectName={projectName}
            isLoadedBlackboard={isLoadedBlackboard}
            dataBlackBoards={dataBlackBoards}
            isDisabled={isDisabled}
            webSocketMessages={webSocketMessages}
            insertSubItemLog={insertSubItemLog}
          />
        </Box>
      </Box>

      <DynamicFields
        type={TypeFieldDynamic.TYPE_ITEM}
        template={documentTemplate}
        documentItemSelected={subItemSelected as DocumentItemDTO}
        insertDocumentItemLog={insertSubItemLog}
        onSaveDataForDocumentItem={handleSaveDynamicField}
        isDisabled={isDisabled}
        marginBottom="3.2rem"
      />

      {/* logs */}
      <Box
        backgroundColor="#E8E8E8"
        ml="-1.6rem"
        mt="auto"
        width="calc(100% + 3.2rem)"
        padding="2rem 1.6rem"
      >
        <Text
          display="block"
          fontSize="1.6rem"
          color="var(--primary-text-color)"
          fontWeight="bold"
          mb="1.2rem"
        >
          変更履歴
        </Text>
        {subItemLogs?.map((item) => (
          <ItemLog
            key={item.id}
            log={item}
            user={listAllUserById?.[item?.createdBy || ""]}
            currentUser={currentUser}
            listUserById={listUserById}
            mapDynamicFieldTitle={mapDynamicFieldTitle}
            blackboardTemplateId={
              documentTemplate?.blackboardTemplateDetail?.id
            }
            onRevertDataByLog={onRevertDataByLog}
          />
        ))}
        {isLoadMoreLogs && (
          <Flex height="4rem" justifyContent="center" alignItems="center">
            <Spinner size="md" color="blue.500" />
          </Flex>
        )}
      </Box>
    </Flex>
  );
};

export default memo(ModalTypeSubItem);
