import { Box, BoxProps, Flex, Spinner } from "@chakra-ui/react";
import CollapseModalIcon, {
  useCollapseModal,
} from "components/ui/CollapseModalIcon";
import OfflineLabel from "components/ui/OfflineLabel";
import {
  DocumentCategoryStatusType,
  MapDocumentCategoryStatusTypeColor,
} from "constants/enum";
import {
  RIGHT_SIDEBAR_MODAL_CLASSNAME,
  RIGHT_SIDEBAR_MODAL_CONTAINER_ID,
} from "constants/styleProps";
import useFamilyInstance from "hooks/useFamilyInstance";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import {
  isCommissiontingTableTemplate,
  isEquipmentDataSheetTemplate,
  isPhotoLedgerTemplate,
  isSelfInspectionTemplate,
} from "models/documentCategory";
import { forwardRef, useImperativeHandle, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { ClickInfo } from "utils/forge/extensions/click-extension";
import FormItemDataSheet from "./FormItemDataSheet";
import FormItemPAC from "./FormItemPAC";
import FormItemPhotoLedger from "./FormItemPhotoLedger";
import FormItemSelfInspection from "./FormItemSelfInspection";
import useUserOfProject from "hooks/useUserOfProject";

export type DocumentItemModalHandleType = {
  handleCollapse: (val?: boolean) => void;
};

interface Props extends BoxProps {
  clickInfo?: ClickInfo;
  setClickInfo: React.Dispatch<React.SetStateAction<ClickInfo | undefined>>;
  filterDocumentCategories: DocumentCategoryDTO[];
}

const DocumentItemModal = forwardRef<DocumentItemModalHandleType, Props>(
  (props, ref) => {
    const { filterDocumentCategories, clickInfo, setClickInfo } = props;

    const boxContainerRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const { isCollapsed, setIsCollapsed } = useCollapseModal();
    const {
      documentCategories,
      documentCategorySelected,
      documentItemSelected,
      documentTemplates,
      isLoadedBlackboard,
      dataBlackboards,
      isLoadingDocument,
      dataLogTemp,
    } = useSelector((state: RootState) => state.document);
    const { familyInstances } = useFamilyInstance();

    const {
      levels,
      levelSelected,
      isLoadedViewer,
      isLoadedExternalId,
      isLoadedFamilyInstances,
    } = useSelector((state: RootState) => state.forgeViewer);

    const { currentUser } = useSelector((state: RootState) => state.user);

    const { listUserById, listAllUserById } = useUserOfProject();

    const { partnerCompanies, projectDetail } = useSelector(
      (state: RootState) => state.project
    );

    const { sizePanel, isOnline } = useSelector(
      (state: RootState) => state.app
    );

    const usersAssigned = useMemo(
      () => Object.values(listUserById),
      [listUserById]
    );

    const widthPanel = useMemo(
      () => `${sizePanel.width}${sizePanel.unit}`,
      [sizePanel]
    );

    const documentTemplate = useMemo(
      () => documentTemplates?.[documentCategorySelected?.templateId || ""],
      [documentCategorySelected?.templateId, documentTemplates]
    );

    const familyInstance = useMemo(
      () => familyInstances?.[documentItemSelected?.externalId || ""],
      [documentItemSelected?.externalId, familyInstances]
    );

    const itemStatusColor = useMemo(() => {
      const status = (documentItemSelected?.status ||
        DocumentCategoryStatusType.NotStarted) as DocumentCategoryStatusType;

      return MapDocumentCategoryStatusTypeColor[status];
    }, [documentItemSelected]);

    const itemDisplayOrder = useMemo(() => {
      if (!documentItemSelected?.id || !documentCategorySelected?.id) {
        return "";
      }
      const category = filterDocumentCategories.find(
        (cate) => cate.id === documentCategorySelected.id
      );
      if (!category) {
        return "";
      }

      return (
        (category?.documentItems || [])?.findIndex(
          (item) => item.id === documentItemSelected.id
        ) + 1
      );
    }, [
      documentItemSelected,
      documentCategorySelected,
      filterDocumentCategories,
    ]);

    useImperativeHandle(ref, () => ({
      handleCollapse: (val = true) => {
        if (val) {
          setIsCollapsed(true);
        } else {
          setIsCollapsed(false);
        }
      },
      onClosePreview: () => {},
    }));

    const handleCollapse = () => {
      setIsCollapsed((prev) => !prev);
    };

    return (
      <Box
        className={RIGHT_SIDEBAR_MODAL_CLASSNAME}
        w={isCollapsed ? "0" : widthPanel}
        position="absolute"
        right={0}
        height="100%"
        zIndex={11}
        transition="all 200ms ease-in-out 0s"
        boxShadow="0px 4px 8px 3px #00000026"
      >
        <Box
          ref={boxContainerRef}
          backgroundColor="white"
          p="0"
          height="100%"
          color="var(--primary-text-color)"
          position="relative"
        >
          <CollapseModalIcon
            backgroundColor="white"
            borderRadius="5px 0px 0px 5px"
            borderRightStyle="hidden"
            borderLeftStyle="solid"
            height="12rem"
            width="3.8rem"
            top="50%"
            iconProps={{
              transform: isCollapsed ? "rotate(90deg)" : "rotate(-90deg)",
            }}
            transform="translateY(-50%)"
            onClose={handleCollapse}
          />

          <Box
            id={RIGHT_SIDEBAR_MODAL_CONTAINER_ID}
            ref={containerRef}
            height="100%"
            overflowY="auto"
            width="100%"
            paddingTop={documentCategorySelected?.blockedBy ? "5rem" : "1.6rem"}
            gap="0.8rem"
          >
            {/* offline label */}
            {documentCategorySelected?.blockedBy && (
              <Box
                marginBottom="1.6rem"
                position="absolute"
                left="0"
                top="0"
                zIndex="sticky"
                width="100%"
              >
                <OfflineLabel
                  minHeight="3.6rem"
                  p="0.5rem 1.6rem"
                  blockedBy={documentCategorySelected?.blockedBy}
                />
              </Box>
            )}

            {documentItemSelected?.isLoadingUpdateImage && (
              <Box
                position="absolute"
                inset="0px"
                backgroundColor="rgba(0,0,0,0.2)"
                zIndex={999}
              ></Box>
            )}

            {isLoadingDocument ? (
              <Flex
                position="absolute"
                inset="0px"
                width="100%"
                height="100%"
                alignItems="center"
                justifyContent="center"
                zIndex="1000"
                backgroundColor="white"
              >
                <Spinner color="blue.500" size="lg" mt="1rem" />
              </Flex>
            ) : (
              <>
                {isPhotoLedgerTemplate(
                  documentCategorySelected?.documentType
                ) && (
                  <FormItemPhotoLedger
                    dataLogTemp={dataLogTemp}
                    isOnline={isOnline}
                    itemStatusColor={itemStatusColor}
                    familyInstance={familyInstance}
                    documentTemplate={documentTemplate}
                    documentItemSelected={documentItemSelected}
                    documentCategorySelected={documentCategorySelected}
                    documentCategories={documentCategories}
                    users={usersAssigned}
                    listAllUserById={listAllUserById}
                    listUserById={listUserById}
                    currentUser={currentUser}
                    levels={levels}
                    levelSelected={levelSelected}
                    partnerCompanies={partnerCompanies}
                    projectName={projectDetail.name}
                    isLoadedViewer={isLoadedViewer}
                    isLoadedBlackboard={isLoadedBlackboard}
                    dataBlackBoards={dataBlackboards}
                    itemDisplayOrder={itemDisplayOrder}
                  />
                )}

                {isEquipmentDataSheetTemplate(
                  documentCategorySelected?.documentType
                ) && (
                  <FormItemDataSheet
                    dataLogTemp={dataLogTemp}
                    isOnline={isOnline}
                    itemStatusColor={itemStatusColor}
                    isLoadedViewer={isLoadedViewer}
                    documentTemplate={documentTemplate}
                    documentItemSelected={documentItemSelected}
                    documentCategorySelected={documentCategorySelected}
                    itemDisplayOrder={itemDisplayOrder}
                  />
                )}

                {isSelfInspectionTemplate(
                  documentCategorySelected?.documentType
                ) && (
                  <FormItemSelfInspection
                    itemStatusColor={itemStatusColor}
                    isLoadedViewer={isLoadedViewer}
                    isLoadedExternalId={isLoadedExternalId}
                    isLoadedFamilyInstances={isLoadedFamilyInstances}
                    documentItemSelected={documentItemSelected}
                    documentCategorySelected={documentCategorySelected}
                    documentTemplate={documentTemplate}
                    clickInfo={clickInfo}
                    isOnline={isOnline}
                    itemDisplayOrder={itemDisplayOrder}
                    setClickInfo={setClickInfo}
                  />
                )}

                {isCommissiontingTableTemplate(
                  documentCategorySelected?.documentType
                ) && (
                  <FormItemPAC
                    dataLogTemp={dataLogTemp}
                    isOnline={isOnline}
                    itemStatusColor={itemStatusColor}
                    isLoadedViewer={isLoadedViewer}
                    documentItemSelected={documentItemSelected}
                    documentCategorySelected={documentCategorySelected}
                    documentTemplate={documentTemplate}
                    itemDisplayOrder={itemDisplayOrder}
                  />
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
    );
  }
);

export default DocumentItemModal;
