import { memo, useCallback, useContext, useMemo } from "react";
import {
  Button,
  Center,
  CenterProps,
  forwardRef,
  Popover,
  PopoverContent,
  PopoverTrigger,
  VStack,
  Divider,
} from "@chakra-ui/react";
import { PATH_ICONS } from "constants/file";
import { COLORS, HEX_TRANSPARENT } from "../constants";
import { ColorPickerMicrosoftContext } from "../context";
import MoreColorsModal from "./MoreColorsModal";
import SliderAlpha from "./SliderAlpha";
import IconBase from "components/base/icon";
import useClickAway from "hooks/useClickAway";
import ThemColors from "./ThemColors";

type PopoverColorsProps = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  size: string;
};

const PopoverColors = forwardRef<CenterProps & PopoverColorsProps, "div">(
  ({ isOpen, onClose, onOpen, size, ...props }, ref) => {
    const {
      colorPicker,
      onHandleChangeComplete,
      isOpenMoreColors,
      setIsOpenMoreColors,
    } = useContext(ColorPickerMicrosoftContext);

    const refPopover = useClickAway((e: any) => {
      e.preventDefault();
      e.stopPropagation();

      setTimeout(() => {
        onClose();
      }, 200);
    }) as any;

    const isTransparent = useMemo(
      () => colorPicker.color === HEX_TRANSPARENT,
      [colorPicker.color]
    );

    const onHandleSetColorPicker = useCallback(() => {
      onHandleChangeComplete({ color: HEX_TRANSPARENT, opacity: 0 });
    }, [onHandleChangeComplete]);

    const onHandleOpenMoreColors = useCallback(() => {
      setIsOpenMoreColors(true);
      onClose();
    }, [onClose, setIsOpenMoreColors]);

    const onHandleCloseMoreColors = useCallback(() => {
      setIsOpenMoreColors(false);
    }, [setIsOpenMoreColors]);

    return (
      <>
        <Popover
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          closeOnBlur={false}
          placement="bottom"
        >
          <PopoverTrigger>
            <Center
              id="more-colors"
              ref={ref}
              paddingInline="2px"
              height={size}
              borderTopEndRadius="4px"
              borderBottomEndRadius="4px"
              {...props}
            >
              <IconBase
                width="12px"
                height="12px"
                icon={PATH_ICONS.IconChevronDown}
              />
            </Center>
          </PopoverTrigger>
          {isOpen && (
            <PopoverContent
              ref={refPopover}
              backgroundClip="padding-box"
              boxShadow="0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);"
              background={COLORS.GRAY._3}
              width="auto"
              padding="1.4rem"
              cursor="default"
              _focus={{
                boxShadow:
                  "0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);",
              }}
            >
              <VStack spacing="8px" width="100%">
                <SliderAlpha />
                <ThemColors />
                <Divider />
                <Button
                  width="100%"
                  fontSize="1.2rem"
                  borderRadius="0px"
                  border={
                    isTransparent
                      ? `2px solid ${COLORS.GRAY._8}`
                      : "1px solid #A3A3A3"
                  }
                  onClick={onHandleSetColorPicker}
                >
                  塗りつぶしなし
                </Button>
                <Button
                  width="100%"
                  fontSize="1.2rem"
                  borderRadius="0px"
                  onClick={onHandleOpenMoreColors}
                >
                  <IconBase
                    icon={PATH_ICONS.IconPalette}
                    mr="8px"
                    width="16px"
                    height="16px"
                  />
                  色の設定
                </Button>
              </VStack>
            </PopoverContent>
          )}
        </Popover>
        <MoreColorsModal
          isOpen={isOpenMoreColors}
          onClose={onHandleCloseMoreColors}
        />
      </>
    );
  }
);

export default memo(PopoverColors);
