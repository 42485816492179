import {
  Center,
  Box,
  VStack,
  Tooltip,
  HStack,
  useDisclosure,
  BoxProps,
} from "@chakra-ui/react";
import IconBase from "components/base/icon";
import { PATH_ICONS } from "constants/file";
import useHover from "hooks/useHover";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getOpacityFromHex } from "utils/color";
import PopoverColors from "./components/PopoverColors";
import { COLORS, HEX_TRANSPARENT } from "./constants";
import {
  ColorPickerMicrosoftContext,
  DEFAULT_COLOR_PICKER_MICROSOFT,
  ParamsChangeComplete,
  StateColorPickerMicrosoft,
} from "./context";

type ColorPickerMicrosoftProps = {
  tooltip?: string;
  value?: string;
  onChangeComplete?: (color: string) => void;
  name?: string;
  size?: string;
} & BoxProps;

const ColorPickerMicrosoft: React.FC<ColorPickerMicrosoftProps> = ({
  tooltip,
  onChangeComplete,
  value,
  id,
  name,
  size = "38px",
  ...props
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const [refBoxChevronDown, hoveringBoxChevronDown] = useHover();
  const [refBoxPainBucket, hoveringBoxPainBucket] = useHover();

  const [colorPicker, setColorPicker] = useState<StateColorPickerMicrosoft>({
    ...DEFAULT_COLOR_PICKER_MICROSOFT,
  });
  const [isOpenMoreColors, setIsOpenMoreColors] = useState<boolean>(false);

  useEffect(() => {
    if (!isOpen) {
      const color = value ?? HEX_TRANSPARENT;
      const opacity = getOpacityFromHex(color) as number;

      setColorPicker({ ...colorPicker, color, opacity });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, isOpen]);

  const backgroundColorPickerMicrosoft: {
    bgBoxPaintBucket: string;
    bgBoxChevronDown: string;
  } = useMemo(() => {
    if (hoveringBoxChevronDown) {
      return {
        bgBoxPaintBucket: "#f0f0f0",
        bgBoxChevronDown: COLORS.GRAY._5,
      };
    }

    if (hoveringBoxPainBucket) {
      return {
        bgBoxPaintBucket: COLORS.GRAY._5,
        bgBoxChevronDown: COLORS.GRAY._3,
      };
    }

    return {
      bgBoxPaintBucket: "transparent",
      bgBoxChevronDown: "transparent",
    };
  }, [hoveringBoxChevronDown, hoveringBoxPainBucket]);

  const onHandleChangeComplete = useCallback(
    (color: ParamsChangeComplete) => {
      const colorPickerClone = { ...colorPicker, ...color };
      setColorPicker(colorPickerClone);
      onChangeComplete && onChangeComplete(color.color);
    },
    [colorPicker, onChangeComplete]
  );

  const onHandlePaintBucketClick = useCallback(() => {
    onChangeComplete && onChangeComplete(colorPicker.color);
  }, [onChangeComplete, colorPicker.color]);

  return (
    <ColorPickerMicrosoftContext.Provider
      value={{
        colorPicker,
        setColorPicker,
        onHandleChangeComplete,
        isOpenMoreColors,
        setIsOpenMoreColors,
      }}
    >
      <Tooltip
        isDisabled={isOpen || isOpenMoreColors}
        label={tooltip}
        id={id ?? name}
        bg="black"
        color="white"
      >
        <HStack cursor="pointer" spacing="0px" borderRadius="4px" {...props}>
          <Center
            onClick={onHandlePaintBucketClick}
            width={size}
            height={size}
            borderTopStartRadius="4px"
            borderEndStartRadius="4px"
            background={backgroundColorPickerMicrosoft.bgBoxPaintBucket}
            ref={refBoxPainBucket}
            border="1px solid"
            borderColor="border.default"
          >
            <VStack spacing="1px" width="100%">
              <IconBase
                icon={PATH_ICONS.IconPaintBucket}
                width="16px"
                height="16px"
              />
              {colorPicker.color === HEX_TRANSPARENT ? (
                <Box
                  backgroundImage={`url(${PATH_ICONS.IconTransparent})`}
                  width="72%"
                  height="5px"
                />
              ) : (
                <Box background={colorPicker.color} width="72%" height="5px" />
              )}
            </VStack>
          </Center>
          <PopoverColors
            size={size}
            border="1px solid"
            borderColor="border.default"
            borderLeft="none"
            onOpen={onOpen}
            onClose={onClose}
            isOpen={isOpen}
            ref={refBoxChevronDown}
            background={backgroundColorPickerMicrosoft.bgBoxChevronDown}
          />
        </HStack>
      </Tooltip>
    </ColorPickerMicrosoftContext.Provider>
  );
};

export default ColorPickerMicrosoft;
