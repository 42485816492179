import { Center, CenterProps, Tooltip } from "@chakra-ui/react";
import IconBase from "components/base/icon";
import { PATH_ICONS } from "constants/file";
import { memo, useCallback, useContext, useMemo } from "react";
import { getIsBrightColorFromHex } from "utils/color";
import { COLORS, HEX_TRANSPARENT } from "../constants";
import { ColorPickerMicrosoftContext } from "../context";

type BoxColorItemProps = {
  color: string;
} & CenterProps;

const BoxColorItem: React.FC<BoxColorItemProps> = ({ color, ...props }) => {
  const { colorPicker, onHandleChangeComplete } = useContext(
    ColorPickerMicrosoftContext
  );

  const isActive = useMemo(
    () =>
      colorPicker.color.includes(color) &&
      colorPicker.color !== HEX_TRANSPARENT,
    [colorPicker.color, color]
  );

  const isBrightColor = useMemo(() => {
    if (color.charAt(0) === "#") {
      return getIsBrightColorFromHex(color);
    }

    return false;
  }, [color]);

  const onHandleClick = useCallback(() => {
    onHandleChangeComplete({ color: `${color}FF`, opacity: 1 });
  }, [color, onHandleChangeComplete]);

  return (
    <Tooltip label={color} aria-label={color}>
      <Center
        key={color}
        width="18px"
        height="18px"
        background={color}
        border={
          isActive
            ? `2px solid ${COLORS.GRAY._8}`
            : `1px solid ${COLORS.GRAY._5}`
        }
        transition="all 0.3s"
        transform={`scale(${isActive ? 1.3 : 1})`}
        borderRadius="4px"
        cursor="pointer"
        _hover={{
          border: `2px solid ${COLORS.GRAY._8}`,
          transform: "scale(1.3)",
        }}
        onClick={onHandleClick}
        {...props}
      >
        {isActive && (
          <IconBase
            icon={PATH_ICONS.IconCheck}
            width="12px"
            height="12px"
            color={isBrightColor ? "black" : "white"}
          />
        )}
      </Center>
    </Tooltip>
  );
};

export default memo(BoxColorItem);
