import {
  blackboardApi,
  documentCategoryApi,
  documentItemApi,
} from "apiClient/v2";
import { message } from "components/base";
import {
  DocumentCategoryKey,
  DocumentCategoryStatusType,
  DocumentTemplateType,
  FamilyTitleDisplayType,
  ModalType,
  Priority,
  SystemModeType,
} from "constants/enum";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { FamilyInstanceDTO } from "interfaces/dtos/familyInstance";
import { Level } from "interfaces/models";
import { Blackboard } from "interfaces/models/blackboard";
import {
  FamilyTitleDisplayForCustomDocumentItem,
  TitleDisplay,
} from "interfaces/models/component";
import { DocumentItem, DocumentSubItem } from "interfaces/models/documentItem";
import { DocumentTemplate } from "interfaces/models/documentTemplate";
import { PartnerCompany } from "interfaces/models/partnerCompany";
import { User, UserSetting } from "interfaces/models/user";
import {
  FilterDocumentCategoryByUserSettingProps,
  filterDocumentItemsByUserSettting,
  getDataBlackboardDefault,
} from "models/document";
import {
  doMapDocumentCategories,
  isPhotoLedgerTemplate,
} from "models/documentCategory";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchBlackBoardByCategoryId,
  insertDocumentItemIdCreated,
  setDataBlackboard,
  setDocumentGroupSelected,
  setDocumentItemSelected,
  updateDocumentInfoOfTemplate,
} from "redux/documentSlice";
import {
  resetState,
  setIsCreateDocumentItem,
  setModalType,
} from "redux/forgeViewerSlice";
import store, { RootState } from "redux/store";
import { sleep, uuid } from "utils/common";
import { updateForgeWhenSelectCategory } from "utils/document";
import {
  getDocumentItemTitleByFamilyTitle,
  hightLightDocumentItem,
} from "utils/documentItem";
import useFamilyInstance from "hooks/useFamilyInstance";

import { useRoles } from "hooks/usePermission";
import { DocumentTemplateDTO } from "interfaces/dtos/documentTemplateDTO";
import { NeptuneArea } from "interfaces/models/area";
import { ClickInfo } from "utils/forge/extensions/click-extension";
import { getLabelExtension } from "utils/forge/extensions/custom-label";
import { arrayToObject } from "utils/object";
import { transformBodyForCombineData } from "utils/offline";
import { useForgeViewerContext } from "../ForgeViewerContext";
import useArea from "./useArea";
import { TypeHandleInitData } from "./useSupportSyncDataOffline";
import { getExternalIdByDbId } from "utils/forge/data";

interface iProps {
  clickInfo?: ClickInfo;
  filterDocumentCategoryOptions?: FilterDocumentCategoryByUserSettingProps;
  users?: User[];
  currentUser?: User | null;
  partnerCompanies?: PartnerCompany[];
  levels?: Level[];
  handleClickDocumentCategory: (documentCategory: DocumentCategoryDTO) => void;
  handleUpdateClickForgeInfo: (info?: ClickInfo) => void;
}

const useHandleAddDocumentItem = ({
  clickInfo,
  filterDocumentCategoryOptions,
  users,
  currentUser,
  partnerCompanies,
  levels,
  handleClickDocumentCategory,
  handleUpdateClickForgeInfo,
}: iProps) => {
  const dispatch = useDispatch();
  const { systemMode, isCreateDocumentItem, levelSelected } = useSelector(
    (state: RootState) => state.forgeViewer
  );
  const { familyInstances } = useFamilyInstance();
  const { socket } = useForgeViewerContext();
  const {
    documentCategorySelected,
    documentCategories,
    documentTemplates,
    documentItemSelected,
    documentItems,
  } = useSelector((state: RootState) => state.document);

  const { bimFileId } = useParams();
  const [isAddingDocumentItem, setIsAddingItem] = useState<boolean>(false);
  const { dataProjectDetail } = useSelector(
    (state: RootState) => state.project
  );
  const { areas: neptuneAreas } = useArea({ isHandleSideEffect: false });

  const isHighlightDocumentItemRef = useRef(false);
  const documentItemRef = useRef<DocumentItemDTO>();
  const documentCategorySelectedRef = useRef<DocumentCategoryDTO>();
  const dataBlackBoardDefaultRef = useRef<Partial<Blackboard> | undefined>();
  const { isTakasagoGroup } = useRoles();

  useEffect(() => {
    documentCategorySelectedRef.current = documentCategorySelected;
  }, [documentCategorySelected]);

  useEffect(() => {
    dataBlackBoardDefaultRef.current = getDataBlackboardDefault({
      levels: levels || [],
      currentUser: currentUser as any,
      users: users || [],
      partnerCompanies,
      currentLevel: documentCategorySelected?.level,
    });
  }, [
    documentCategorySelected?.level,
    currentUser,
    users,
    levels,
    partnerCompanies,
  ]);

  useEffect(() => {
    if (
      documentCategorySelected &&
      documentCategorySelected?.documentType ===
        DocumentTemplateType.SELF_INSPECTION
    ) {
      dispatch(setIsCreateDocumentItem(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentCategorySelected?.documentType]);

  useEffect(() => {
    if (isCreateDocumentItem) {
      dispatch(setIsCreateDocumentItem(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelSelected?.guid, dispatch, systemMode]);

  useEffect(() => {
    if (
      !clickInfo ||
      systemMode !== SystemModeType.Document ||
      !clickInfo.forgeData?.position ||
      !isCreateDocumentItem
    ) {
      return;
    }
    handleCreateDocumentItem();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickInfo]);

  // highlight document item when created
  useLayoutEffect(() => {
    if (
      !documentItemSelected ||
      documentItemSelected.id !== documentItemRef.current?.id ||
      !isHighlightDocumentItemRef.current
    ) {
      return;
    }

    (async () => {
      await sleep(1);
      hightLightDocumentItem(documentItemSelected);
      isHighlightDocumentItemRef.current = false;
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentItemSelected?.id]);

  const showMessageObjectSelectedInvalid = useCallback(() => {
    return message.warning(
      "選択されたオブジェクトに既にピンが存在しているので新たにピンを追加できません。"
    );
  }, []);

  const getDocumentItemPayload = useCallback(
    async (params: {
      category: DocumentCategoryDTO;
      documentTemplate: DocumentTemplate;
      familyInstance: FamilyInstanceDTO;
      isPhotoLedger: boolean;
      familyTitleOfDocumentItem: TitleDisplay;
      familyTitleOfDocumentSubItem?: TitleDisplay;
      areaIds: string[];
    }) => {
      const {
        category,
        documentTemplate,
        familyInstance,
        isPhotoLedger,
        familyTitleOfDocumentItem,
        familyTitleOfDocumentSubItem,
        areaIds,
      } = params;
      const currentFamiliesOfTemplate = documentTemplate?.families || [];
      const requestId = uuid();

      // handle document item title
      const isAddedFromForge =
        !familyInstance?.objectTypes?.every(
          (ob) => !!currentFamiliesOfTemplate.find((id) => ob.id === id)
        ) || !familyInstance?.objectTypes?.length;

      const documentItemTitle = getDocumentItemTitleByFamilyTitle({
        familyInstance,
        familyTitle: familyTitleOfDocumentItem,
      });

      let documentSubItemTitle = familyInstance?.symbol;
      if (familyTitleOfDocumentSubItem) {
        documentSubItemTitle = getDocumentItemTitleByFamilyTitle({
          familyInstance,
          familyTitle: familyTitleOfDocumentSubItem,
        });
      }

      // handle other field
      const blackboardTemplateId =
        documentTemplate?.blackboardTemplateDetail?.id;

      const body = {
        title: documentItemTitle,
        externalId: familyInstance.externalId,
        level: category.level,
        bimFileId,
        projectId: dataProjectDetail!.projectId,
        systemNames: familyInstance?.systemName
          ? [familyInstance.systemName]
          : [],
        position: clickInfo?.forgeData?.position!,
        documentCategoryId: category.id,
        templateId: category.templateId,
        documentType: documentTemplate?.documentType,
        objectTypes:
          familyInstance?.objectTypes?.map((family) => family.id) || [],
        data: {},
        userCreated: currentUser?.id,
        status: DocumentCategoryStatusType.NotStarted,
        priority: Priority.High,
        displayOrder: category?.documentItems?.length,
        isAddedFromForge,
        areaIds,
        requestId,
      } as Partial<DocumentItemDTO>;

      // create document item
      let documentItemData: DocumentItemDTO | undefined;
      const { data } = await documentItemApi.createItem(body as DocumentItem);
      documentItemData = data;
      if (!documentItemData) {
        return;
      }
      dispatch(insertDocumentItemIdCreated(documentItemData.id));

      // create sub item for photo ledger
      if (isPhotoLedger) {
        const bodySubItem: Partial<DocumentSubItemDTO> = {
          itemId: documentItemData.id,
          blackboardTemplateId: documentTemplate?.blackboardTemplateDetail?.id,
          isShowBlackboard: true,
          title: documentSubItemTitle,
          data: {},
          level: documentItemData?.level || "",
          templateId: documentItemData?.templateId || "",
          externalId: documentItemData?.externalId || "",
          bimFileId: documentItemData?.bimFileId || "",
          projectId: documentItemData?.projectId || "",
          requestId,
        };

        const res = await documentItemApi.createSubItem(
          bodySubItem as DocumentSubItem
        );

        const subItem = res?.data;
        const isCreateBlackboard =
          isPhotoLedger &&
          !!dataBlackBoardDefaultRef.current &&
          !!documentCategorySelectedRef.current?.id;
        if (isCreateBlackboard && isTakasagoGroup) {
          const dataBlackBoard = (
            await blackboardApi.createBlackboard({
              ...dataBlackBoardDefaultRef.current,
              subItemId: res.data.id,
              requestId,
            })
          )?.data;

          if (dataBlackBoard.id) {
            subItem.blackboardId = dataBlackBoard.id;
            dispatch(setDataBlackboard(dataBlackBoard));
          }
        }

        if (!documentItemData?.subItems?.length) {
          documentItemData.subItems = [];
        }

        if (subItem?.id) {
          documentItemData.subItems?.push(subItem as DocumentSubItemDTO);
        }

        documentItemData = {
          ...documentItemData,
          blackboardTemplateId,
          isShowBlackboard: true,
        };
      }

      return documentItemData;
    },
    [
      bimFileId,
      dataProjectDetail,
      clickInfo?.forgeData?.position,
      currentUser?.id,
      dispatch,
      isTakasagoGroup,
    ]
  );

  const handleCheckItemInsideArea = useCallback(
    (params: {
      currentCategory: DocumentCategoryDTO;
      neptuneAreas: NeptuneArea[];
      spaceId: string | undefined;
    }) => {
      const { currentCategory, neptuneAreas, spaceId } = params;
      const currentAreaIds = currentCategory?.neptuneAreaIds || [];
      const currentAreas = neptuneAreas.filter((area) =>
        currentAreaIds.includes(area.id)
      );
      const areaSelected = neptuneAreas.find(
        (item) =>
          item.externalIds.some((id) => spaceId === id) &&
          item.level === currentCategory.level
      );
      const areaIds = areaSelected ? [areaSelected.id] : [];

      const isInsideCurrentArea =
        currentAreas.some((area) =>
          area.externalIds.some((id) => spaceId === id)
        ) ||
        !!currentCategory?.allFloor ||
        (!spaceId && !!currentCategory.noArea);

      return { isInsideCurrentArea, areaIds };
    },
    []
  );

  const handleTransformTitleForItem = useCallback(
    (params: {
      familyInstance: FamilyInstanceDTO;
      documentTemplate: DocumentTemplateDTO;
      category: DocumentCategoryDTO;
    }) => {
      const { familyInstance, documentTemplate, category } = params;
      const currentFamiliesOfTemplate = documentTemplate?.families || [];
      const isAddedFromForge =
        !familyInstance?.objectTypes?.every(
          (ob) => !!currentFamiliesOfTemplate.find((id) => ob.id === id)
        ) || !familyInstance?.objectTypes?.length;
      let titleDisplayItem: TitleDisplay = {} as TitleDisplay;
      let titleDisplaySubItem: TitleDisplay = {} as TitleDisplay;
      const isPhotoLedger = isPhotoLedgerTemplate(category.documentType);

      if (isAddedFromForge) {
        const familyTitleDisplayForCustomDocumentItem: FamilyTitleDisplayForCustomDocumentItem =
          documentTemplate?.familyTitleDocItem || {};

        titleDisplayItem =
          familyTitleDisplayForCustomDocumentItem[
            FamilyTitleDisplayType.DocumentItem
          ];
        if (isPhotoLedger) {
          titleDisplayItem =
            familyTitleDisplayForCustomDocumentItem[
              FamilyTitleDisplayType.SubCategory
            ];
          titleDisplaySubItem =
            familyTitleDisplayForCustomDocumentItem[
              FamilyTitleDisplayType.DocumentItem
            ];
        }
      }

      if (!isAddedFromForge) {
        const mapObjectTypesById = arrayToObject(
          familyInstance?.objectTypes || [],
          "id"
        );
        let displayTypeItem =
          (documentTemplate?.familyTitle || {})?.[
            FamilyTitleDisplayType.DocumentItem
          ] || {};
        let objectTypeItem = Object.keys(displayTypeItem).filter(
          (key) => !!mapObjectTypesById?.[key]
        );

        titleDisplayItem = displayTypeItem?.[objectTypeItem?.[0] || ""];

        if (isPhotoLedger) {
          displayTypeItem =
            (documentTemplate?.familyTitle || {})?.[
              FamilyTitleDisplayType.SubCategory
            ] || {};
          const displayTypeSubItem =
            (documentTemplate?.familyTitle || {})?.[
              FamilyTitleDisplayType.DocumentItem
            ] || {};
          objectTypeItem = Object.keys(displayTypeItem).filter(
            (key) => !!mapObjectTypesById?.[key]
          );
          const objectTypeSubItem = Object.keys(displayTypeSubItem).filter(
            (key) => !!mapObjectTypesById?.[key]
          );

          titleDisplayItem = displayTypeItem?.[objectTypeItem?.[0] || ""];
          titleDisplaySubItem =
            displayTypeSubItem?.[objectTypeSubItem?.[0] || ""];
        }
      }

      return { titleDisplayItem, titleDisplaySubItem };
    },
    []
  );

  // handle add document item for sleeve pipe template
  const handleCreateDocumentItem = useCallback(async () => {
    if (isAddingDocumentItem) {
      return;
    }

    try {
      setIsAddingItem(true);
      if (!documentCategorySelectedRef.current) {
        setIsAddingItem(false);

        return showMessageObjectSelectedInvalid();
      }
      let currentCategory = structuredClone(
        documentCategorySelectedRef.current
      );
      const documentTemplate = documentTemplates?.[currentCategory?.templateId];
      if (!documentTemplate) {
        setIsAddingItem(false);
        handleUpdateClickForgeInfo(undefined);

        return message.error(["ピンの追加", "テンプレートがありません。"]);
      }
      const spaceId = clickInfo?.areaData?.externalId;
      const dbId = clickInfo?.forgeData?.dbId;
      const externalId = dbId
        ? (await getExternalIdByDbId(dbId)) || uuid()
        : uuid();

      // check point inside area
      const { isInsideCurrentArea, areaIds } = handleCheckItemInsideArea({
        currentCategory,
        neptuneAreas,
        spaceId,
      });
      if (!isInsideCurrentArea) {
        setIsAddingItem(false);
        handleUpdateClickForgeInfo(undefined);

        return message.warning(
          "ピンは検査対象工区外であるため追加できません。"
        );
      }

      // handle recovery item
      const isPhotoLedger = isPhotoLedgerTemplate(
        currentCategory?.documentType
      );

      let familyInstance: FamilyInstanceDTO | undefined =
        familyInstances[externalId || ""];
      if (!familyInstance || dbId === undefined) {
        familyInstance = {
          externalId,
        } as FamilyInstanceDTO;
      }

      // handle transform title for item, sub item
      const { titleDisplayItem, titleDisplaySubItem } =
        handleTransformTitleForItem({
          familyInstance,
          category: currentCategory,
          documentTemplate,
        });

      let documentItemEps: DocumentItem[] = [];
      const documentItemPayload = await getDocumentItemPayload({
        category: currentCategory,
        documentTemplate,
        familyInstance,
        isPhotoLedger,
        familyTitleOfDocumentItem: titleDisplayItem,
        familyTitleOfDocumentSubItem: titleDisplaySubItem,
        areaIds,
      });

      documentItemEps = documentItemPayload ? [documentItemPayload] : [];

      if (!documentItemEps.length) {
        setIsAddingItem(false);

        return message.error(["ピンの追加", "ピンの追加に失敗しました。"]);
      }
      socket.addDocItems(currentCategory.level!, documentItemEps);

      const templateDocumentItems = documentItems.filter(
        (item) => item.templateId === currentCategory.templateId
      );
      const templateCategories = documentCategories.filter(
        (item) =>
          item.templateId === currentCategory.templateId &&
          item.level === currentCategory.level
      );

      const allDocumentItems = structuredClone([
        ...(templateDocumentItems || []),
        ...documentItemEps,
      ]);

      const documentIds: string[] = [];
      documentItemEps.forEach((item) => {
        if (item.id) {
          documentIds.push(item.id);
        }
      });

      const mapTemplateCategoriesById = arrayToObject(
        templateCategories,
        DocumentCategoryKey.ID
      );
      const categoryUpdatedAt = new Date();
      const allDocumentCategories = (templateCategories || []).map(
        (category) => {
          const selectedExternalIds = [
            ...new Set([
              ...(category.selectedExternalIds || []),
              ...documentIds,
            ]),
          ];
          const itemIds = [
            ...new Set([...(category?.itemIds || []), ...documentIds]),
          ];

          return {
            ...category,
            itemIds,
            updatedAt: categoryUpdatedAt,
            selectedExternalIds,
          };
        }
      );

      const {
        documentCategories: newDocumentCategories,
        documentItems: newDocumentItems,
      } = doMapDocumentCategories({
        documentCategories: allDocumentCategories,
        documentItems: allDocumentItems,
        familyInstances,
        documentTemplates: [documentTemplate],
      });

      Promise.all(
        newDocumentCategories.map((category) => {
          return documentCategoryApi.updateCategory(
            transformBodyForCombineData<DocumentCategoryDTO>({
              body: {
                id: category.id,
                itemIds: category.itemIds,
                selectedExternalIds: category.selectedExternalIds,
                updatedAt: categoryUpdatedAt,
              } as DocumentCategoryDTO,
              bodyBefore: mapTemplateCategoriesById[category.id],
              typeInitData: TypeHandleInitData.DOCUMENT_CATEGORY,
            })
          );
        })
      );

      dispatch(
        updateDocumentInfoOfTemplate({
          documentCategories: newDocumentCategories,
          documentItems: newDocumentItems,
        })
      );

      const lengthDocumentItems = currentCategory?.documentItems?.length;
      currentCategory = newDocumentCategories?.find(
        (item) => item.id === currentCategory.id
      )!;

      const documentItemShouldSelected = currentCategory?.documentItems?.find(
        (item) => item.id === documentItemEps?.[0]?.id
      );

      documentItemRef.current = documentItemShouldSelected;

      const settings =
        filterDocumentCategoryOptions?.settings || ({} as UserSetting);
      const searchDocumentValue =
        filterDocumentCategoryOptions?.searchDocumentValue || "";
      const areas = filterDocumentCategoryOptions?.areas || [];

      if (
        isPhotoLedger &&
        currentCategory?.documentItems?.length === lengthDocumentItems
      ) {
        message.success(["写真の追加", "写真を追加しました。"]);
      } else {
        message.success(["ピンの追加", "ピンを追加しました。"]);
      }

      const documentItemsFilter = filterDocumentItemsByUserSettting({
        documentItems: documentItemEps,
        documentCategory: currentCategory,
        areas,
        settings,
        searchDocumentValue,
        documentTemplates,
        documentCategories: [currentCategory],
        isIgnoreSearchDocument: !!currentCategory.templateParentId,
      });

      if (documentItemsFilter?.length) {
        isHighlightDocumentItemRef.current = true;
        dispatch(setDocumentItemSelected(documentItemShouldSelected));
        dispatch(setModalType(ModalType.DOC_ITEM));
      }

      setIsAddingItem(false);
      handleUpdateClickForgeInfo(undefined);
    } catch (e) {
      setIsAddingItem(false);
      handleUpdateClickForgeInfo(undefined);
      isHighlightDocumentItemRef.current = false;
    }
  }, [
    isAddingDocumentItem,
    documentTemplates,
    clickInfo?.areaData?.externalId,
    clickInfo?.forgeData?.dbId,
    handleCheckItemInsideArea,
    neptuneAreas,
    familyInstances,
    handleTransformTitleForItem,
    getDocumentItemPayload,
    socket,
    documentItems,
    documentCategories,
    dispatch,
    filterDocumentCategoryOptions?.settings,
    filterDocumentCategoryOptions?.searchDocumentValue,
    filterDocumentCategoryOptions?.areas,
    handleUpdateClickForgeInfo,
    showMessageObjectSelectedInvalid,
  ]);

  const onClickAddDocumentItem = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement>,
      documentCategory: DocumentCategoryDTO
    ) => {
      e.stopPropagation();
      e.preventDefault();
      const state = store.getState();
      const { isCreateDocumentItem } = state.forgeViewer;
      const {
        documentItemSelected,
        documentCategorySelected,
        documentGroupSelected,
      } = state.document;
      const isDiffDocumentCategory =
        documentCategorySelected?.id !== documentCategory.id;

      if (documentItemSelected?.id) {
        dispatch(resetState());
        dispatch(setDocumentItemSelected());
        getLabelExtension()?.setSelectedItems([]);
        dispatch(setModalType(ModalType.DOC_CATEGORY));
        if (!isDiffDocumentCategory) {
          updateForgeWhenSelectCategory(documentCategory);
        }
      }

      if (isDiffDocumentCategory) {
        dispatch(setIsCreateDocumentItem(true));
        handleClickDocumentCategory(documentCategory);
      } else {
        dispatch(setIsCreateDocumentItem(!isCreateDocumentItem));
      }
      if (documentGroupSelected) {
        dispatch(setDocumentGroupSelected());
      }

      if (
        isPhotoLedgerTemplate(documentCategory?.documentType) &&
        isDiffDocumentCategory
      ) {
        dispatch(fetchBlackBoardByCategoryId(documentCategory.id));
      }
    },
    [dispatch, handleClickDocumentCategory]
  );

  const onCloseAddDocumentItem = useCallback(() => {
    dispatch(setIsCreateDocumentItem(false));
  }, [dispatch]);

  return {
    onClickAddDocumentItem,
    isAddingDocumentItem,
    onCloseAddDocumentItem,
    isCreateDocumentItem,
  };
};

export default useHandleAddDocumentItem;
