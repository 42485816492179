import {
  Box,
  CloseButton,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import { IconBase } from "components/base";
import { PCTooltip } from "components/PCTooltip";
import { useCallback, useRef } from "react";
import { ReactMic, ReactMicStopEvent } from "react-mic";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

interface Props {
  onResult: (data: ReactMicStopEvent) => void;
}

function RecordAudio({ onResult }: Props) {
  const { isOnline } = useSelector((state: RootState) => state.app);
  const { isOpen: record, onOpen, onClose: stopRecord } = useDisclosure();
  const isSave = useRef(false);

  const startRecord = () => {
    if (!isOnline) {
      return;
    }

    onOpen();
    isSave.current = false;
  };

  const onStopRecord = useCallback(
    (data: ReactMicStopEvent) => {
      if (!isOnline) {
        return;
      }
      if (isSave.current) {
        onResult(data);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOnline]
  );

  const onSaveRecord = () => {
    isSave.current = record;
  };

  return (
    <Popover
      isOpen={record && isOnline}
      onOpen={startRecord}
      onClose={stopRecord}
      // closeOnBlur={false}
    >
      <PopoverTrigger>
        <Box tabIndex={0} role="button" className="button" mr="4px">
          <PCTooltip label={record ? "Stop recording" : "Record audio clip"}>
            <IconButton
              color="icon.default"
              variant="text"
              icon={
                <IconBase
                  width="1.8rem"
                  height="1.8rem"
                  icon={record ? "/img/check.svg" : "/img/icon-mic.svg"}
                />
              }
              aria-label="Record audio clip"
              isDisabled={!isOnline}
              onClick={onSaveRecord}
            />
          </PCTooltip>
        </Box>
      </PopoverTrigger>
      <PopoverContent w="unset" _focus={{}} boxShadow="0px 3px 6px #00000029">
        <PopoverArrow />
        <PCTooltip label="Cancel recording" placement="auto-start">
          <CloseButton
            size="sm"
            onClick={stopRecord}
            right={"-1rem"}
            top={"-1rem"}
            position="absolute"
            borderRadius="50%"
            bgColor="#fff"
            boxShadow="0px 3px 6px #00000029"
            _hover={{}}
          />
        </PCTooltip>

        <PopoverBody>
          <ReactMic
            record={record}
            className="sound-wave"
            strokeColor="#1795FE"
            visualSetting="sinewave"
            width={150}
            height={50}
            onStop={onStopRecord}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default RecordAudio;
