import { Text } from "@chakra-ui/react";
import { DEFAULT_OPTION_WEATHER } from "constants/document";
import { CellPropertyDocumentDataForTableOptions } from "constants/documentTemplate";
import {
  DocumentCategoryKey,
  DocumentGroupKey,
  DocumentItemKey,
  LinkedDynamicFieldsType,
  MapDocumentCategoryStatusType,
  MapExtractionInspectionResultType,
  SubItemKey,
} from "constants/enum";
import { PARTNER_ROLES } from "constants/user";
import dayjs from "dayjs";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";

import { UserDTO } from "interfaces/dtos/UserDTO";
import { DataLog } from "interfaces/models/dataLog";
import { DocumentGroup } from "interfaces/models/documentGroup";
import { User } from "interfaces/models/user";
import { isSelfInspectionTemplate } from "models/documentCategory";
import { ReactNode } from "react";
import { getLocalStorageUser } from "utils/user";
import {
  ACTION_CHANGE_DATA,
  DocumentLogTypeKey,
  ENTITY_NAME,
  getCommonFormatLog,
  getDateDisplay,
  getTextChangeImage,
  KeyMapCommonDocumentLogType,
  MapCommonDocumentLogType,
  MapTitleForDocument,
  MapTitleForDocumentGroup,
  MapTitleForTask,
} from ".";
import { DATE_FORMAT, formatDate } from "../date";

const FIELDS_TYPE_DOCUMENT_SINGLE_USER = [
  DocumentCategoryKey.USER_IMPLEMENT,
  DocumentCategoryKey.USER_MEASURE,
  DocumentCategoryKey.USER_MANAGE,
  DocumentCategoryKey.MANAGER,
];

const MEDIA_KEYS = [SubItemKey.ATTACHES, SubItemKey.IMAGES];

const FIELDS_TYPE_DOCUMENT_MULTIPLE_USERS = [DocumentCategoryKey.USER_ASSIGNED];

const FIELDS_TYPE_DOCUMENT_DATE = [
  DocumentCategoryKey.APPROVE_DATE,
  DocumentCategoryKey.INSPECTION_DATE,
  DocumentCategoryKey.MEASURE_DATE,
  DocumentCategoryKey.DEADLINE,
  DocumentCategoryKey.IMPLEMENT_DATE,
];

const FIELDS_TYPE_DOCUMENT_OPTION: (
  | keyof DocumentSubItemDTO
  | keyof DocumentCategoryDTO
  | keyof DocumentItemDTO
)[] = [
  DocumentCategoryKey.PARTNER_COMPANY,
  DocumentCategoryKey.USER_ASSIGNED,
  DocumentCategoryKey.USER_IMPLEMENT,
  DocumentCategoryKey.USER_MEASURE,
  DocumentCategoryKey.USER_MANAGE,
  DocumentCategoryKey.MANAGER,
  DocumentCategoryKey.DATA,
];

const checkFieldTypeSingleUser = (field: string) =>
  FIELDS_TYPE_DOCUMENT_SINGLE_USER.includes(field as any);
const checkFieldTypeMultipleUsers = (field: string) =>
  FIELDS_TYPE_DOCUMENT_MULTIPLE_USERS.includes(field as any);
const checkFieldTypeDate = (field: string) =>
  FIELDS_TYPE_DOCUMENT_DATE.includes(field as any);

const getActionLogChangeImageForDoc = (log: DataLog) => {
  const isEditMedia = !!log.value?.isEditMedia;
  const isDeleteImage = !log.value?.data || !!log?.value?.isDeleteMedia;
  const isAddImage = !isEditMedia && !isDeleteImage;

  let action = "";
  if (isAddImage) {
    action = ACTION_CHANGE_DATA.REGISTER;
  } else if (isEditMedia) {
    action = ACTION_CHANGE_DATA.EDIT;
  } else if (isDeleteImage) {
    action = ACTION_CHANGE_DATA.DELETE;
  }

  return action;
};

const processTitleForDynamicField = (params: {
  mapDynamicFieldTitle: Record<
    string,
    { label: string; groupName?: string; dynamicFieldType: string }
  >;
  listUserById: { [key: string]: UserDTO | null };
  value: Object;
  displayLabel?: string;
  displayValueType?: string;
}) => {
  const {
    displayValueType,
    displayLabel,
    value,
    listUserById,
    mapDynamicFieldTitle,
  } = params;
  const cellId = Object.keys((value || {}) as Object)
    .at(0)
    ?.split("-")
    .shift();
  const dynamicFieldTitle = mapDynamicFieldTitle[cellId || ""];
  const dynamicFieldLabel = dynamicFieldTitle?.label;

  // will get previously saved type or current type in template
  const dynamicFieldType =
    displayValueType || mapDynamicFieldTitle[cellId || ""]?.dynamicFieldType;

  let groupName = dynamicFieldTitle?.groupName;
  if (groupName) {
    groupName = `${groupName}の`;
  }
  let labelOfField = `${groupName || ""}${dynamicFieldLabel}`;
  if (displayLabel) {
    labelOfField = displayLabel;
  }

  if (!labelOfField || !dynamicFieldType) {
    return { labelOfField: undefined, displayValue: undefined };
  }

  let displayValue = Object.values((value || {}) as Object).at(0) || "";
  switch (dynamicFieldType) {
    case LinkedDynamicFieldsType.USER_DROPDOWN:
      displayValue = listUserById[displayValue]?.name || "";
      break;

    case LinkedDynamicFieldsType.WEATHER:
      displayValue =
        DEFAULT_OPTION_WEATHER.find((opt) => opt.value === displayValue)
          ?.name || "";
      if (!displayValue) {
        return;
      }
      break;

    case LinkedDynamicFieldsType.DATE_TIME:
      displayValue = dayjs(displayValue).isValid()
        ? formatDate(displayValue, DATE_FORMAT)
        : "";
      break;

    case LinkedDynamicFieldsType.MEASURE:
      displayValue = ((displayValue?.values || []) as string[])
        .filter((i) => !!i)
        .join(",");

      break;

    default:
      break;
  }

  return {
    labelOfField,
    displayValue,
    dynamicFieldLabel: labelOfField,
    // Returns only the type of the template side
    dynamicFieldType: mapDynamicFieldTitle[cellId || ""]?.dynamicFieldType,
  };
};

type ParamTransformTextForDocumentLog = {
  log: DataLog;
  user: User | null;
  listUserById: { [key: string]: UserDTO | null };
  mapPartnerCompany: { [key: string]: string };
  mapDynamicFieldTitle: Record<
    string,
    { label: string; groupName?: string; dynamicFieldType: string }
  >;
  documentType?: string;
  entityName?: ENTITY_NAME;
  blackboardTemplateId?: string;
};

export const getCustomTitleAndValueChangeForDoc = (
  params: ParamTransformTextForDocumentLog
) => {
  const {
    documentType,
    log,
    listUserById,
    mapPartnerCompany,
    mapDynamicFieldTitle,
  } = params;
  const field = log.field as
    | keyof DocumentCategoryDTO
    | keyof DocumentItemDTO
    | keyof DocumentSubItemDTO
    | keyof DocumentGroup;
  const data = structuredClone(log.value?.data);
  let customTitle;
  let dynamicFieldLabel;
  let dynamicFieldType;
  let displayValue = data;
  switch (field) {
    case DocumentGroupKey.NAME:
      customTitle = MapTitleForDocumentGroup.name?.title;
      break;
    case SubItemKey.IS_SHOW_BLACKBOARD:
      displayValue = data ? "ON" : "OFF";
      break;

    case SubItemKey.MEMO:
      if (isSelfInspectionTemplate(documentType) && log.subItemId) {
        customTitle = MapTitleForDocument.treatmentContent?.title;
      }
      break;

    case SubItemKey.ATTACHES:
    case SubItemKey.IMAGES:
      displayValue = "";
      break;

    case DocumentItemKey.IS_HIDE_PIN:
      displayValue = data ? "OFF" : "ON";
      break;

    case DocumentCategoryKey.STATUS:
      if (isSelfInspectionTemplate(documentType) && log.subItemId) {
        customTitle = MapTitleForDocument.checkbox?.title;
        displayValue = data === "1" ? "ON" : "OFF";
      } else {
        displayValue =
          MapDocumentCategoryStatusType[
            data as keyof typeof MapDocumentCategoryStatusType
          ];
        customTitle = MapTitleForDocument.status?.title;
      }
      break;
    case DocumentCategoryKey.DATA:
      const titleDynamicField = processTitleForDynamicField({
        value: data,
        displayLabel: log.value?.displayLabel,
        displayValueType: log.value?.displayValueType,
        listUserById,
        mapDynamicFieldTitle,
      });
      if (!titleDynamicField?.labelOfField) {
        return;
      }
      customTitle = titleDynamicField.labelOfField;
      displayValue = titleDynamicField.displayValue;
      dynamicFieldLabel = titleDynamicField?.dynamicFieldLabel;
      dynamicFieldType = titleDynamicField?.dynamicFieldType;

      break;

    case DocumentCategoryKey.INSPECTION_RESULT:
      displayValue =
        MapExtractionInspectionResultType[
          data as keyof typeof MapExtractionInspectionResultType
        ];
      break;

    case DocumentCategoryKey.PARTNER_COMPANY:
      displayValue = mapPartnerCompany?.[data || ""] || "";
      break;

    case DocumentCategoryKey.TAGS:
      displayValue = ((data || []) as string[]).join(",");
      break;

    default:
      if (checkFieldTypeSingleUser(field)) {
        displayValue = listUserById[data]?.name || "";
      }

      if (checkFieldTypeMultipleUsers(field)) {
        displayValue = ((data || []) as string[])
          .sort()
          .map((id) => listUserById?.[id || ""]?.name || "")
          .filter((i) => !!i)
          .join(",");
      }
      if (checkFieldTypeDate(field)) {
        displayValue = getDateDisplay(data);
      }

      break;
  }
  //@ts-ignore
  if (!MEDIA_KEYS.includes(field)) {
    displayValue = displayValue || "";
  }

  return { displayValue, customTitle, dynamicFieldLabel, dynamicFieldType };
};

const checkDisabledRevertDocumentLog = (params: {
  log: DataLog;
  blackboardTemplateId?: string;
  changeValue?: string;
  dynamicFieldLabel?: string;
  dynamicFieldType?: string;
}) => {
  const {
    log,
    blackboardTemplateId,
    changeValue,
    dynamicFieldLabel,
    dynamicFieldType,
  } = params;
  const field = log.field;
  const displayValue = log.value?.displayValue;
  const displayValueType = log.value?.displayValueType;
  const displayLabel = log.value?.displayLabel;

  if (MapCommonDocumentLogType[field as KeyMapCommonDocumentLogType]) {
    switch (field) {
      case DocumentLogTypeKey.BLACKBOARD:
        return blackboardTemplateId !== log.value?.blackboardTemplateId;
      default:
        return false;
    }
  }

  let isDisabledRevert =
    displayValue !== undefined && displayValue !== changeValue;

  if (
    field === DocumentCategoryKey.DATA &&
    ((displayLabel && displayLabel !== dynamicFieldLabel) ||
      (displayValueType && displayValueType !== dynamicFieldType))
  ) {
    isDisabledRevert = true;
  } else if (
    field === DocumentItemKey.IMAGES &&
    log.value?.blackboardImagePosition
  ) {
    isDisabledRevert = blackboardTemplateId !== log.value.blackboardTemplateId;
  }

  return isDisabledRevert;
};

export const checkPermissionRevertLog = (user: User | null) => {
  const isPartnerRole = PARTNER_ROLES.includes(user?.role as any);

  return !isPartnerRole;
};

export const transformTextForDocumentLog = (
  params: ParamTransformTextForDocumentLog
) => {
  const { user, log, blackboardTemplateId, entityName } = params;
  const field = log.field;
  if (!field) {
    return { logText: <Text></Text>, isRevert: false };
  }
  const data = log.value?.data;
  const displayValue = log.value?.displayValue;
  const isRevertDataByLog = !!log?.value?.isRevertDataByLog;
  let logText: ReactNode = "";
  let isRevert = true;
  let isDisabledRevert = false;
  if (MapCommonDocumentLogType[field as KeyMapCommonDocumentLogType]) {
    //@ts-ignore
    const { title, action } = MapCommonDocumentLogType[field];
    isRevert = field === DocumentLogTypeKey.BLACKBOARD;
    isDisabledRevert = checkDisabledRevertDocumentLog({
      log,
      blackboardTemplateId,
    });
    if (field === DocumentLogTypeKey.CREATE_TASK) {
      // with case create task, we have 2 format
      // 1.  Dat Tranが指摘を作成しました。
      // 1.  Dat Tranがタイトルの"指摘名"で指摘を作成しました。
      const prefixAction = displayValue ? `で${ENTITY_NAME.TASK}を` : "";
      logText = getCommonFormatLog({
        action: `${prefixAction}${ACTION_CHANGE_DATA.CREATE}しました`,
        changeValue: displayValue,
        labelOfField: displayValue ? MapTitleForTask.taskTypeId?.title : title,
        afterLabel: displayValue ? "の" : "を",
      });
    } else {
      logText = getCommonFormatLog({
        action: `${action}しました`,
        changeValue: "",
        labelOfField: title,
        afterLabel: title ? "を" : "",
        isRevertDataByLog,
      });
    }
  } else {
    const result = getCustomTitleAndValueChangeForDoc(params);
    if (!result) {
      return { logText: undefined, data, isRevert: false };
    }
    // common format: Ex fullMsg: Dat TranがピンをOFFへ変更しました
    const titleForDocument = MapTitleForDocument[field];
    const fieldColor = titleForDocument?.fieldColor;
    const valueColor = titleForDocument?.valueColor;
    let labelOfField = result.customTitle || titleForDocument?.title;
    if (!labelOfField) {
      const cell = CellPropertyDocumentDataForTableOptions.find((opt) => {
        return opt.value === field;
      });
      labelOfField = cell?.name;
    }
    if (labelOfField === undefined) {
      return;
    }

    let prefixAction = "へ";
    let afterLabel = "を";
    let actionType = ACTION_CHANGE_DATA.EDIT;
    const changeValue = result.displayValue;
    const textChange =
      displayValue !== undefined &&
      FIELDS_TYPE_DOCUMENT_OPTION.includes(field as any)
        ? displayValue
        : changeValue;
    const isLogDefault = log.value?.isLogDefault;
    const isMediaField = MEDIA_KEYS.includes(field as SubItemKey);

    // case create element: Ex fullMsg: Dat Tranがタイトルの“FolderName”でフォルダーを作成しました。
    if (isLogDefault && params.entityName && textChange) {
      prefixAction = `で${entityName}を`;
      afterLabel = "の";
      actionType = ACTION_CHANGE_DATA.CREATE;
    } else if (!isMediaField) {
      if (typeof textChange === "string" && !textChange?.trim()) {
        // case clear data
        prefixAction = "を";
        afterLabel = "の値";
        actionType = ACTION_CHANGE_DATA.CLEAR;
      }
    }
    let action = `${actionType}しました`;
    let moreText: ReactNode = "";
    // case images and attaches: Ex fullMsg: Dat Tranが添付資料を登録しました。添付資料の状態が以下になります。
    if (isMediaField) {
      const actionChange = getActionLogChangeImageForDoc(log);
      prefixAction = "";
      action = `${actionChange}しました。`;
      prefixAction = "";
      moreText = getTextChangeImage(actionChange, fieldColor, labelOfField);
    }

    logText = getCommonFormatLog({
      action: prefixAction + action,
      changeValue: textChange,
      fieldColor,
      valueColor,
      labelOfField,
      moreText,
      afterLabel,
      isRevertDataByLog,
    });

    isDisabledRevert = checkDisabledRevertDocumentLog({
      log,
      changeValue,
      blackboardTemplateId,
      dynamicFieldLabel: result.dynamicFieldLabel,
      dynamicFieldType: result.dynamicFieldType,
    });
  }

  if (!checkPermissionRevertLog(user)) {
    isDisabledRevert = true;
  }

  return {
    logText,
    data,
    isRevert,
    isDisabledRevert,
  };
};
