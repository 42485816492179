import {
  EditTaskModalPermission,
  ROLE,
  RoleSettings,
} from "interfaces/models/role";
import { UserSetting } from "interfaces/models/user";
import { DEFAULT_DOCUMENT_FILTER_DATA } from "./document";
import { DEFAULT_TASK_FILTER_DATA } from "./task";

export const SYSTEM_NAME_JP_TEXT = "システム";

export const DEFAULT_USER_SETTING: UserSetting = {
  userId: "",
  displayTaskTitle: true,
  displayTaskBlackBoard: true,
  displayTaskId: true,
  displayTaskPhoto: true,
  displayTaskPriority: true,
  displayDocumentBlackBoard: true,
  displayDocumentId: true,
  displayDocumentPhoto: true,
  displayDocumentPriority: true,
  displayDocumentTitle: true,
  displayDocumentSubTitle: true,
  ...DEFAULT_TASK_FILTER_DATA,
  ...DEFAULT_DOCUMENT_FILTER_DATA,
  taskDateFrom: null as any,
  taskDateTo: null as any,
  deadlineFrom: null as any,
  deadlineTo: null as any,
  documentStartDateScheduledFrom: null as any,
  documentStartDateScheduledTo: null as any,
  documentEndDateScheduledFrom: null as any,
  documentEndDateScheduledTo: null as any,
} as UserSetting;

export const PARTNER_ROLES = [ROLE.PARTNER_LEADER, ROLE.PARTNER_MEMBER];

export const TAKASAGO_ROLES = [
  ROLE.TAKASAGO_ADMIN,
  ROLE.TAKASAGO_FIELD_AGENT,
  ROLE.TAKASAGO_ONSITE,
];

export const TAKASAGO_AND_PARTNER_LEADER_ROLES = [
  ROLE.TAKASAGO_ADMIN,
  ROLE.TAKASAGO_FIELD_AGENT,
  ROLE.TAKASAGO_ONSITE,
  ROLE.PARTNER_LEADER,
];

export const DEFAULT_EDIT_TASK_MODAL_PERMISSION: Partial<EditTaskModalPermission> =
  {
    canEditTaskTypeField: true,
    canEditTagsField: true,
    canEditImagesField: true,
    canEditMemoField: true,
    canEditAssignedUserField: true,
    canEditDeadlineField: true,
    canEditTaskDateField: true,
    canEditPartnerCompaniesField: true,
    canEditConfirmedImagesField: true,
    canEditConfirmedMemoField: true,
    canEditEndDateScheduledField: true,
    canEditConfirmedDateTimeField: true,
    canEditConfirmedUserField: true,
    canEditAttachsField: true,
    canEditCommentField: true,
    canViewImageOriginal: true,
  };

export const DEFAULT_PERMISSION: RoleSettings = {
  canAddProject: false,
  canEditProject: false,
  canDeleteProject: false,
  canViewProject: false,
  canEditUserList: false,
  canViewUserList: false,
  canAccessDocumentTemplate: false,
  canAccessBlackboardTemplate: false,
  canAccessTaskTypeList: false,
  canAccessUserDashboard: false,
  canAccessProjectDashboard: false,
  canAccessForgeViewer: false,
  canAccessTask: false,
  canAccessTaskSheetDoc: false,
  canAccessDocument: false,
  canAccessCheckItem: false,
  canViewTaskModal: false,
  canEditTaskModal: DEFAULT_EDIT_TASK_MODAL_PERMISSION,
  canAccessSettingFamily: false,
  canEditTaskList: false,

  // Folder
  canAddFolder: false,
  canEditFolder: false,
  canDeleteFolder: false,

  // Document category
  canAddDocumentCategory: false,
  canEditDocumentCategory: false,
  canDeleteDocumentCategory: false,
  canEditDocumentCategoryTitle: false,
  canEditDocumentCategoryInfo: false,

  // Document items
  canAddDocumentItem: false,
  canEditDocumentItem: false,
  canDeleteDocumentItem: false,

  // Task
  canEditTaskCorrection: false,
  canAddTask: false,
  canDeleteTask: false,
};
