import { blackboardApi, documentItemApi } from "apiClient/v2";
import { message } from "components/base";
import {
  DocumentCategoryStatusType,
  DocumentItemKey,
  MapDocumentCategoryStatusTypeColor,
  SubItemKey,
} from "constants/enum";
import { DocumentCategoryDTO } from "interfaces/dtos/documentCategoryDTO";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { Blackboard } from "interfaces/models/blackboard";
import { DataLog } from "interfaces/models/dataLog";
import { GetContentLog, transformMapTitleKey } from "models/dataLog";
import {
  isPhotoLedgerTemplate,
  isSelfInspectionTemplate,
} from "models/documentCategory";
import { useForgeViewerContext } from "pages/forge-viewer/ForgeViewerContext";
import { TypeHandleInitData } from "pages/forge-viewer/hooks/useSupportSyncDataOffline";
import { useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  setDataBlackboard,
  updateDocumentItem,
  updateVisibleDocumentItem,
} from "redux/documentSlice";
import store from "redux/store";
import { updateElementInArray } from "utils/array";
import { uuid } from "utils/common";
import {
  handleVisiblePinNotPhotoLedger,
  handleVisiblePinPhotoLedger,
} from "utils/documentItem";
import { setSelectionColor } from "utils/forge";
import { transformBodyForCombineData } from "utils/offline";

interface Props {
  insertLog: (params: GetContentLog & { subItemId?: string }) => Promise<void>;
  documentItemSelected?: DocumentItemDTO;
  subItemSelected?: DocumentSubItemDTO;
}

const useRevertDataByLogs = (props: Props) => {
  const { insertLog, documentItemSelected, subItemSelected } = props;
  const subItemSelectedRef = useRef(subItemSelected);
  const documentItemSelectedRef = useRef(documentItemSelected);
  subItemSelectedRef.current = subItemSelected;
  documentItemSelectedRef.current = documentItemSelected;
  const dispatch = useDispatch();
  const { socket } = useForgeViewerContext();

  const handleRevertTypeShowHidePin = useCallback(
    async (params: {
      log: DataLog;
      requestId: string;
      documentItem: DocumentItemDTO;
    }) => {
      const { log, requestId, documentItem } = params;
      const isHidePin = log.value?.data;
      const documentCategories = store.getState().document.documentCategories;
      const isVisible = !isHidePin;
      if (!isPhotoLedgerTemplate(documentItem.documentType)) {
        const documentCategory = documentCategories.find(
          (cate) => cate.id === documentItem.documentCategoryId
        );

        if (documentCategory) {
          handleVisiblePinNotPhotoLedger({
            isAwait: false,
            isVisible,
            documentItem,
            requestId,
            documentCategory,
          });

          dispatch(
            updateVisibleDocumentItem({
              itemId: documentItem.id,
              cateId: documentItem.documentCategoryId,
              documentType: documentItem.documentType,
              isVisible,
            })
          );
        }

        return;
      }

      const result = await handleVisiblePinPhotoLedger({
        isVisible,
        documentItem,
        documentCategories,
        requestId,
      });

      return result;
    },
    [dispatch]
  );

  const handleRevertItemByLogs = useCallback(
    async ({
      log,
      documentItem,
    }: {
      log: DataLog;
      documentItem: DocumentItemDTO | undefined;
    }) => {
      if (!documentItem?.id) {
        return;
      }
      const updateLoadingRevert = (
        isLoading: boolean,
        newDocItem?: DocumentItemDTO,
        isUpdateVisibleItem?: boolean
      ) => {
        dispatch(
          updateDocumentItem({
            ...(newDocItem ?? documentItem),
            isLoadingUpdateImage: isLoading,
            isUpdateVisibleItem,
          })
        );
      };

      updateLoadingRevert(true);
      const now = new Date();
      const field = log.field as
        | keyof DocumentItemDTO
        | keyof DocumentCategoryDTO;
      const dataLog = log?.value?.data;
      const {
        mapLogs,
        mapTitleKey,
        mapDisplayValueKey,
        mapDisplayLabelKey,
        mapDisplayValueTypeKey,
      } = transformMapTitleKey([
        {
          field: field as any,
          value: dataLog,
          ...log.value,
          isTypeRevert: true,
          nameDynamicField: "unknown",
        },
      ]);

      const requestId = uuid();
      const bodyUpdate: Partial<DocumentItemDTO> = {
        id: documentItem.id,
        [field]: dataLog,
        mapTitleKey,
        mapDisplayValueKey,
        mapDisplayLabelKey,
        mapDisplayValueTypeKey,
        requestId,
        isRevertDataByLog: true,
        updatedAt: now,
      };

      const result = await documentItemApi.updateItem(
        transformBodyForCombineData<DocumentItemDTO>({
          body: bodyUpdate as DocumentItemDTO,
          bodyBefore: documentItem,
          typeInitData: TypeHandleInitData.DOCUMENT_ITEM,
        })
      );

      let newDocumentItem = {
        ...documentItem,
        ...bodyUpdate,
      } as DocumentItemDTO;
      if (field === DocumentItemKey.DATA) {
        newDocumentItem.data = {
          ...(documentItem.data || {}),
          ...(result.data.data || {}),
        };
      }

      let isUpdateVisibleItem = false;
      if (field === "isHidePin") {
        const newItem = await handleRevertTypeShowHidePin({
          log,
          requestId,
          documentItem,
        });

        if (isPhotoLedgerTemplate(documentItem.documentType)) {
          isUpdateVisibleItem = true;
          newDocumentItem = { ...newDocumentItem, ...newItem };
        }

        socket.changeVisibleDocItem(documentItem, {
          isVisible: !log.value?.data,
          updatedAt: result.data?.updatedAt,
        });
      }

      if (!result?.data?.id) {
        updateLoadingRevert(false);
        message.error("Data recovery failed");

        return;
      }

      Object.values(mapLogs).forEach((params) => {
        insertLog({ ...params, requestId });
      });

      if (field === DocumentItemKey.STATUS) {
        setSelectionColor({
          color:
            MapDocumentCategoryStatusTypeColor[
              dataLog as DocumentCategoryStatusType
            ],
          shouldRender: true,
        });
      }

      updateLoadingRevert(false, newDocumentItem, isUpdateVisibleItem);

      const payload = newDocumentItem ?? documentItem;
      socket.updateDocItem(documentItem, {
        ...payload,
        updatedAt: result.data.updatedAt,
      });
    },
    [dispatch, insertLog, handleRevertTypeShowHidePin, socket]
  );

  const handleRevertTypeBlackboard = useCallback(
    async (params: {
      log: DataLog;
      requestId: string;
      subItemId: string;
      handleLoading: (isLoading: boolean) => void;
    }) => {
      const { subItemId, requestId, log, handleLoading } = params;
      const dataLog = {
        ...log?.value?.data,
        commentManageExecute: log?.value?.data?.commentManageExecute || [],
      } as Partial<Blackboard>;
      const dataBlackboards = store.getState().document.dataBlackboards;

      const dataBlackboard = dataBlackboards.find(
        (data) => data.id === dataLog?.id
      );
      if (dataBlackboard) {
        const thumbnail = log?.value?.thumbnail;
        const blackboardTemplateId = log.value?.blackboardTemplateId;
        const result = await blackboardApi.updateBlackboard({
          id: dataBlackboard.id,
          subItemId,
          requestId,
          ...dataLog,
          isRevertDataByLog: true,
        });
        if (!result.data.id) {
          handleLoading(false);
          message.error("Data recovery failed");

          return;
        }
        const newDataBlackboard: Blackboard = {
          ...dataBlackboard,
          ...dataLog,
          thumbnail,
          blackboardTemplateId,
          updatedAt: result.data.updatedAt,
        };

        dispatch(setDataBlackboard(newDataBlackboard));

        insertLog({
          field: "blackboard" as any,
          blackboardThumbnail: thumbnail,
          blackboardTemplateId,
          value: newDataBlackboard,
          isTypeRevert: true,
          requestId,
        });

        socket.updateBlackboard(
          documentItemSelectedRef.current?.level!,
          dataBlackboard.id!,
          newDataBlackboard
        );
      }
      handleLoading(false);
    },
    [dispatch, insertLog, socket]
  );

  const handleRevertTypeBlackboardByImage = useCallback(
    async (params: { log: DataLog; requestId: string }) => {
      const { log, requestId } = params;

      const blackboardData = structuredClone(log?.value?.blackboardData);
      if (!blackboardData) {
        return;
      }

      blackboardData.commentManageExecute =
        blackboardData?.commentManageExecute || [];
      const result = await blackboardApi.updateBlackboard({
        ...blackboardData,
        requestId,
        isInsertLog: false,
      });

      if (result?.data?.id) {
        dispatch(setDataBlackboard(blackboardData));
        socket.updateBlackboard(
          documentItemSelectedRef.current?.level!,
          blackboardData.id!,
          blackboardData
        );
      }

      return blackboardData;
    },
    [socket, dispatch]
  );

  const transformBodyRevertTypeImage = useCallback(
    async (params: {
      bodyUpdate: Partial<DocumentSubItemDTO>;
      log: DataLog;
    }) => {
      const { bodyUpdate, log } = params;
      const blackboardImagePosition = log.value?.blackboardImagePosition;
      bodyUpdate.thumbnail = log?.value?.thumbnail;
      bodyUpdate.blackboardImagePosition = blackboardImagePosition;

      if (blackboardImagePosition) {
        bodyUpdate.blackboardTemplateId = log?.value?.blackboardTemplateId;
        bodyUpdate.isShowBlackboard = true;
      }
    },
    []
  );

  const handleRevertSubItemByLogs = useCallback(
    async ({
      log,
      documentItem,
      subItem,
    }: {
      log: DataLog;
      subItem: DocumentSubItemDTO | undefined;
      documentItem: DocumentItemDTO | undefined;
    }) => {
      if (!subItem?.id || !documentItem?.id) {
        return;
      }
      const newDocumentItem = structuredClone(documentItem);
      const now = new Date();
      const field = log.field;
      const dataLog = log?.value?.data;

      const updateLoadingRevert = (
        isLoading: boolean,
        newSubItem?: DocumentSubItemDTO
      ) => {
        // use field loading upload image to loading when revert data
        updateElementInArray({
          array: newDocumentItem?.subItems || [],
          keyIndex: SubItemKey.ID,
          element: {
            ...(newSubItem ?? subItem),
            isLoadingUpdateImage: isLoading,
          } as DocumentSubItemDTO,
        });
        dispatch(
          updateDocumentItem({
            ...newDocumentItem,
            isLoadingUpdateImage: isLoading,
          })
        );
      };

      updateLoadingRevert(true);
      const requestId = uuid();

      if (field === "blackboard") {
        await handleRevertTypeBlackboard({
          log,
          requestId,
          subItemId: subItem.id,
          handleLoading: updateLoadingRevert,
        });

        return;
      }

      const isSelfInspection = isSelfInspectionTemplate(
        documentItem.documentType
      );

      const {
        mapLogs,
        mapTitleKey,
        mapDisplayValueKey,
        mapDisplayLabelKey,
        mapDisplayValueTypeKey,
      } = transformMapTitleKey([
        {
          field: field as any,
          value: dataLog,
          isTypeRevert: true,
          ...log.value,
          blackboardThumbnail: log.value?.thumbnail,
          nameDynamicField: "unknown",
        },
      ]);

      const bodyUpdate: Partial<DocumentSubItemDTO> = {
        id: subItem.id,
        itemId: subItem.itemId,
        [field]: dataLog ?? null,
        mapTitleKey,
        mapDisplayValueKey,
        mapDisplayLabelKey,
        mapDisplayValueTypeKey,
        isAddMedia: log.value?.isAddMedia,
        isDeleteMedia: log.value?.isDeleteMedia,
        isEditMedia: log.value?.isEditMedia,
        requestId,
        isRevertDataByLog: true,
        updatedAt: now,
      };

      if (field === DocumentItemKey.IMAGES) {
        transformBodyRevertTypeImage({
          bodyUpdate,
          log,
        });

        // If the log has blackboard data, also revert the data for that blackboard.
        const blackboardData = await handleRevertTypeBlackboardByImage({
          log,
          requestId,
        });
        if (blackboardData) {
          bodyUpdate.blackboardData = blackboardData;
        }
      }

      const result = await documentItemApi.updateSubItem(
        transformBodyForCombineData<DocumentSubItemDTO>({
          body: bodyUpdate as DocumentSubItemDTO,
          bodyBefore: subItem,
          typeInitData: TypeHandleInitData.SUB_ITEM,
        })
      );

      if (!result?.data?.id) {
        updateLoadingRevert(false);
        message.error("Data recovery failed");

        return;
      }

      Object.values(mapLogs).forEach((p) => {
        const params = { ...p, requestId } as GetContentLog & {
          subItemId?: string;
        };
        if (isSelfInspection) {
          params.subItemId = subItem.id;
        }
        insertLog(params);
      });

      const newSubItem = {
        ...subItem,
        ...bodyUpdate,
      } as DocumentSubItemDTO;

      // type dynamic data stores only 1 key
      // so need to use spread operator
      if (field === DocumentItemKey.DATA) {
        newSubItem.data = {
          ...(subItem?.data || {}),
          ...(result?.data?.data || {}),
        };
      }
      updateLoadingRevert(false, newSubItem);
      socket.updateSubItem(newDocumentItem, subItem, {
        ...bodyUpdate,
        updatedAt: result.data.updatedAt,
      });
    },
    [
      socket,
      dispatch,
      insertLog,
      handleRevertTypeBlackboard,
      transformBodyRevertTypeImage,
      handleRevertTypeBlackboardByImage,
    ]
  );

  const onRevertDataByLog = useCallback(
    (log: DataLog, children?: DocumentSubItemDTO[]) => {
      let subItem = subItemSelectedRef.current;
      if (log.subItemId && children?.length) {
        subItem = children?.find((sub) => sub.id === log.subItemId);
        if (!subItem) {
          return;
        }
      }

      if (subItem) {
        handleRevertSubItemByLogs({
          log,
          documentItem: documentItemSelectedRef.current,
          subItem,
        });
      } else {
        handleRevertItemByLogs({
          log,
          documentItem: documentItemSelectedRef.current,
        });
      }
    },
    [handleRevertSubItemByLogs, handleRevertItemByLogs]
  );

  return {
    handleRevertItemByLogs,
    handleRevertSubItemByLogs,
    onRevertDataByLog,
  };
};

export default useRevertDataByLogs;
