import { taskApi } from "apiClient/v2";
import { message } from "components/base";
import { SystemModeType } from "constants/enum";
import { TaskLogTypeKey } from "constants/task";
import { TaskDTO } from "interfaces/dtos/taskDTO";
import { TaskLog } from "interfaces/models/task";
import { TaskType } from "interfaces/models/taskType";
import { WSMessage } from "interfaces/models/websocket";
import {
  getMapTaskTypeKeyFromTaskLogs,
  getTaskContentLog,
} from "models/commentLog";
import { useForgeViewerContext } from "pages/forge-viewer/ForgeViewerContext";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCreateTask, setIsMoveTaskLabel } from "redux/forgeViewerSlice";
import { RootState } from "redux/store";
import { setTask } from "redux/taskSlice";
import { getNetworkStatus, uuid } from "utils/common";
import { getDbIdByExternalId } from "utils/forge/data";
import { ClickInfo } from "utils/forge/extensions/click-extension";
import { updateLabel } from "utils/forge/extensions/custom-label";

interface iProps {
  clickInfo?: ClickInfo;
  taskTypes: TaskType[];
  setClickInfo?: React.Dispatch<React.SetStateAction<ClickInfo | undefined>>;
  handleCloseTaskModal: () => void;
  sendWebSocketMessage: (message: WSMessage) => void;
  addTaskLog: (logs: TaskLog[], requestId: string) => void;
}

const useHandleMoveTaskLabel = ({
  taskTypes = [],
  clickInfo,
  addTaskLog,
  setClickInfo,
  handleCloseTaskModal,
}: iProps) => {
  const dispatch = useDispatch();
  const { isMoveTaskLabel, systemMode } = useSelector(
    (state: RootState) => state.forgeViewer
  );
  const { taskSelected } = useSelector((state: RootState) => state.task);

  const showTooltipMoveTaskLabel = useMemo(
    () => systemMode === SystemModeType.Task && isMoveTaskLabel,
    [systemMode, isMoveTaskLabel]
  );
  const taskSelectedRef = useRef<TaskDTO>();
  const { socket } = useForgeViewerContext();

  useEffect(() => {
    if (!taskSelected?.id) {
      dispatch(setIsMoveTaskLabel(false));
    }

    taskSelectedRef.current = taskSelected;
  }, [taskSelected, dispatch]);

  useEffect(() => {
    if (
      !clickInfo ||
      systemMode !== SystemModeType.Task ||
      !clickInfo.forgeData?.position ||
      !isMoveTaskLabel
    ) {
      return;
    }

    handleMoveTaskLabel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickInfo, isMoveTaskLabel]);

  const handleMoveTaskLabel = async () => {
    const position = clickInfo?.forgeData?.position;
    const externalId = clickInfo?.forgeData?.externalId;
    const taskSelected = taskSelectedRef?.current;
    if (!position || !taskSelected?.id) {
      return;
    }

    const isOnline = getNetworkStatus();
    setClickInfo?.(undefined);
    const requestId = uuid();
    const taskLog = getTaskContentLog({
      field: "position",
      value: { ...position, externalId },
      taskId: taskSelected.id,
      type: TaskLogTypeKey.MOVE_TASK,
    }) as TaskLog;
    const body = {
      id: taskSelected?.id,
      position,
      externalId,
      documentItemId: taskSelected?.documentItemId,
      mapTaskTypeKey: getMapTaskTypeKeyFromTaskLogs([taskLog]),
      requestId,
    } as TaskDTO;

    let { data: newTask } = await taskApi.updateTask(body);

    newTask = { ...taskSelected, ...newTask };
    addTaskLog([taskLog], requestId);
    if (newTask) {
      newTask.dbId = getDbIdByExternalId(newTask.externalId);
      newTask.templateId = taskSelected?.templateId;
      if (!isOnline) {
        newTask = { ...taskSelected, ...newTask };
      }

      socket.updateTask({
        ...body,
        level: taskSelected?.level!,
        updatedAt: newTask.updatedAt,
      });

      dispatch(setTask(newTask));
      updateLabel(newTask.id, {
        id: newTask.id,
        position: newTask.position,
        title:
          taskTypes.find((taskType) => taskType.id === newTask.taskTypeId)
            ?.title || "-",
        indexId: newTask.indexId,
        showImage: Number(newTask.images?.length) > 0,
        status: newTask.status,
        externalId: newTask.externalId,
      });
      message.success("指摘ピンの移動が完了しました。");
      dispatch(setIsMoveTaskLabel(false));
      handleCloseTaskModal();
    }
  };

  const toggleMoveTaskLabelMode = useCallback(() => {
    if (!isMoveTaskLabel) {
      dispatch(setCreateTask(false));
    }

    dispatch(setIsMoveTaskLabel(!isMoveTaskLabel));
  }, [dispatch, isMoveTaskLabel]);

  return { toggleMoveTaskLabelMode, showTooltipMoveTaskLabel };
};

export default useHandleMoveTaskLabel;
